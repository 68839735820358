import React, { Component } from  'react'
import styled from 'styled-components'

import ChevronIcon from './ChevronIcon'

const BigButton = styled.button`
  background: ${props => props.active ? '#E6E8FC' : '#fff'};
  border-radius: 0.75rem;
  border: 0;
  padding: 0.5rem 1rem;
  color: ${props => props.active ? '#3040E1' : '#161616'};
  font-family: "HK Grotesk";
  font-weight: 400;
  margin-right: 0.5rem;

  &:focus {
    outline: 0 !important;
  }
`

export default class CustomDropdownMenu extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false
    }

    this.toggle = this.toggle.bind(this)
  }
  componentDidMount() {
    document.querySelector('.dark-background')
        .addEventListener('click', this.toggle);
  }
  componentWillUnmount() {
    document.querySelector('.dark-background')
        .removeEventListener('click', this.toggle);
  }
  toggle() {
    const background = document.querySelector('.dark-background')
    const isOpen = !this.state.isOpen

    if (isOpen) {
      background.classList.add('active')
    } else {
      background.classList.remove('active')
    }

    this.setState({
      isOpen
    })
  }
  render() {
    return (
      <li>
        <BigButton onClick={this.toggle} active={+this.state.isOpen}>
          Browse Software <ChevronIcon active={this.state.isOpen} />
        </BigButton>

        {this.state.isOpen && this.props.children}
      </li>
    )
  }
}
