import React, { Component } from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'
import ReactSVG from 'react-svg'

import Button from '../components/register/Button'
import RegisterForm from '../components/register/RegisterForm'
import TextRule from '../components/register/TextRule'
import Feature from '../components/register/Feature'

import SoftwarePathPath from '../svg/new-softwarepath-logo-with-icon.svg'
import LinkedInPath from '../svg/new-linkedin.svg'

const SoftwarePathReactIcon = (props) => (
  <ReactSVG src={SoftwarePathPath} wrapper="span" {...props} />
)

const LinkedInReactIcon = (props) => (
  <ReactSVG src={LinkedInPath} wrapper="span" {...props} />
)

const SoftwarePathIcon = styled(SoftwarePathReactIcon)`
  svg {
    margin-bottom: 2rem;
  }
`

const RegisterContainer = styled(Container)`
  padding-top: 6rem;
`

const LinkedInIcon = styled(LinkedInReactIcon)`
  svg {
    height: 20px;
    width: 20px;
    margin-right: .2rem;
  }
`

const LinkedInButton = styled(Button)`
  background: #2977c9;
  border: 0;
`

export default class Register extends Component {
  constructor(props) {
    super(props)

    this.goToLinkedIn = this.goToLinkedIn.bind(this)
  }
  goToLinkedIn() {
    window.location.href = '/login/linkedin'
  }
  render () {
    return (
      <div>
        <RegisterContainer>
          <Row>
            <Col md={7} sm={7} xs={12} className="mt-3">
              <Feature />
            </Col>
            <Col md="5" sm={5} xs={12} className="mt-3">
              <a href="/">
                <SoftwarePathIcon />
              </a>

              <LinkedInButton onClick={this.goToLinkedIn}>
                <LinkedInIcon /> Sign Up with LinkedIn
              </LinkedInButton>

              <TextRule>or</TextRule>

              <RegisterForm />
            </Col>
          </Row>
        </RegisterContainer>
      </div>
    )
  }
}
