import styled from 'styled-components'

const Label = styled.a`
  background: #fff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  color: #585858;
  padding: 0.2rem 0.5rem;
  font-size: 0.75rem;
  margin-right: 0.5rem;
  user-select: none;
  display: inline-block;

  &:hover {
    text-decoration: none;
    color: #585858;
  }
`

export default Label
