import store from '../store'
import Axios from "axios";
import { isGuest } from '../helpers'
import setShowingShortlistComponent from './set-showing-shortlist-component'

export const PRODUCT_ADDED_TO_CURRENT_SHORTLIST = 'PRODUCT_ADDED_TO_CURRENT_SHORTLIST'

const addToSessionShortlist = (productSlug, dispatch) => {
  Axios.post(
    '/api/session/shortlist/add-product/slug',
    {
      product_slug: productSlug,
    }
  )
  .then(response => {
    const product = response.data.data

    dispatch({
      type: PRODUCT_ADDED_TO_CURRENT_SHORTLIST,
      product,
    })

    store.dispatch(setShowingShortlistComponent(true))
  })
}

const addToAuthenticatedShortlist = (productSlug, dispatch) => {
      const shortlistId = store.getState().shortlists.current.shortlist.id

      Axios.post(`/api/shortlists/${shortlistId}/products`, {
            product: productSlug
      }).then((response) => {
            const product = response.data.data

            dispatch({
                  type: PRODUCT_ADDED_TO_CURRENT_SHORTLIST,
                  product,
            })

            store.dispatch(setShowingShortlistComponent(true))
      })
}

const addProductToCurrentShortlist = productSlug => (
    dispatch => {
      // Look for the product in the current shortlist
      const currentShortlistProduct = store
          .getState()
          .shortlists
          .current
          .products
          .find(shortlistProduct => shortlistProduct.slug === productSlug)

      // Only continue to add the product to the shortlist if it is not already there
      if (typeof currentShortlistProduct === 'undefined') {
           if (isGuest()) {
              addToSessionShortlist(productSlug, dispatch)
           } else {
              addToAuthenticatedShortlist(productSlug, dispatch)
           }
      }
    }
)

export default addProductToCurrentShortlist
