import React from 'react'
import styled from 'styled-components'

const InviteeContainer = styled.div`
  display: flex;
  margin-bottom: 0.75rem;
`

const UserIcon = styled.svg`
  margin-right: 0.75rem;
`

const EmailContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: "HK Grotesk";
`

const CloseButtonContainer = styled.div`
  margin-left: auto;
`

const CloseButton = styled.button`
  border: 0;
  height: 100%;

  &:focus {
    outline: 0;
  }
`

const Invitee = ({ email, removeInvitee }) => (
  <InviteeContainer>
    <UserIcon aria-hidden="true" width="40" height="40" viewBox="0 0 496 512">
      <path fill="currentColor" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"/>
    </UserIcon>
    <EmailContainer>
      {email}
    </EmailContainer>
    <CloseButtonContainer>
      <CloseButton onClick={() => removeInvitee(email)}>
        <svg width="16" height="16" viewBox="0 0 16 16">
            <path fill="#BBB" fillRule="evenodd" d="M8 6.586l2.293-2.293a1 1 0 0 1 1.414 1.414L9.414 8l2.293 2.293a1 1 0 0 1-1.414 1.414L8 9.414l-2.293 2.293a1 1 0 1 1-1.414-1.414L6.586 8 4.293 5.707a1 1 0 0 1 1.414-1.414L8 6.586z"/>
        </svg>
      </CloseButton>
    </CloseButtonContainer>
  </InviteeContainer>
)

export default Invitee