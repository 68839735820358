import React from 'react'
import ReactSVG from 'react-svg'
import styled from 'styled-components'

import CheckboxPath from '../../svg/new-checkbox.svg'

const FeatureContainer = styled.div`
  background: #f5f6fc;
  border-radius: 12px;
  padding: 2.5rem;
  position: relative;
  margin: 0 2rem;
`

const PlanImage = styled.img`
  position: absolute;
  top: -3rem;
  zoom: 1;
  left: -2rem;
  right: 0;
  width: 118%;
`

const FeatureHeading = styled.h1`
  font-size: 1.5rem;
  padding-top: 88%;
`

const FeatureList = styled.ul`
  padding: 0;
  padding-top: 1rem;
  list-style-type: none;
`

const FeatureListItem = styled.li`
  padding-bottom: 0.75rem;
`

const FeatureText = styled.span`
  font-weight: 600;
  margin-left: 0.5rem;
`

const CheckboxIcon = (props) => (
  <ReactSVG src={CheckboxPath} wrapper="span" {...props} />
)

const Feature = () => (
  <FeatureContainer>
    <PlanImage src="/register/image"></PlanImage>

    <FeatureHeading>Compare the products in your shortlist ...</FeatureHeading>

    <FeatureList>
      <FeatureListItem>
        <CheckboxIcon/> 
        <FeatureText>Detailed pricing plans</FeatureText>
      </FeatureListItem>
      <FeatureListItem>
        <CheckboxIcon/> 
        <FeatureText>In-depth software features</FeatureText>
      </FeatureListItem>
      <FeatureListItem>
        <CheckboxIcon/> 
        <FeatureText>Reviews and rankings</FeatureText>
      </FeatureListItem>
    </FeatureList>
  </FeatureContainer>
)

export default Feature
