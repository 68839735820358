import Axios from "axios";

export const SHORTLIST_CREATED = 'SHORTLIST_CREATED'

const createShortlist = shortlistName => (
    dispatch => {
      return Axios.post(`/api/shortlists`, {
        name: shortlistName,
      }).then(response => {
        const shortlist = response.data.data

        dispatch({
          type: SHORTLIST_CREATED,
          shortlist,
        })

        return shortlist
      })
    }
)

export default createShortlist
