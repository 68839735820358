import React from 'react'
import { connect } from 'react-redux'
import {Modal, ModalCloseButton, ModalHeader, Input, Label, Button} from 'software-path-components'
import renameShortlist from '../../actions/rename-shortlist'

const customModalStyle = {
  overlay: {
    position: 'fixed',
  },
}

class RenameShortlistModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      newShortlistName: '',
    }

    this.renameShortlist = this.renameShortlist.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }
  renameShortlist() {
    this.props.renameShortlist(this.props.currentShortlist, this.state.newShortlistName)
        .then(() => {
          this.closeModal()
        })
  }
  closeModal() {
    this.setState({newShortlistName: ''})
    this.props.closeModal()
  }
  render() {
    Modal.setAppElement('body')

    return (
        <Modal style={customModalStyle} isOpen={this.props.isOpen}>
          <ModalCloseButton style={{float: 'right'}} onClick={this.closeModal} />
          <ModalHeader>
            <h3>Rename Shortlist</h3>
          </ModalHeader>
          <Label style={{marginTop: '2rem'}}>
            Shortlist Name
            <Input value={this.state.newShortlistName} onChange={event => this.setState({newShortlistName: event.target.value})} />
          </Label>
          <Button
              colour="primary"
              style={{width: '100%', marginTop: '1rem'}}
              onClick={this.renameShortlist}
          >
            Rename shortlist
          </Button>
        </Modal>
    )
  }
}

const mapStateToProps = () => ({})
const mapDispatchToProps = dispatch => ({
  renameShortlist: (shortlist, newName) => dispatch(renameShortlist(shortlist, newName))
})

export default connect(mapStateToProps, mapDispatchToProps)(RenameShortlistModal)
