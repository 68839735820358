import React, { Component } from 'react'
import ReactSVG from 'react-svg'
import styled from 'styled-components'
import ProductImage from '../common/ProductImage'
import DeleteCrossPath from '../../svg/new-delete-cross.svg'

const DeleteCross = (props) => (
  <ReactSVG src={DeleteCrossPath} wrapper="span" {...props} />
)

const DeleteCrossIcon = styled(DeleteCross)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  line-height: 24px;
`

const DeleteButton = styled.button`
  display: none;
  position: absolute;
  right: 1rem;
  border: 0;
  box-shadow: 0 2px 4px 0 rgba(22, 22, 22, 0.12), 0 -1px 2px 0 rgba(22, 22, 22, 0.04);
  border-radius: 50%;
  width: 24px;
  height: 24px;

  &:focus {
    outline: 0;
  }
`

const ProductContainer = styled.div`
  position: relative;
  flex: 0 0;
  padding-right: 1.25rem;

  &:hover {
    cursor: pointer
    ${DeleteButton} {
      display: inline-block;
    }
  }
`

const ProductText = styled.span`
  font-size: 0.75rem;
  text-align: center;
  display: block;
  margin-top: 0.75rem;
`

export default class Product extends Component
{
  render() {
    return (
      <ProductContainer onClick={() => { window.location = `/software/${this.props.product.slug}` }}>
        <DeleteButton onClick={() => this.props.deleteProduct(this.props.shortlistId, this.props.id)}>
          <DeleteCrossIcon />
        </DeleteButton>
        <ProductImage src={this.props.product.display_logo} draggable="false"></ProductImage>
        <ProductText>{this.props.product.name}</ProductText>
      </ProductContainer>
    )
  }
}
