/**
 * @param {string} key
 * @param {object} initialState
 * @param {Storage} store
 * @return {object}
 */
function getItem(key, initialState, store) {
  const data = store.getItem(key)

  if (data !== null) {
    try {
      return {
        ...initialState,
        ...JSON.parse(atob(data)),
      }
    } catch {}
  }

  return {
    ...initialState
  }
}

/**
 * @param {string} key
 * @param {object} data
 * @param {Storage} store
 */
function setItem(key, data, store) {
  try {
    store.setItem(key, btoa(JSON.stringify(data)))
  } catch {}
}

/**
 * @param {string} key
 * @param {Storage} store
 */
function removeItem(key, store) {
  try {
    store.removeItem(key)
  } catch {}
}

function getLocalStorageItem(key, initialState) {
  return getItem(key, initialState, window.localStorage)
}

function setLocalStorageItem(key, data) {
  setItem(key, data, window.localStorage)
}

function removeLocalStorageItem(key) {
  removeItem(key, window.localStorage)
}

function getSessionStorageItem(key, initialState) {
  return getItem(key, initialState, window.sessionStorage)
}

function setSessionStorageItem(key, data) {
  setItem(key, data, window.sessionStorage)
}

const leadInformationKey = btoa("lead-information")
const leadInformationState = {
  name: '',
  email: '',
  company: '',
  work_contact_number: '',
}

export function loadLeadInformation() {
  return getLocalStorageItem(leadInformationKey, leadInformationState)
}

export function saveLeadInformation(data) {
  setLocalStorageItem(leadInformationKey, data)
}

export function deleteLeadInformation() {
  removeLocalStorageItem(leadInformationKey)
}

const formVisibilityKey = btoa("form-visibility")
const formVisibilityState = {
  showLeadCapture: true
}

export function loadFormVisibility() {
  return getSessionStorageItem(formVisibilityKey, formVisibilityState)
}

export function saveFormVisibility(data) {
  setSessionStorageItem(formVisibilityKey, {
    ...loadFormVisibility(),
    ...data
  })
}
