import PropTypes from 'prop-types'
import styled from 'styled-components'
import { mediaBreakpointDownSm } from 'styled-bootstrap-responsive-breakpoints'

const ButtonLink = styled.a`
  border: 1px solid #EBEBEB;
  border-radius: 12px;
  color: #161616;
  display: flex;
  font-size: 1rem;
  font-weight: 600;
  margin-top: auto;
  padding: 0.5rem 1rem;
  text-align: center;
  width: 100%;
  justify-content: center;
  user-select: none;

  &:hover {
    color: ${props => props.variant === 'primary' ? '#fff' : '#3040E1'};
    background: ${props => props.variant === 'primary' ? '#3040E1' : '#E6E8FC'};
    text-decoration: none;
  }
`

ButtonLink.defaultProps = {
  variant: 'primary',
}

ButtonLink.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary'])
}

export default ButtonLink