import React, { Component } from 'react'
import styled from 'styled-components'
import { Alert } from 'reactstrap';

import Button from './Button'
import Input from './Input'
import InputGroup from './InputGroup'
import Label from './Label'

const ErrorList = styled.ul`
  margin-bottom: 0;
  font-family: "HK Grotesk";
`

const RegisterText = styled.p`
  font-size: 0.875rem;
  text-align: center;
  padding-top: 1.25rem;
  font-weight: 500;

  a {
    font-weight: 600;
    color: #3040e1;
  }
`

export default class ResetForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      token: '',
      errors: []
    }

    this.handleChange = this.handleChange.bind(this)
  }
  componentWillMount() {
    const token = document.querySelector('meta[name="csrf-token"]').content
    const email = window.loginEmail !== null ? window.loginEmail : ''
    const errors = window.errors

    this.setState({
      token,
      errors,
      email
    })
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }
  render() {
    return (
      <div>
        {this.state.errors.length > 0 && (
          <Alert color="danger">
            <ErrorList>
              {this.state.errors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ErrorList>
          </Alert>
        )}
        <form method="POST" action="/password/email">
          <input type="hidden" name="_token" value={this.state.token} />
          <InputGroup>
            <Label htmlFor="email">
              Email
            </Label>
            <Input 
              placeholder="you@email.com"
              id="email"
              name="email"
              onChange={this.handleChange}
              value={this.state.email}
            />
          </InputGroup>

          <Button>Send Password Reset Link</Button>

          <RegisterText>Don't have an account? <a href="/register">Register</a></RegisterText>
        </form>
      </div>
    )
  }
}