import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { mediaBreakpointDownMd } from 'styled-bootstrap-responsive-breakpoints'

const ItemContainer = styled.div`
  opacity: ${props => props.disabled ? 0.24 : 1};
  margin-top: 5rem;

  ${mediaBreakpointDownMd`
    margin-top: 2.5rem;
  `}
`

const Heading = styled.h4`
  color: #161616;
  font-size: 1.75rem;
  line-height: 2.25rem;
`

const Body = styled.div`
  color: #585858;
  font-weight: 400;
`

const Asterisk = styled.span`
  color: #FFBF4A;
  font-size: 1.5rem;
  margin-right: 0.25rem;
`

const ComingSoonText = styled.span`
  font-size: 0.75rem;
`

const Item = ({ title, disabled, isComingSoon, children }) => (
  <ItemContainer disabled={disabled}>
    <Heading>{title}</Heading>

    <Body>
      {children}
    </Body>
    {isComingSoon && (
      <div>
        <Asterisk>*</Asterisk>
        <ComingSoonText>Coming Soon</ComingSoonText>
      </div>
    )}
  </ItemContainer>
)

Item.defaultProps = {
  disabled: false,
  isComingSoon: false,
}

Item.propTypes = {
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isComingSoon: PropTypes.bool,
}

export default Item