import React from 'react'
import styled from 'styled-components'

const RankingCardHeadingContainer = styled.div`
  display: flex;
  padding: 0 2rem 1.5rem 1rem;
`

const RankingCardHeading = ({ children }) => (
  <RankingCardHeadingContainer>
    {children}
  </RankingCardHeadingContainer>
)

export default RankingCardHeading