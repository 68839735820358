import React from 'react'
import styled from 'styled-components'
import ReactSVG from 'react-svg'

import CheckPath from '../../svg/new-feature-check.svg'

const CheckReactIcon = (props) => (
  <ReactSVG src={CheckPath} wrapper="span" {...props} />
)

const CheckIcon = styled(CheckReactIcon)`
  svg path {
    fill: #087f5b;
  }
`

export default CheckIcon