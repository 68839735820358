import React, { Component } from 'react'
import styled from 'styled-components'

import UnstyledCrossIcon from './CrossIcon'
import PlanCellContainer from './PlanCellContainer'
import Plan from './Plan'

const PlanWrapper = styled.div`
  &:hover {
    cursor: pointer;
    
    .plan-list-container {
      display: inline-block;
    }
  }
`

const PlanListContainer = styled.div.attrs({
  className: 'plan-list-container'
})`
  position: absolute;
  width: 100%;
  display: none;
`

const CrossIcon = styled(UnstyledCrossIcon)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

class PlanCell extends Component {
  constructor(props) {
    super(props)

    this.state = {
      firstPlan: null,
      plans: []
    }
  }
  componentDidMount() {
    const plans = [...this.props.plans]

    const firstPlan = plans ? plans[0] : null;

    this.setState({
      plans, firstPlan: firstPlan !== undefined ? firstPlan : null
    })
  }
  render() {
    return (
      <div style={{ position: 'relative' }}>
        {this.state.firstPlan === null && (
          <PlanCellContainer
            featured={this.props.featured}
            firstItem={this.props.firstItem}
            lastItem={this.props.lastItem}
          >
            {this.props.verified ? <CrossIcon /> : ''}
          </PlanCellContainer>
        )}

        {this.state.firstPlan !== null && (
          <PlanWrapper>
            <Plan
              featured={this.props.featured}
              firstItem={this.props.firstItem}
              lastItem={this.props.lastItem}
              name={this.props.currentPlan.name}
              price={this.props.currentPlan.price}
              period={this.props.currentPlan.price_per}
              expandable={this.state.plans.length > 0}
            />

            <PlanListContainer>
              {this.state.plans.map((plan, index) => (
                <div onClick={() => { this.props.setPlan(plan); }}>
                  <Plan
                    key={index}
                    featured={this.props.featured}
                    firstItem={this.props.firstItem}
                    lastItem={this.props.lastItem}
                    name={plan.name}
                    price={plan.price}
                    period={plan.price_per}
                  />
                </div>
              ))}
            </PlanListContainer>
          </PlanWrapper>
        )}
      </div>
    )
  }
}

export default PlanCell
