import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import { mediaBreakpointDownMd } from 'styled-bootstrap-responsive-breakpoints';
import { /*Sticky,*/ StickyContainer } from 'react-sticky';
import DefaultContainer from '../Container';
import Item from '../slider/Item';
import SliderHero from '../slider/SliderHero';
import { motion } from 'framer-motion';

const Section = styled.section`
  background: rgba(0,0,0,0.02);
  height: 100%;
`

const Container = styled(DefaultContainer)`
  align-items: center;
  padding: 10rem 0;

  ${mediaBreakpointDownMd`
    padding: 3.75rem 0;
  `}
`

const Heading = styled.h2`
  color: #000749;
  font-family: 'Tiempos Headline', serif;
  font-size: 2.75rem;
  margin-bottom: 1.5rem;
  line-height: 3.5rem;
`;

const Subheading = styled.h6`
  font-size: 1.25rem;
  line-height: 1.75rem;
`

class SoftwareSliderSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstText: {
        opacity: 1,
        scale: 1.05,
      },
      secondText: {
        opacity: 0.5,
        scale: 1,
      },
      thirdText: {
        opacity: 0.2,
        scale: 1,
      },
      firstImageOpacity: 1,
      secondImageOpacity: 0,
      thirdImageOpacity: 0,
    }

    this.onScroll = this.onScroll.bind(this);
    this.componentRef = React.createRef();
  }
  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, false);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false);
  }
  onScroll() {
    // Handle the opacity of the text
    window.componentRef = this.componentRef;
    if (this.componentRef.current) {
      const componentOffset = this.componentRef.current.getBoundingClientRect();
      const offsetTop = componentOffset.top;
      const offsetBottom = componentOffset.bottom;
      const viewPortHeight = window.innerHeight;
      const scrollVal = offsetTop - (viewPortHeight - offsetBottom);

      const selected = {
        opacity: 1,
        paddingLeft: 10,
        scale: 1.05,
      };
      const unselected = {
        opacity: 0.5,
        paddingLeft: 0,
        scale: 1,
      }

      if (scrollVal > (viewPortHeight / 4)) {
        this.setState({
          firstText: selected,
          secondText: unselected,
          thirdText: unselected,
          firstImageOpacity: 1,
          secondImageOpacity: 0,
          thirdImageOpacity: 0,
        });
      }
      if (scrollVal < (viewPortHeight / 4) && scrollVal > -(viewPortHeight / 4)) {
        this.setState({
          firstText: unselected,
          secondText: selected,
          thirdText: unselected,
          firstImageOpacity: 0,
          secondImageOpacity: 1,
          thirdImageOpacity: 0,
        });
      }
      if (scrollVal < -(viewPortHeight / 4)) {
        this.setState({
          firstText: unselected,
          secondText: unselected,
          thirdText: selected,
          firstImageOpacity: 0,
          secondImageOpacity: 0,
          thirdImageOpacity: 1,
        });
      }
    }
  }
  render() {
    const { featureText } = this.props;

    return (
      <StickyContainer>
        <Section>
          <Container>
            <Row style={{ marginLeft: 'auto', marginRight: 'auto' }}>
              <Col md={6} className="d-none d-md-block" style={{ minHeight: '50rem' }}>
                {/*<Sticky style={{ top: '30%' }}>*/}
                <div style={{ top:"calc(50% - 220px)", position:"sticky" }}>
                  <SliderHero
                    firstImageOpacity={this.state.firstImageOpacity}
                    secondImageOpacity={this.state.secondImageOpacity}
                    thirdImageOpacity={this.state.thirdImageOpacity}
                  />
                </div>
                {/*</Sticky>*/}
              </Col>
              <Col md={6}>
                <Heading>Great software improves everything</Heading>
                <Subheading>So shortlisting software should be simple</Subheading>

                <SliderHero
                  firstImageOpacity={this.state.firstImageOpacity}
                  secondImageOpacity={this.state.secondImageOpacity}
                  thirdImageOpacity={this.state.thirdImageOpacity}
                  style={{ zoom: 0.8 }}
                  className="d-flex d-md-none mt-5"
                />

                <span ref={this.componentRef}>
                  <motion.div
                    animate={this.state.firstText}
                  >
                    <Item title={featureText[0].feature_title}>
                      <p>{featureText[0].feature_text}</p>
                    </Item>
                  </motion.div>

                  <motion.div
                    animate={this.state.secondText}
                  >
                    <Item title={featureText[1].feature_title}>
                      <p>{featureText[1].feature_text}</p>
                    </Item>
                  </motion.div>

                  <motion.div
                    animate={this.state.thirdText}
                  >
                    <Item title={featureText[2].feature_title}>
                      <p>{featureText[2].feature_text}</p>
                    </Item>
                  </motion.div>
                </span>
              </Col>
            </Row>
          </Container>
        </Section>
      </StickyContainer>
    );
  }
}

export default SoftwareSliderSection
