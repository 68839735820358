import React, { Component } from 'react'
import ReactSVG from 'react-svg'
import styled from 'styled-components'
import _ from 'lodash';

import FeatureContainer from './common/FeatureContainer'
import Heading from './common/Heading'

import FacebookIconPath from '../../svg/new-social-facebook.svg'
import TwitterIconPath from '../../svg/new-social-twitter.svg'
import CrunchbaseIconPath from '../../svg/new-social-crunchbase.svg'
import LinkedInIconPath from '../../svg/new-social-linkedin.svg'

const FacebookIcon = (props) => (
  <ReactSVG src={FacebookIconPath} {...props} />
)

const TwitterIcon = (props) => (
  <ReactSVG src={TwitterIconPath} {...props} />
)

const CrunchbaseIcon = (props) => (
  <ReactSVG src={CrunchbaseIconPath} {...props} />
)

const LinkedInIcon = (props) => (
  <ReactSVG src={LinkedInIconPath} {...props} />
)

const DescriptionTerm = styled.dt`
  width: 10rem;
  font-weight: 600;
  font-size: 0.875rem;
`

const DescriptionDefinition = styled.dt`
  font-weight: 400;
  width: 100%;
  font-size: 0.875rem;
`

const SocialList = styled.ul`
  display: inline-block;
  padding: 0;
  list-style: none;
  margin: 0;

  li {
    display: inline-block;
    margin-right: 1.5rem;
  }
`

export default class VendorInformation extends Component {
  render() {
    let stats = '';
    if (this.props.clearbit !== null) {
      stats = (<dl className="my-4">
        <div className="d-flex py-1">
          <DescriptionTerm>Revenue</DescriptionTerm>
          <DescriptionDefinition>{_.get(this.props.clearbit, 'enrichment_data.metrics.estimatedAnnualRevenue')}</DescriptionDefinition>
        </div>
        <div className="d-flex py-1">
          <DescriptionTerm>Headquarters</DescriptionTerm>
          <DescriptionDefinition>{_.get(this.props.clearbit, 'enrichment_data.location')}</DescriptionDefinition>
        </div>
        <div className="d-flex py-1">
          <DescriptionTerm>Employees</DescriptionTerm>
          <DescriptionDefinition>{_.get(this.props.clearbit, 'enrichment_data.metrics.employeesRange')}</DescriptionDefinition>
        </div>
        <div className="d-flex py-1">
          <DescriptionTerm>Founded</DescriptionTerm>
          <DescriptionDefinition>{_.get(this.props.clearbit, 'enrichment_data.foundedYear')}</DescriptionDefinition>
        </div>
      </dl>);
    }

     const socials = [];

    if (_.get(this.props.clearbit, 'enrichment_data.facebook.handle', null) !== null) {
      socials.push(
          <li key="facebook">
            <a target="_blank" href={`https://facebook.com/${this.props.clearbit.enrichment_data.facebook.handle}`}><FacebookIcon /></a>
          </li>
      );
    }
    if (_.get(this.props.clearbit, 'enrichment_data.twitter.handle', null) !== null) {
      socials.push(
          <li key="twitter">
            <a target="_blank" href={`https://twitter.com/${this.props.clearbit.enrichment_data.twitter.handle}`}><TwitterIcon /></a>
          </li>
      );
    }
    if (_.get(this.props.clearbit, 'enrichment_data.crunchbase.handle', null) !== null) {
      socials.push(
          <li key="crunchbase">
            <a target="_blank" href={`https://crunchbase.com/${this.props.clearbit.enrichment_data.crunchbase.handle}`}><CrunchbaseIcon /></a>
          </li>
      );
    }
    if (_.get(this.props.clearbit, 'enrichment_data.linkedin.handle', null) !== null) {
      socials.push(
          <li key="linkedin">
            <a target="_blank" href={`https://linkedin.com/${this.props.clearbit.enrichment_data.linkedin.handle}`}><LinkedInIcon /></a>
          </li>
      );
    }

    return (
      <FeatureContainer>
        <Heading>Vendor Information</Heading>
        <div dangerouslySetInnerHTML={{__html: this.props.vendor.description}} />

        {stats}

        <SocialList>
          {socials}
        </SocialList>
      </FeatureContainer>
    )
  }
}
