import React, { Component } from 'react'
import { Row, Col, Progress as BootstrapProgress } from 'reactstrap'
import ReactSVG from 'react-svg'
import styled from 'styled-components'

import FeatureContainer from './common/FeatureContainer'
import Plan from './pricing/Plan'
import Plans from './pricing/Plans'
import Heading from './common/Heading'
import CompareButton from '../common/CompareButton';
import QuoteIconPath from '../../svg/new-quote.svg'
import MoneyIconPath from '../../svg/new-money.svg'

import StopclockPath from '../../svg/new-stopclock.svg'

import OrgIconLargePath from '../../svg/new-org-icon-large.svg'
import OrgIconMediumPath from '../../svg/new-org-icon-medium.svg'

const QuoteIcon = (props) => (
  <ReactSVG src={QuoteIconPath} {...props} wrapper="span" />
)

const MoneyIcon = (props) => (
  <ReactSVG src={MoneyIconPath} {...props} wrapper="span" />
)

const Stopclock = (props) => (
  <ReactSVG src={StopclockPath} {...props} wrapper="span" />
)

const OrgIconLarge = (props) => (
  <ReactSVG src={OrgIconLargePath} {...props} />
)

const OrgIconMedium = (props) => (
  <ReactSVG src={OrgIconMediumPath} {...props} />
)

const PricingHeading = styled(Heading)`
  display: flex;
  align-items: center;
  margin: 0;
`

const Progress = styled(BootstrapProgress)`
  height: .25rem !important;
  background: #EBEBEB;
  margin-top: 1.5rem;

  .progress-bar {
    background: #087F5B;
  }
`

const TrialLabel = styled.div`
  background: #f5faf9;
  border-radius: 12px;
  color: #087f5b;
  font-size: 0.875rem;
  line-height: 1;
  padding: 0.25rem 0.5rem;
`

const StyledStopclock = styled(Stopclock)`
  height: 1rem;
  width: 1rem;
  margin-right: 0.5rem;

  path {
    fill: #087f5b;
  }
`

const IconContainer = styled.div`

`

const TextContainer = styled.div`
  margin-left: 0.75rem;
`

const BudgetCostings = styled.div`
  color: #585858;
  font-size: 0.75rem;
`

export default class Pricing extends Component {
  render() {
    let cheapest;
    let planContent = <p><QuoteIcon /> Custom pricing on application</p>;
    if (this.props.pricing.length > 0) {
      cheapest = this.props.pricing.reduce((accumulator, current) => parseFloat(current.price) < parseFloat(accumulator.price) ? current : accumulator);
    }
    const plans = this.props.pricing.map((plan, index) => (
        <Plan key={index} name={plan.plan_name} currency="$" period={plan.pricing_model} price={plan.price} cheapest={plan.id === cheapest.id} />
    ));

    if (this.props.pricing.length > 0) {
      planContent = (<div>
        <Plans multiple={this.props.pricing.length > 1} scrollable={this.props.pricing.length > 2}>
          {plans}
        </Plans>

        <p><MoneyIcon /> Starts at ${cheapest.price} per month, but will vary depending on your own business needs.</p>

        <h5>How much does {this.props.product.name} cost?</h5>
        {this.props.text}
      </div>);
    }

    let affordability = '';
    if (this.props.customerSize) {
      let affordabilityVal = 0;

      const hasSmallDataValue = this.props.customerSize
          .filter(customerSize => customerSize.name.toLowerCase().indexOf('small') !== -1)
          .length > 0;
      const hasMediumDataValue = this.props.customerSize
          .filter(customerSize => customerSize.name.toLowerCase().indexOf('medium') !== -1)
          .length > 0;
      const hasEnterpriseDataValue = this.props.customerSize
          .filter(customerSize => customerSize.name.toLowerCase().indexOf('enterprise') !== -1)
          .length > 0;

      if (hasEnterpriseDataValue) {
        affordabilityVal = (100/3);
      }
      if (hasMediumDataValue) {
        affordabilityVal = (100/3)*2;
      }
      if (hasSmallDataValue) {
        affordabilityVal = 100;
      }

      affordability = (
          <div className="my-4">
            <h5>Affordability</h5>

            <Progress value={affordabilityVal} />
            <Row className="my-3">
              <Col>
                <div className="d-flex">
                  <IconContainer><OrgIconLarge /></IconContainer>
                  <TextContainer>
                    Within budget
                    <BudgetCostings>Enterprise</BudgetCostings>
                  </TextContainer>
                </div>
              </Col>
              <Col>
                <div className="d-flex">
                  <IconContainer><OrgIconLarge /></IconContainer>
                  <TextContainer>
                    {affordabilityVal > (100/3) ? 'Within' : 'Outside'} budget
                    <BudgetCostings>Medium-sized business</BudgetCostings>
                  </TextContainer>
                </div>
              </Col>
              <Col>
                <div className="d-flex">
                  <IconContainer><OrgIconMedium /></IconContainer>
                  <TextContainer>
                    {affordabilityVal === 100 ? 'Within' : 'Outside'} budget
                    <BudgetCostings>Small business</BudgetCostings>
                  </TextContainer>
                </div>
              </Col>
            </Row>
          </div>
      );
    }

    let trialLabel = <TrialLabel><StyledStopclock />Free trial unavailable</TrialLabel>;
    if (null !== this.props.freeTrialDays && '' !== this.props.freeTrialDays) {
      trialLabel = <TrialLabel><StyledStopclock />{this.props.freeTrialDays}-Day Free Trial</TrialLabel>;
    }

    return (
      <FeatureContainer id="pricing">
        <div className="d-flex">
          <PricingHeading>Pricing</PricingHeading>
          <div className="d-flex align-items-center ml-3">
            {trialLabel}
          </div>
          <div className="ml-auto">
            <CompareButton productSlug={this.props.product.slug} buttonText="Compare Pricing" />
          </div>
        </div>
        {planContent}
        {affordability}
      </FeatureContainer>
    )
  }
}
