import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {
  Collapse,
  Nav as BSNav
} from 'reactstrap'
import NavItem from './NavItem'
import NavLink from './NavLink'
import BackButton from "./BackButton";

const Nav = styled(BSNav)`
  padding-top: 1.25rem;
`

const ButtonWrapper = styled.div`
  margin: 0 16px;
  margin-bottom: 1.25rem;
`

class MobileNav extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showCategories: false,
    }

    this.mobileCategories = window.mobileNavCategories
  }
  toggleCategories(event) {
    event.preventDefault()

    this.setState({
      showCategories: !this.state.showCategories
    })

    this.toggleCategories = this.toggleCategories.bind(this)
  }
  render() {
    return (
      <Collapse {...this.props} navbar>
        <Nav className="d-block d-md-none d-lg-none d-xl-none">

          {!this.state.showCategories && (
            <div>
              <NavItem>
                <NavLink onClick={e => this.toggleCategories(e)} href="#">Browse categories</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/guides">Guides</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/login">Log In</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/register">Sign Up</NavLink>
              </NavItem>
            </div>
          )}

          {this.state.showCategories && (
            <div>
              <ButtonWrapper>
                <BackButton onClick={(e) => this.toggleCategories(e)}>Back</BackButton>
              </ButtonWrapper>
              {this.mobileCategories.map((category, index) => (
                <NavItem key={index}>
                  <NavLink href={`/${category.slug}`}>{category.name}</NavLink>
                </NavItem>
              ))}
            </div>
          )}
        </Nav>
      </Collapse>
    )
  }
}

MobileNav.propTypes = {
  isOpen: PropTypes.bool.isRequired,
}

export default MobileNav