import Axios from "axios";

export const USER_RECEIVED = 'USER_RECEIVED'

const getUser = () => (
    dispatch => {
      return Promise.resolve()
      // return Axios.get(`/api/user`).then(response => {
      //   const user = response.data.data;
      //
      //   dispatch({
      //     type: USER_RECEIVED,
      //     user,
      //   })
      // })
    }
)

export default getUser
