import React from 'react'
import ReactSVG from 'react-svg'
import styled from 'styled-components'
import StarIconPath from '../../svg/new-star.svg'

const ReactStarIcon = (props) => (
  <ReactSVG src={StarIconPath} {...props} wrapper="span" />
)

const StarIcon = styled(ReactStarIcon)`
  svg {
    width: ${(props) => props.width};
    height: ${(props) => props.height};

    use {
      fill: ${(props) => props.fill};
    }
  }
`

export default StarIcon