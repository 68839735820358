import first from 'lodash/first'

export const manySelectedPlaceholder = (selectedItems) => {
  const selectedItem = first(selectedItems)
  const remainingItemsCount = selectedItems.length - 1;

  if (selectedItem !== undefined) {
    return `${selectedItem.name} +${remainingItemsCount} more`
  }

  return ''
}

export const isAuthenticated = () => {
  return window.authenticated
}

export const isGuest = () => {
  return !isAuthenticated()
}