import React from 'react'
import styled from 'styled-components'

import PlanCellContainer from './PlanCellContainer'
import ChevronDownIcon from './ChevronDownIcon'

const PlanContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  padding: 8px 12px;
`

const PlanDetails = styled.div`
  color: #585858;
  text-align: left;
`

const PlanName = styled.div`

`

const PlanPeriod = styled.div`
  font-size: 0.75rem;
`

const PlanPrice = styled.div`
  margin-left: auto;
  color: #161616;
  font-weight: 600;
`

const ExpandableContainer = styled.div`
  margin-left: 0.5rem;
`


const Plan = ({ featured, firstItem, lastItem, name, period, price, expandable, children, className }) => (
  <PlanCellContainer
    featured={featured}
    firstItem={firstItem}
    lastItem={lastItem}
    className={className}
  >
    <PlanContainer>
      <PlanDetails>
        <PlanName>
          {name}
        </PlanName>
        <PlanPeriod>
          {period}
        </PlanPeriod>
      </PlanDetails>

      {price && (
        <PlanPrice>
          ${parseFloat(price)}
        </PlanPrice>
      )}

      {expandable && (
        <ExpandableContainer>
          <ChevronDownIcon></ChevronDownIcon>
        </ExpandableContainer>
      )}
    </PlanContainer>
  </PlanCellContainer>
)

export default Plan
