import React, { Component } from 'react'
import styled from 'styled-components'
import { Alert } from 'reactstrap';

import Button from './Button'
import Input from './Input'
import InputGroup from './InputGroup'
import Label from './Label'

const ErrorList = styled.ul`
  margin-bottom: 0;
  font-family: "HK Grotesk";
`

export default class PasswordResetForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      password_confirmation: '',
      token: '',
      emailToken: '',
      route: '',
      errors: []
    }

    this.handleChange = this.handleChange.bind(this)
  }
  componentWillMount() {
    const token = document.querySelector('meta[name="csrf-token"]').content
    const emailToken = window.token
    const email = window.loginEmail !== null ? window.loginEmail : ''
    const errors = window.errors
    const route = window.route

    this.setState({
      token,
      emailToken,
      errors,
      email,
      route
    })
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }
  render() {
    return (
      <div>
        {this.state.errors.length > 0 && (
          <Alert color="danger">
            <ErrorList>
              {this.state.errors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ErrorList>
          </Alert>
        )}
        <form method="POST" action={this.state.route}>
          <input type="hidden" name="_token" value={this.state.token} />
          <input type="hidden" name="token" value={this.state.emailToken}></input>
          <InputGroup>
            <Label htmlFor="email">
              Email
            </Label>
            <Input 
              placeholder="you@email.com"
              id="email"
              name="email"
              onChange={this.handleChange}
              value={this.state.email}
            />
          </InputGroup>
          
          <InputGroup>
            <Label htmlFor="password">
              Password
            </Label>
            <Input
              type="password"
              id="password"
              name="password"
              onChange={this.handleChange}
              value={this.state.password}
            />
          </InputGroup>

          <InputGroup>
            <Label htmlFor="password_confirmation">
              Confirm Password
            </Label>
            <Input
              type="password"
              id="password_confirmation"
              name="password_confirmation"
              onChange={this.handleChange}
              value={this.state.password_confirmation}
            />
          </InputGroup>

          <Button>Reset Password</Button>
        </form>
      </div>
    )
  }
}