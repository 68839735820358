import Axios from "axios";
import { isGuest } from '../helpers'
import store from '../store'
import getShortlists from './get-shortlists'

export const PRODUCT_REMOVED_FROM_CURRENT_SHORTLIST = 'PRODUCT_REMOVED_FROM_CURRENT_SHORTLIST'

const removeProductFromSessionShortlist = (productId, dispatch) => {
  Axios.post(
    '/api/session/shortlist/remove-product',
    {
      product_id: productId,
    }
  )
  .then(() => {
    dispatch({
      type: PRODUCT_REMOVED_FROM_CURRENT_SHORTLIST,
      productId,
    })
  })
}

const removeProductFromAuthenticatedShortlist = (productId, dispatch) => {
  const shortlistId = store.getState().shortlists.current.shortlist.id

  Axios.delete(`/api/shortlists/${shortlistId}/products/${productId}`).then(() => {
    dispatch({
      type: PRODUCT_REMOVED_FROM_CURRENT_SHORTLIST,
      productId
    })
  })
  .catch(error => {
    if (error.response && error.response.status === 404) {
      store.dispatch(getShortlists())
    }
  })
}

const removeProductFromCurrentShortlist = productId => (
    dispatch => {
      if (isGuest()) {
        removeProductFromSessionShortlist(productId, dispatch)
      } else {
        removeProductFromAuthenticatedShortlist(productId, dispatch)
      }
    }
)

export default removeProductFromCurrentShortlist
