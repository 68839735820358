import styled from 'styled-components'

const Button = styled.button`
  border: 0;
  color: #fff;
  background: #3040e1;
  border-radius: 12px;
  padding: 12px 20px;
  width: 100%;

  &:focus {
    outline: none;
  }
`
export default Button