import React, { Component } from 'react'
import styled from 'styled-components'
import { Col, Row, Container } from 'reactstrap'
import { Banner as DefaultBanner } from 'prospect-path-base'

import Screenshots from '../components/result/Screenshots'
import Similar from '../components/result/Similar'
import ShortlistPicker from '../components/common/ShortlistPicker'
import GuestShortlistButton from '../components/result/GuestShortlistButton'

import Label from '../components/common/Label';

import { isAuthenticated, isGuest } from '../helpers'

const Button = styled.a`
  background: ${props => (props.primary ? '#3040E1' : '#fff')};
  border: 1px solid ${props => (props.primary ? '#3040E1' : '#f2f2f2')};
  color: ${props => (props.primary ? '#fff' : '#585858')};
  text-align: center;
  font-family: "HK Grotesk";
  width: 100%;
  padding: .75rem 1rem;
  display: block;
  border-radius: 12px;
  font-weight: 600;
  margin-bottom: 0.5rem;

  &:hover {
    color: #fff;
    color: ${props => (props.primary ? '#fff' : '#585858')};
    text-decoration: none;
  }
`

const Card = styled.div`
  padding: 1rem;
  border-radius: 12px;
  background-color: #FFFFFF;
  box-shadow: 0 -1px 2px 0 rgba(22,22,22,0.04), 0 2px 4px 0 rgba(22,22,22,0.12);
`

const Image = styled.img`
  width: 5rem;
  height: 5rem;
  border: 1px solid #EBEBEB;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(33,37,41,0.12);}
  padding: 0.2rem;
  object-fit: contain;
`

const HeadingContainer = styled.div`
  display: flex;
  padding: 0 1rem 0 1.5rem;
  flex-direction: column;
  align-items: flex-start;
`

const Banner = styled(DefaultBanner)`
  margin: 1rem 0;
`

const BannerTitle = styled.span`
  font-family: "HK Grotesk", sans-serif;
`

const BannerBody = styled.span`
  font-family: "HK Grotesk", sans-serif;
`

const ClaimText = styled.p`
  font-family: "HK Grotesk", sans-serif;
  text-align: center;
  font-size: 1rem;
  margin: 0 0 0.5rem 0;
`

export default class Result extends Component {
  render() {
    const labels = this.props.data.categories.map((category, index) => category.short_name ? <Label href={`/${category.slug}`} key={index}>{category.short_name}</Label> : '');

    // Use intro from text generation with description as default
    let intro = <div className="py-4 m-0" dangerouslySetInnerHTML={{__html:this.props.data.description}} />;
    if (this.props.data.text.intro.length > 0) {
      intro = this.props.data.text.intro.map((text, index) => <p key={index} className="py-4 m-0">{text}</p>);
    }

    let similarContainer = '';
    if (this.props.data.alternatives.length > 0) {
      similarContainer = <Similar alternatives={this.props.data.alternatives} />;
    }

    let screenshots = '';
    if (this.props.data.screenshots.length > 0) {
      screenshots = <Screenshots divider={false} screenshots={this.props.data.screenshots} />;
    }

    return (
      <Container>
        <Row>
          <Col sm={12} md={9}>
            <div className="d-flex py-3">
              <div><Image src={ this.props.data.product.image ? this.props.data.product.image : "https://via.placeholder.com/82x82"} /></div>
              <HeadingContainer>
                <h1>{this.props.data.product.name}</h1>
                <div>
                  {labels}
                </div>
              </HeadingContainer>
            </div>

            <Banner
              title={<BannerTitle>{`${this.props.data.product.name} haven’t completed their profile`}</BannerTitle>}
              closeable={false}
            >
              <BannerBody>
                They haven’t provided us with enough information to provide a detailed comparison. View popular alternatives below
              </BannerBody>
            </Banner>

            {screenshots}

            {similarContainer}
          </Col>
          <Col sm={12} md={3}>
            <Card>
              <div>
                {isGuest() && <GuestShortlistButton disabled productSlug={this.props.data.product.slug} />}
                {isAuthenticated() && <ShortlistPicker disabled productSlug={this.props.data.product.slug} />}
              </div>
              <div>
                <Button href={this.props.data.vendor.website}>Go to Website</Button>
              </div>
            </Card>

            <Card style={{ marginTop: '1rem' }}>
              <ClaimText>Work for this vendor?</ClaimText>

              <Button href="https://app.prospectpath.com/signup/">Complete profile</Button>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}
