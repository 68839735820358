import Axios from "axios";
import getShortlists from './get-shortlists'
import store from '../store'

export const INVITATION_REMOVED_FROM_SHORTLIST = 'INVITATION_REMOVED_FROM_SHORTLIST'

const removeInvitationFromShortlist = (shortlist, invitationId) => (
    dispatch => {
      Axios.delete(`/api/shortlists/${shortlist.id}/invitations/${invitationId}`).then(() => {
        dispatch({
          type: INVITATION_REMOVED_FROM_SHORTLIST,
          shortlist,
          invitationId,
        })
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          store.dispatch(getShortlists())
        }
      })
    }
)

export default removeInvitationFromShortlist
