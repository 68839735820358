import styled from 'styled-components'

import CellContainer from './CellContainer'

const PlanCellContainer = styled(CellContainer)`
  height: 3.5rem;
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  font-family: "HK Grotesk";
  margin-top: 0;
  margin-bottom: 0;
`

export default PlanCellContainer
