import React, { Component } from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import SearchInput from './SearchInput'

const Heading = styled.h3`
  font-weight: 800;
`

const Subheading = styled.h4`
  font-size: 1.1rem;
  font-weight: 500;
`

export default class CategoriesSearch extends Component
{
  render() {
    return (
      <Container>
        <Row>
          <Col md={6} lg={6} xl={6}>
            <Heading>
              Can’t find what you’re looking for?
            </Heading>
            <Subheading>Try searching for software names or the feature you’re looking for.</Subheading>
          </Col>
          <Col md={6} lg={6} xl={6}>
            <form action="/search">
              <SearchInput name="q" placeholder="Search an app, company or category…" />
            </form>
          </Col>
        </Row>
      </Container>
    )
  }
}