import React from 'react'
import styled from 'styled-components'
import { mediaBreakpointDownMd } from 'styled-bootstrap-responsive-breakpoints'
import Downshift from 'downshift'
import ReactSVG from 'react-svg'
import axios from 'axios';
import _ from 'lodash';

import Button from '../../common/Button'
import Label from '../../common/Label'

import PatternImage from '../../../svg/pattern.svg'
import SearchPath from '../../../svg/new-search.svg'


const SearchContainer = styled.div`
  position: relative;
`

const InnerSearchContainer = styled.div`
  border: 1px solid #EBEBEB;
  border-radius: 24px;
  background-color: #FCFCFC;
  box-shadow: inset 0 2px 4px 0 rgba(22,22,22,0.08);
  display: flex;
  padding: 1rem;
  position: relative;
`

const SearchInput = styled.input`
  background: none;
  border: none;
  font-size: 1.25rem;
  flex: 1;
  padding-left: 0.5rem;
  line-height: 28px;
  font-weight: 500;
  overflow: hidden;

  &:focus {
    outline: 0;
  }
`

const SearchButton = styled.button`
  border: 0;
  background-color: #3040E1;
  border-radius: 12px;
  box-shadow: 0 4px 12px -4px rgba(28,38,135,0.4);
  color: #FFFFFF;
  display: flex;
  font-size: 20px;
  padding: 0.875rem 1.25rem;
  user-select: none;
  font-weight: 500;

  &:focus {
    outline: 0;
  }

  ${mediaBreakpointDownMd`
    padding: 0.75rem;
  `}
`

const SuggestionHeading = styled.h5`
  font-size: 14px;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
`

const SuggestionsDropdown = styled.div`
  background-color: #FFFFFF;
  box-shadow: 0 -1px 2px 0 rgba(22,22,22,0.04), 0 2px 4px 0 rgba(22,22,22,0.12);
  border-radius: 0 0 24px 24px;
  padding: 0.5rem 1rem 1.5rem 1rem;
  position: absolute;
  top: 82%;
  width: 100%;
  z-index: 10;
`

const SuggestionList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const SuggestionTitle = styled.span`
  flex: 1;
`

const SuggestionListItem = styled.li`
  align-items: center;
  display: flex;
  border-radius: 8px;
  padding: 0.5rem;

  &:hover {
    background-color: #E6E8FC;
    cursor: pointer;

    ${SuggestionTitle} {
      color: #3040E1;
    }
  }
`

const SuggestionThumbnail = styled.img`
  margin-right: 0.75rem;
  max-height: 28px;
  max-width: 28px;
`

const Pattern = styled.img`
  position: absolute;
  right: -2.2rem;
  z-index: -1;
  bottom: -2.2rem;

  ${mediaBreakpointDownMd`
    display: none;
  `}
`

const SearchIcon = styled(ReactSVG)`
  display: flex;
  margin-right: 0.5rem;
  margin-top: auto;
  margin-bottom: auto;

  svg {
    display: flex;

    path {
      fill: #979FF0;
    }
  }
`

class SearchForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      results: [],
      categories: [],
    };

    this.changeSearch = this.changeSearch.bind(this);
    this.searchRequest = _.debounce(this.searchRequest, 500);
  }
  changeSearch(value) {
    this.setState({
      searchText: value,
    });
    this.searchRequest(value);

    return value
  }
  searchRequest(value) {
    axios.get('/search', {
      params: {
        q: value,
      }
    }).then((response) => {
      this.setState({
        results: response.data.products.map(product => {
          const tags = product.categories.slice(0, 2).map(category => category.short_name);
          if (product.categories.length > 2) {
            tags.push(`+${product.categories.length - 2} more`);
          }

          return {
            value: product.name,
            type: 'product',
            thumbnail: product.display_logo,
            link: product.link,
            tags,
          }
        }),
        categories: response.data.categories.map(category => ({
          ...category,
          type: 'category',
          link: `/${category.slug}`,
        })),
      });
    });
  }
  render() {
    return (
        <form {...this.props} autoComplete="off">
          <Downshift
              onChange={selection => window.location = selection.link}
              itemToString={item => (item ? item.value : this.state.searchText)}
              onInputValueChange={this.changeSearch}
          >
            {({
                getInputProps,
                getItemProps,
                getMenuProps,
                isOpen,
                inputValue,
                highlightedIndex,
                selectedItem,
                getRootProps,
              }) => (
                <SearchContainer>
                  <div
                      style={{display: 'inline-block', width: '100%'}}
                      {...getRootProps({}, { suppressRefError: true })}
                  >
                    <InnerSearchContainer>
                      <SearchInput {...getInputProps()} value={this.state.searchText} type="text" placeholder="Search software..." name="q" />
                      <SearchButton onClick={(e) => {e.preventDefault(); window.location = `/search?q=${inputValue}`}}><SearchIcon src={SearchPath} /> Search</SearchButton>
                      <Pattern src={PatternImage} />
                    </InnerSearchContainer>
                  </div>
                  {isOpen ? (
                      <SuggestionsDropdown {...getMenuProps()}>
                        <SuggestionHeading>Top Results</SuggestionHeading>
                        <SuggestionList>
                          {this.state.results
                              .slice(0, 3)
                              .filter(item => {
                                if (!inputValue) {
                                  return false;
                                }

                                return item.value.toLowerCase().includes(inputValue.toLowerCase());
                              })
                              .map((item, index) => (
                                  <SuggestionListItem
                                      {...getItemProps({
                                        key: item.value,
                                        index,
                                        item,
                                      })}
                                  >
                                    <SuggestionThumbnail src={item.thumbnail} />
                                    <SuggestionTitle>{item.value}</SuggestionTitle>
                                    <span>
                        {item.tags.map((tag) => (
                            <Label>{tag}</Label>
                        ))}
                      </span>
                                  </SuggestionListItem>
                              ))}
                        </SuggestionList>

                        <SuggestionHeading>Categories</SuggestionHeading>
                        <SuggestionList>
                          {this.state.categories
                              .filter(item => !inputValue || item.name.toLowerCase().includes(inputValue.toLowerCase()))
                              .slice(0,3)
                              .map((item, index) => (
                                  <SuggestionListItem
                                      {...getItemProps({
                                        key: item.id,
                                        index,
                                        item,
                                      })}
                                  >
                                    <SuggestionTitle>{item.name}</SuggestionTitle>
                                    {item.products > 0 && <Label>{item.products} products</Label>}
                                  </SuggestionListItem>
                              ))}
                        </SuggestionList>
                        <Button style={{ marginTop: '1rem' }} onClick={() => window.location = `/search?q=${inputValue}`}>View all results</Button>
                      </SuggestionsDropdown>
                  ) : null}
                </SearchContainer>
            )}
          </Downshift>
        </form>
    );
  }
}

export default SearchForm
