import rootReducer from './reducers/root-reducer'
import thunk from 'redux-thunk'
import { createStore, applyMiddleware, compose } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

const initialState = {}
const middleware = [
  thunk,
]

let composedEnhancers = compose(
    applyMiddleware(...middleware)
)

if (process.env.NODE_ENV === 'development') {
  composedEnhancers = composeWithDevTools(
      applyMiddleware(...middleware)
  )
}

const store = createStore(
    rootReducer,
    initialState,
    composedEnhancers
)

export default store
