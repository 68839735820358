import React from 'react'
import styled from 'styled-components'

const CancelButton = styled.button`
  font-family: "HK Grotesk";
  border: 0;
  color: #3040E1;
  padding-left: 1rem;
  background: none;

  &:focus {
    outline: 0;
  }
`

export default CancelButton