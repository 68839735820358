import React, { Component } from 'react'
import styled from 'styled-components'

import FeatureContainer from './common/FeatureContainer'
import Listing from '../common/Listing'
import Heading from './common/Heading'

const ListingContainer = styled.div`
  box-shadow: 0 -1px 2px 0 rgba(22,22,22,0.04), 0 2px 4px 0 rgba(22,22,22,0.12);
  border-radius: 12px;
  margin: 1.5rem 0;
`

export default class Similar extends Component {
  constructor(props) {
    super(props)

    this.selectListing = this.selectListing.bind(this)
  }
  selectListing() {
    console.log('select')
  }
  render() {
    const alternatives = this.props.alternatives.map(alternative => {
      let description = '';
      if (alternative.description) {
        description = alternative.description.substr(0, 140) + (alternative.description.length > 140 ? '...' : '');
      }

      return (<Listing
          key={alternative.id}
          image={alternative.display_logo}
          name={alternative.name}
          slug={alternative.slug}
          description={description}
          link={alternative.link}
          labels={alternative.categories.map(category => ({name: category.short_name ? category.short_name : category.slug, link: `/${category.slug}`}))}
          onClick={this.selectListing}
      />)
    });

    return (
      <FeatureContainer id="alternatives">
        <Heading>Similar Alternatives</Heading>

        <ListingContainer>
          {alternatives}
        </ListingContainer>
      </FeatureContainer>
    )
  }
}
