import React from 'react'
import styled from 'styled-components'

const LinkButton = styled.a`
  border-radius: 12px;
  color: #161616;
  font-family: "HK Grotesk";
  font-weight: 600;
  font-size: 1rem;
  padding: 0.6rem 1rem;
  text-decoration: none;
  margin-right: 0.6rem;

  &:hover {
    color: #3040e1;
    background: #e6e8fc;
    text-decoration: none;
  }

  ${(props) => props.selected && `
    color: #3040e1;
    background: #e6e8fc;
  `}
`

export default LinkButton