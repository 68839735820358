import React, { Component } from 'react'
import styled from 'styled-components'

import { default as UnstyledProductImage } from '../common/ProductImage'

import CellContainer from './CellContainer'
import CheckIcon from './CheckIcon'
import CrossIcon from './CrossIcon'
import Rating from './Rating'
import PlanCell from './PlanCell'

const Container = styled.div`
  width: 240px;
  min-width: 240px;
`

const TableHeading = styled.div`
  height: 10rem;
  
  &:hover {
    cursor: pointer;
  }
`

const Heading = styled.h3`
  font-size: 1.25rem;
  text-align: center;
  padding: 0.5rem 0;
`

const TrialLength = styled.div`
  color: #087f5b;
  font-family: "HK Grotesk";
  font-weight: 600;
`

const SectionRule = styled.hr`
  border-top:  1px solid #EBEBEB;
  margin: 0;
  ${props => props.show ? null : 'margin-bottom: 2rem;'}
`

const Section = styled.div`
  padding-top: 30px;
  padding-bottom: 38px

  &:last-child {
    padding-bottom: 0;
  }

  ${(props) => props.show ? null : 'display: none;'}
`

const ReviewCount = styled.span`
  color: #3040e1;
  font-size: 14px;
  padding-left: 5px;
`

const ProductImage = styled(UnstyledProductImage)`
  height: 64px;
  width: 64px;
`

export default class ShortlistItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPlan: this.props.plans.length > 0 ? this.props.plans[0] : null,
    };
    this.setPlan = this.setPlan.bind(this);
    this.props.setCurrentPricingPlan(this.props.productId,  this.props.plans[0]);
  }
  setPlan(plan) {
    this.setState({currentPlan: plan});
    this.props.setCurrentPricingPlan(this.props.productId, plan);
  }
  componentDidMount() {
    if (this.operatingSystemCell) {
      this.props.updateCellHeight('operatingSystem', this.operatingSystemCell.clientHeight);
    }
  }

  render() {
    // If the product is not verified leave cells blank rather than displaying a cross
    let crossIcon = '';
    if (this.props.verified) {
      crossIcon = <CrossIcon />;
    }

    return (
      <Container>
        <TableHeading onClick={() => { window.open(`/software/${this.props.slug}`) }}>
          <ProductImage src={this.props.image} />
          <Heading>{this.props.name}</Heading>
        </TableHeading>

        <SectionRule show={!this.props.hideOverview} />
        <Section show={!this.props.hideOverview}>
          {this.props.showReviews && (
            <CellContainer
              featured={this.props.featured}
              firstItem={this.props.firstItem}
              lastItem={this.props.lastItem}
            >
              <Rating rating={this.props.rating} />
              <ReviewCount>({this.props.reviewCount})</ReviewCount>
            </CellContainer>
          )}
          <CellContainer
            featured={this.props.featured}
            firstItem={this.props.firstItem}
            lastItem={this.props.lastItem}
            fixedHeight
          >
            {this.props.verified && <CheckIcon />}
            {!this.props.verified && crossIcon}
          </CellContainer>
          <CellContainer
              featured={this.props.featured}
              firstItem={this.props.firstItem}
              lastItem={this.props.lastItem}
          >
            <Rating rating={this.props.averageReviews} />
            {null !== this.props.averageReviews && this.props.averageReviews !==  'N/A' && <ReviewCount>({this.props.reviewVolume})</ReviewCount>}
          </CellContainer>
          {!this.props.reviewVolumeBlank && <CellContainer
            featured={this.props.featured}
            firstItem={this.props.firstItem}
            lastItem={this.props.lastItem}
        >
          {this.props.reviewVolume}
        </CellContainer>}
          {!this.props.operatingSystemsBlank && <CellContainer
              featured={this.props.featured}
              firstItem={this.props.firstItem}
              lastItem={this.props.lastItem}
              minHeight={`${this.props.cellHeights.operatingSystem}px`}
              ref={(operatingSystemCell) => {this.operatingSystemCell = operatingSystemCell}}
          >
            {this.props.operatingSystems}
          </CellContainer>}
          <CellContainer
              featured={this.props.featured}
              firstItem={this.props.firstItem}
              lastItem={this.props.lastItem}
          >
            {this.props.rankings ? this.props.rankings.overall_score + '/100' : ''}
          </CellContainer>
          <CellContainer
              featured={this.props.featured}
              firstItem={this.props.firstItem}
              lastItem={this.props.lastItem}
          >
            {this.props.rankings ? this.props.rankings.popularity + '/100' : ''}
          </CellContainer>
          <CellContainer
              featured={this.props.featured}
              firstItem={this.props.firstItem}
              lastItem={this.props.lastItem}
          >
            {this.props.rankings ? this.props.rankings.reviews + '/100' : ''}
          </CellContainer>
          <CellContainer
              featured={this.props.featured}
              firstItem={this.props.firstItem}
              lastItem={this.props.lastItem}
          >
            {this.props.rankings ? this.props.rankings.company + '/100' : ''}
          </CellContainer>
        </Section>

        <SectionRule show={!this.props.hidePricing} />
        <Section show={!this.props.hidePricing}>
          <PlanCell
            featured={this.props.featured}
            firstItem={this.props.firstItem}
            lastItem={this.props.lastItem}
            plans={this.props.plans}
            currentPlan={this.state.currentPlan}
            setPlan={this.setPlan}
            verified={this.props.verified}
          />
          <CellContainer
            featured={this.props.featured}
            firstItem={this.props.firstItem}
            lastItem={this.props.lastItem}
          >
            {this.props.trial && <TrialLength>{this.props.trial.days} days</TrialLength>}
            {this.props.trial === null && crossIcon}
          </CellContainer>
          {!this.props.pricingBlank && <CellContainer
              featured={this.props.featured}
              firstItem={this.props.firstItem}
              lastItem={this.props.lastItem}
          >
            {this.state.currentPlan && this.state.currentPlan.pricing_model}
          </CellContainer>}
          {!this.props.pricingBlank && <CellContainer
              featured={this.props.featured}
              firstItem={this.props.firstItem}
              lastItem={this.props.lastItem}
          >
            {this.state.currentPlan && this.state.currentPlan.price}
          </CellContainer>}
          {!this.props.pricingBlank && <CellContainer
              featured={this.props.featured}
              firstItem={this.props.firstItem}
              lastItem={this.props.lastItem}
          >
            {this.state.currentPlan && this.state.currentPlan.price_per}
          </CellContainer>}
          {!this.props.pricingBlank && this.props.shouldDisplayPricingSection('extra_fee') && <CellContainer
              featured={this.props.featured}
              firstItem={this.props.firstItem}
              lastItem={this.props.lastItem}
          >
           {this.state.currentPlan && this.state.currentPlan.extra_fee}
          </CellContainer>}
          {!this.props.pricingBlank && this.props.shouldDisplayPricingSection('extra_fee_per') && <CellContainer
              featured={this.props.featured}
              firstItem={this.props.firstItem}
              lastItem={this.props.lastItem}
          >
            {this.state.currentPlan && this.state.currentPlan.extra_fee_per}
          </CellContainer>}
          {!this.props.pricingBlank && this.props.shouldDisplayPricingSection('annual_discount') && <CellContainer
              featured={this.props.featured}
              firstItem={this.props.firstItem}
              lastItem={this.props.lastItem}
          >
            {this.state.currentPlan && this.state.currentPlan.annual_discount && <CheckIcon />}
            {this.state.currentPlan && !this.state.currentPlan.annual_discount  && crossIcon}
          </CellContainer>}
          {!this.props.pricingBlank && this.props.shouldDisplayPricingSection('from_plan') && <CellContainer
              featured={this.props.featured}
              firstItem={this.props.firstItem}
              lastItem={this.props.lastItem}
          >
            {this.state.currentPlan && this.state.currentPlan.from_plan && <CheckIcon />}
            {this.state.currentPlan && !this.state.currentPlan.from_plan  && crossIcon}
          </CellContainer>}
          {!this.props.pricingBlank && this.props.shouldDisplayPricingSection('minimum_cost') && <CellContainer
              featured={this.props.featured}
              firstItem={this.props.firstItem}
              lastItem={this.props.lastItem}
          >
            {this.state.currentPlan && this.state.currentPlan.minimum_cost}
          </CellContainer>}
          {!this.props.pricingBlank && this.props.shouldDisplayPricingSection('user_min') && <CellContainer
              featured={this.props.featured}
              firstItem={this.props.firstItem}
              lastItem={this.props.lastItem}
          >
            {this.state.currentPlan && this.state.currentPlan.user_min}
          </CellContainer>}
          {!this.props.pricingBlank && this.props.shouldDisplayPricingSection('user_max') && <CellContainer
              featured={this.props.featured}
              firstItem={this.props.firstItem}
              lastItem={this.props.lastItem}
          >
            {this.state.currentPlan && this.state.currentPlan.user_max}
          </CellContainer>}
        </Section>

        {this.props.showFeatures && <SectionRule show={!this.props.hideFeatures} />}
        {this.props.showFeatures && <Section show={!this.props.hideFeatures}>
          {this.props.features.map((feature, index) => (
            <CellContainer
              key={index}
              featured={this.props.featured}
              firstItem={this.props.firstItem}
              lastItem={this.props.lastItem}
              fixedHeight
            >
              {feature && <CheckIcon />}
              {!feature && crossIcon}
            </CellContainer>
          ))}
        </Section>}

        { this.props.showSupport && <SectionRule show={!this.props.hideSupport} />}
        { this.props.showSupport && <Section show={!this.props.hideSupport}>
          {this.props.support.map((support, index) => (
              <CellContainer
                  key={index}
                  featured={this.props.featured}
                  firstItem={this.props.firstItem}
                  lastItem={this.props.lastItem}
                  fixedHeight
              >
                {support && <CheckIcon />}
                {!support && crossIcon}
              </CellContainer>
          ))}
        </Section>}

        { this.props.showCompliance && <SectionRule show={!this.props.hideCompliance} />}
        { this.props.showCompliance && <Section show={!this.props.hideCompliance}>
          {this.props.compliance.map((compliance, index) => (
              <CellContainer
                  key={index}
                  featured={this.props.featured}
                  firstItem={this.props.firstItem}
                  lastItem={this.props.lastItem}
                  fixedHeight
              >
                {compliance && <CheckIcon />}
                {!compliance && crossIcon}
              </CellContainer>
          ))}
        </Section>}

        { this.props.showDeployment && <SectionRule show={!this.props.hideDeployment} />}
        { this.props.showDeployment && <Section show={!this.props.hideDeployment}>
          {this.props.deployment.map((deployment, index) => (
              <CellContainer
                  key={index}
                  featured={this.props.featured}
                  firstItem={this.props.firstItem}
                  lastItem={this.props.lastItem}
                  fixedHeight
              >
                {deployment && <CheckIcon />}
                {!deployment && crossIcon}
              </CellContainer>
          ))}
        </Section>}

        { this.props.showIndustry && <SectionRule show={!this.props.hideIndustry} />}
        { this.props.showIndustry && <Section show={!this.props.hideIndustry}>
          {this.props.industry.map((industry, index) => (
              <CellContainer
                  key={index}
                  featured={this.props.featured}
                  firstItem={this.props.firstItem}
                  lastItem={this.props.lastItem}
                  fixedHeight
              >
                {industry && <CheckIcon />}
                {!industry && crossIcon}
              </CellContainer>
          ))}
        </Section>}

        { this.props.showSpecialty && <SectionRule show={!this.props.hideSpecialty} />}
        { this.props.showSpecialty && <Section show={!this.props.hideSpecialty}>
          {this.props.specialty.map((specialty, index) => (
              <CellContainer
                  key={index}
                  featured={this.props.featured}
                  firstItem={this.props.firstItem}
                  lastItem={this.props.lastItem}
                  fixedHeight
              >
                {specialty && <CheckIcon />}
                {!specialty && crossIcon}
              </CellContainer>
          ))}
        </Section>}

      </Container>
    )
  }
}
