import React from 'react'
import styled from 'styled-components'

const BackButtonContainer = styled.button`
  align-items: center;
  border: 0;
  background: none;
  color: #3040e1;
  display: flex;
  font-family: "HK Grotesk";
  padding: 0;

  &:focus {
    outline: 0;
  }
`

const ChevronIcon = styled.svg`
  margin-right: 0.25rem;
  path {
    fill: #98a2ed;
  }
`

const BackButton = ({ children, ...props }) => (
  <BackButtonContainer {...props}>
    <ChevronIcon xmlns="http://www.w3.org/2000/svg" width="16" height="16">
      <path fill="#BBB" fill-rule="evenodd" d="M10.707 4.707a1 1 0 00-1.414-1.414l-4 4a1 1 0 000 1.414l4 4a1 1 0 101.414-1.414L7.414 8l3.293-3.293z"/>
    </ChevronIcon>
    {children}
  </BackButtonContainer>
)

export default BackButton