import React from 'react'
import styled from 'styled-components'

const TextRuleContainer = styled.div`
  position: relative;
  overflow: hidden;
  text-align: center;
  padding: 1rem 0;
  font-size: 0.875rem;
  color: #585858;
  font-weight: normal;
`

const TextRuleInnerContainer = styled.span`
  display: inline-block;
  vertical-align: baseline;
  zoom: 1;
  position: relative;
  padding: 0 10px;

  &:before, &:after {
    content: '';
    display: block;
    width: 500px;
    position: absolute;
    top: 0.73rem;
    border-top: 1px solid #ebebeb;
  }

  &:before {
    right: 100%;
  }

  &:after {
    left: 100%;
  }
`

export default ({ children }) => (
  <TextRuleContainer>
    <TextRuleInnerContainer>
      {children}
    </TextRuleInnerContainer>
  </TextRuleContainer>
)