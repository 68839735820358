import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import styled from 'styled-components'

import CompareButton from './CompareButton'
import Label from './Label'
import Link from './Link'

const ListingContainer = styled.div`
  padding: 2rem;
  border-bottom: 1px solid #ebebeb;

  &:last-child {
    border-bottom: 0;
  }
`

const LabelContainer = styled.div`
  margin-top: 0.25rem;
`

const Image = styled.img`
  border: 1px solid #ebebeb;
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
  object-fit: contain;
  padding: 0.2rem;
`

const Name = styled.a`
  color: #161616;
  font-size: 1rem;
  font-weight: 600;

  &:hover {
    color: #3040e1;
    text-decoration: none;
  }
`

const ContentContainer = styled.div`
  margin-left: 0.75rem;
`

const Description = styled.div`
  color: #585858;
  margin-top: 1rem;
`

const ButtonContainer = styled.div`
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
`

const Button = styled.button`
  background: ${props => props.selected ? '#3040e1' : '#fff'};
  color: ${props => props.selected ? '#fff' : '#161616'};
  border: 1px solid ${props => props.selected ? '#3040e1' : '#ebebeb'};
  padding: 0.5rem 1rem;
  border-radius: 12px;
  font-weight: 500;

  &:focus {
    outline: 0;
  }

  &:hover {
    background: #3040e1;
    color: #fff;
    border: 1px solid #3040e1;
  }
`

export default class Listing extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected: false,
    }

    this.selectCompare = this.selectCompare.bind(this)
  }
  selectCompare() {
    this.setState({ selected: !this.state.selected }, this.handleSelect)
  }
  handleSelect() {
    this.props.onClick(this.state.selected)
  }
  render() {
    const { selected } = this.state

    let description = '';
    if (this.props.description) {
      description = this.props.description;
    }

    return (
      <ListingContainer>
        <Row noGutters={true}>
          <Col xs={2} sm={2} md={2} lg={1}>
            <Image src={this.props.image} />
          </Col>
          <Col xs={10} sm={10} md={10} lg={11}>
            <ContentContainer>
              <div className="d-flex">
                <div>
                  <Name href={this.props.link}>{this.props.name}</Name>
                  <LabelContainer>
                    {this.props.labels.map((label, index) => <Label href={label.link} key={index}>{label.name}</Label>)}
                  </LabelContainer>
                </div>
                <ButtonContainer>
                  <CompareButton productSlug={this.props.slug} />
                </ButtonContainer>
              </div>

              <Description dangerouslySetInnerHTML={{__html: description}} />

              <Link href={this.props.link}>View Profile</Link>
            </ContentContainer>
          </Col>
        </Row>
      </ListingContainer>
    )
  }
}
