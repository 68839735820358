import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg/dist/index'
import styled from 'styled-components'
import { connect } from 'react-redux'

import PlusPath from '../../svg/new-plus.svg'
import CheckPath from '../../svg/new-check.svg'

import getShortlists from '../../actions/get-shortlists'
import createShortlist from '../../actions/create-shortlist'
import addProductToShortlist from '../../actions/add-product-to-shortlist'
import removeProductFromShortlist from '../../actions/remove-product-from-shortlist'

import Button from './Button'

const CompareButton = styled.button`
  background: ${props => props.selected ? '#E6E8FC' : '#fff'};
  color: ${props => props.selected ? '#3040E1' : '#161616'};
  border: 1px solid ${props => props.selected ? '#E6E8FC' : '#ebebeb'};
  padding: 0.5rem 1rem;
  border-radius: 12px;
  font-weight: 500;

  &:focus {
    outline: 0;
  }

  &:hover {
    background: #3040e1;
    color: #fff;
    border: 1px solid #3040e1;
  }
`

import CustomCheckbox from '../result/common/CustomCheckbox'

const UnstyledPlusIcon = (props) => (
  <ReactSVG src={PlusPath} wrapper="span" {...props} />
)

const UnstyledCheckIcon = (props) => (
  <ReactSVG src={CheckPath} wrapper="span" {...props} />
)

const CheckIcon = styled(UnstyledCheckIcon)`
  svg {
    1px solid #bbbbbb;

    rect {
      fill: #979ff0;
    }

    path {
      fill: #fff;
    }
  }
`

const PlusIcon = styled(UnstyledPlusIcon)`
  background: #979ff0;
  border-radius: 6px;
  height: 20px;
  width: 20px;
  margin-right: 0.5rem;

  span {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
  }

  svg {
    position: absolute;
    height: 0.6rem;
    width: 0.6rem;
    fill: #fff;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`

const Container = styled.div`
  position: relative;
`

const Picker = styled.div`
  border-radius: 12px;
  box-shadow: 0 2px 4px 0 rgba(22, 22, 22, 0.12), 0 -1px 2px 0 rgba(22, 22, 22, 0.04);
  background-color: #ffffff;
  position: absolute;
  top: 3.2rem;
  padding: 0.5rem;
  width: 105%;
  min-width: 230px;
  font-family: "HK Grotesk";
  z-index: 999;
`

const PickerList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  user-select: none;
`

const PickerLabel = styled.label`
  margin: 0;
`

const PickerItem = styled.li`
  padding: 0.5rem 0.75rem;
  border-radius: 8px;

  &:hover {
    color: #3040e1;
    background: #e6e8fc;
  }
`

const NewListButton = styled.button`
  align-items: center;
  display: flex;
  padding: 0.5rem 0.75rem;
  border: 0;
  color: #3040e1;
  margin-bottom: 0.5rem;

  &:focus {
    outline: 0;
  }
`

const NewListContainer = styled.div`
  display: flex;
  padding: 0.5rem 0.75rem;
`

const NewListInput = styled.input`
  border: 0;
  caret-color: #3040e1;
  color: #3040e1;

  &:focus {
    outline: 0;
  }
`

const CheckIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
`

const DoneButton = styled(Button)`
  padding: 0.5rem 1rem;

  &:disabled {
    border: 0;
    background: #f8f8f8;
    color: #585858;
  }
`

class ShortlistPicker extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      showNewInput: false,
      shortlistName: '',
    }

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.openPicker = this.openPicker.bind(this)
    this.selectShortlists = this.selectShortlists.bind(this)
    this.toggleNewInput = this.toggleNewInput.bind(this)
    this.submitShortlist = this.submitShortlist.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleNewChecklist = this.handleNewChecklist.bind(this)
    this.isChecked = this.isChecked.bind(this)
    this.productIsInShortlist = this.productIsInShortlist.bind(this)
    this.getProductId = this.getProductId.bind(this)

    this.newListInput = React.createRef()
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  setWrapperRef(node) {
    this.wrapperRef = node;
  }
  openPicker() {
    const open = !this.state.open

    this.setState({ open })
  }
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ open: false })
    }
  }
  handleChange(event) {
    this.setState({ shortlistName: event.target.value })
  }
  submitShortlist() {
    this.props.createShortlist(this.state.shortlistName)
        .then(shortlist => {
          this.selectShortlists(shortlist)

          this.setState({
            shortlistName: '',
            showNewInput: false,
          })
        })
  }
  selectShortlists(shortlist) {
    if (this.isChecked(shortlist)) {
      const productId = this.getProductId(shortlist)
      this.props.removeProductFromShortlist(productId, shortlist.id)
    } else {
      this.props.addProductToShortlist(this.props.productSlug, shortlist.id)
    }
  }
  isChecked(shortlist) {
    return undefined !== shortlist.products.find(product => product.product.slug === this.props.productSlug)
  }
  productIsInShortlist() {
    return undefined !== this.props.shortlists.find(shortlist => this.isChecked(shortlist))
  }
  getProductId(shortlist) {
    const product = shortlist.products.find(product => product.product.slug === this.props.productSlug)

    if (undefined === product) {
      return undefined
    }

    return product.id
}
  toggleNewInput() {
    this.setState({
      showNewInput: !this.state.showNewInput
    }, () => {
      if (this.state.showNewInput) {
        this.newListInput.current.focus()
      }
    })
  }
  handleNewChecklist(event) {
    if (event.key === 'Enter') {
      this.submitShortlist()
    }
  }
  render() {
    return (
      <Container ref={this.setWrapperRef}>
        {this.props.isCompareButton ?
            <CompareButton selected={this.productIsInShortlist()} onClick={() => this.openPicker()}>Compare</CompareButton> :
            <Button primary={true} disabled={this.props.disabled} onClick={() => this.openPicker()}>Add to Shortlist</Button>
        }

        {this.state.open && (
          <Picker>
            <PickerList>
              {this.props.shortlists.map((shortlist, index) => (
                <PickerItem key={index}>
                  <PickerLabel>
                    <CustomCheckbox
                      id={shortlist.id}
                      checked={this.isChecked(shortlist)}
                      onChange={() => this.selectShortlists(shortlist)}
                      label={shortlist.name}
                    >
                    </CustomCheckbox>
                  </PickerLabel>
                </PickerItem>
              ))}
            </PickerList>

            {this.state.showNewInput && (
              <NewListContainer>
                <CheckIconContainer>
                  <CheckIcon></CheckIcon>
                </CheckIconContainer>

                <NewListInput
                  ref={this.newListInput}
                  onChange={this.handleChange}
                  value={this.state.shortlistName}
                  onKeyDown={this.handleNewChecklist}
                  type="text"
                  placeholder="Name your list..."
                />
              </NewListContainer>
            )}

            <div>
              <NewListButton onClick={() => this.toggleNewInput()}>
                <PlusIcon /> <span>New List</span>
              </NewListButton>
              {this.state.showNewInput && (
                <DoneButton
                  onClick={() => this.submitShortlist()}
                  primary={true}
                  disabled={this.state.shortlistName === ''}
                >
                    Done
                  </DoneButton>
              )}
            </div>
          </Picker>
        )}
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  shortlists: state.shortlists.all,
})

const mapDispatchToProps = dispatch => ({
  createShortlist: name => dispatch(createShortlist(name)),
  getShortlists: () => dispatch(getShortlists()),
  addProductToShortlist: (productSlug, shortlistId) => dispatch(addProductToShortlist(productSlug, shortlistId)),
  removeProductFromShortlist: (productId, shortlistId) => dispatch(removeProductFromShortlist(productId, shortlistId)),
})

ShortlistPicker.propTypes = {
  disabled: PropTypes.bool,
}

ShortlistPicker.defaultProps = {
  disabled: false,
}

export default connect(mapStateToProps, mapDispatchToProps)(ShortlistPicker)
