import React from 'react'
import ReactSVG from 'react-svg'
import styled from 'styled-components'
import CrossPath from '../../svg/new-delete-cross.svg'

const ReactCrossIcon = (props) => (
  <ReactSVG src={CrossPath} wrapper="span" {...props} wrapper="span" />
)

const CrossIcon = styled(ReactCrossIcon)`
  svg {
    path {
      fill: #979ff0
    }
  }
`

const BlankButton = styled.button.attrs({
  className: 'delete-button'
})`
  border: 0;
  padding: 0;
  margin-left: auto;
  background: transparent;
  display: none;
  
  &:focus {
    outline: 0;
  }
`

const DeleteButton = (props) => (
  <BlankButton {...props}>
    <CrossIcon />
  </BlankButton>
)

export default DeleteButton