import React, { Component } from 'react'
import styled from 'styled-components'
import Picky from 'react-picky'

const PickyContainer = styled.div`
  margin-bottom: 0.5rem;

  .picky {
    &:focus {
      outline: 0;
    }
    
    .picky__placeholder {
      color: #3C454C;
    }
  }

  .picky__input--disabled {
    background: #f8f8f8;
  }

  .picky__input {
    border-radius: 12px;
    text-align: center;
    background: ${props => props.itemsSelected ? '#e6e8fc' : null};
    color: ${props => props.itemsSelected ? '#3040e1' : '#585858'};

    &:after {
      content: none;
    }

    &:focus {
      outline: 0;
    }
  }

  .picky__dropdown {
    border-radius: 12px;
    box-shadow: 0 4px 8px 0 rgba(22, 22, 22, 0.16), 0 -1px 2px 0 rgba(22, 22, 22, 0.04);
    z-index: 5
  }
`

const ListItemContainer = styled.div`
  padding: 0.5rem;
  margin: 0.5rem;
  border-radius: 8px;
  color: ${props => props.selected ? '#3040e1' : '#585858'};

  &:hover {
    background: #e6e8fc;

    label {
      color: #3040e1;
    }
  }
`

const Label = styled.label`
  width: 100%;
  display: block;
  text-align: left;
  color: #3C454C;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: color 200ms ease-in;
  overflow: hidden;

  &:before {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
    transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
    opacity: 0;
    z-index: -1;
  }

  &:after {
    width: 20px;
    height: 20px;
    content: '';
    border: 1px solid #ebebeb;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
    background-repeat: no-repeat;
    background-position: 1px 2px;
    border-radius: 4px;
    z-index: 2;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
  }
`

const Checkbox = styled.input.attrs({
  type: 'checkbox'
})`
  width: 32px;
  height: 32px;
  order: 1;
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  visibility: hidden;

  &:checked ~ label {
    color: #3040e1;

    &:before {
      transform: translate(-50%, -50%) scale3d(56, 56, 1);
      opacity: 1;
    }

    &:after {
      background-color: #979ff0;
      border-color: #979ff0;
    }
  }
`

const CheckContainer = styled.div`
  display: flex;
  position: relative;
`

const ListItem = ({
  index,
  style,
  item,
  isSelected,
  selectValue,
  labelKey,
  valueKey,
  multiple,
  disabled
}) => (
    <ListItemContainer style={{ ...style }} className={isSelected ? "selected" : ""} selected={isSelected} key={index}>
      <CheckContainer className="d-flex">
        <Checkbox
          className="ml-auto my-auto"
          checked={isSelected}
          disabled={disabled}
          onChange={() => selectValue(item)}
          id={item.id}
        />

        <Label className="w-100 m-0" htmlFor={item.id}>
          <span>{item[labelKey]}</span>
        </Label>
      </CheckContainer>
    </ListItemContainer>
  )

export default class Picker extends Component {
  constructor(props) {
    super(props)
    this.hasItemsSelected = this.hasItemsSelected.bind(this)
  }

  render() {
    return (
      <PickyContainer itemsSelected={this.hasItemsSelected()}>
        <Picky render={ListItem} {...this.props} />
      </PickyContainer>
    )
  }

  hasItemsSelected() {
    return this.props.value.length > 0
  }
}
