import React from 'react'
import ReactSVG from 'react-svg'
import styled from 'styled-components'

import SearchIconPath from '../../svg/new-search.svg'

const SearchIcon = (props) => (
  <ReactSVG src={SearchIconPath} wrapper="span" {...props} />
)

const SearchText = styled.span`
  vertical-align: middle;
  padding-left: 0.2rem;
`

const Button = styled.button`
  flex: auto;
  color: #ccc;
  height: 38px;
  border-radius: 7px;
  background-color: #fff;	
  box-shadow: inset 0 2px 2px 0 rgba(22,22,22,0.08);
  text-align: left;
  border: 1px solid #eee;
  font-size: 0.875rem;
  font-family: "HK Grotesk";

  &:focus {
    outline: 0;
  }
`

const SearchButton = (props) => (
  <Button {...props}>
    <SearchIcon /> <SearchText>Search</SearchText>
  </Button>
)

export default SearchButton