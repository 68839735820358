import { CURRENT_SHORTLIST_RECEIVED } from '../actions/get-current-shortlist'
import { PRODUCT_ADDED_TO_CURRENT_SHORTLIST } from '../actions/add-product-to-current-shortlist'
import { PRODUCT_REMOVED_FROM_CURRENT_SHORTLIST } from '../actions/remove-product-from-current-shortlist'
import { SHORTLISTS_RECEIVED } from "../actions/get-shortlists"
import { SHORTLIST_CREATED } from "../actions/create-shortlist"
import { SHORTLIST_RENAMED } from "../actions/rename-shortlist"
import { SHORTLIST_DELETED } from "../actions/delete-shortlist"
import { SHORTLISTS_MERGED } from "../actions/merge-shortlists"
import { CURRENT_SHORTLIST_SET } from '../actions/set-current-shortlist'
import { PRODUCT_ADDED_TO_SHORTLIST } from "../actions/add-product-to-shortlist"
import { PRODUCT_REMOVED_FROM_SHORTLIST } from "../actions/remove-product-from-shortlist"
import { USER_INVITED_TO_SHORTLIST } from "../actions/invite-user-to-shortlist"
import { INVITATION_REMOVED_FROM_SHORTLIST } from '../actions/remove-invitation-from-shortlist'
import { SHOWING_SHORTLIST_COMPONENT_UPDATED } from '../actions/set-showing-shortlist-component'

const initialState = {
  current: {
    products: [],
    guest: true,
    shortlistUpdatedInSession: false, // When a product is added to the shortlist this is set to true
  },
  all: [],
  showingShortlistComponent: false,
}

const shortlists = (state = initialState, action) => {
  let products = []
  let all = []
  let shortlist = {}
  let currentShortlist = {
    invitations: [],
  }
  switch (action.type) {
    case CURRENT_SHORTLIST_RECEIVED:
      state = Object.assign({}, state, {
        current: {
          shortlist: action.shortlist,
          products: action.products,
          guest: action.guest,
          shortlistUpdatedInSession: state.current.shortlistUpdatedInSession,
        },
        showingShortlistComponent: action.showing_shortlist_component,
      });
      break
    case "PRODUCT_ADDED_TO_CURRENT_SHORTLIST":
      products = state.current.products.concat([action.product])
      state = Object.assign({}, state, {
        current: Object.assign({}, state.current, {
          products,
          shortlistUpdatedInSession: true,
        })
      })

      shortlist = state.all.find(shortlist => shortlist.id === state.current.shortlist.id)
      if (shortlist) {
        if (!shortlist.products) {
          shortlist.products = []
        }
        products = shortlist.products.concat([action.product])
        shortlist = Object.assign({}, shortlist, {
          products,
        })
        all = state.all.map(currentShortlist => {
          if (currentShortlist.id === shortlist.id) {
            return shortlist
          }

          return currentShortlist
        })
      }

      state = Object.assign({}, state, {
        all,
      })
      break
    case PRODUCT_REMOVED_FROM_CURRENT_SHORTLIST:
      products = state.current.products.filter(product => product.id !== action.productId)
      state = Object.assign({}, state, {
        current: Object.assign({}, state.current, {
          products,
        })
      })

      shortlist = state.all.find(shortlist => shortlist.id === state.current.shortlist.id)
      if (shortlist) {
        products = shortlist.products.filter(product => product.id !== action.productId)
        shortlist = Object.assign({}, shortlist, {products})
        state = Object.assign({}, state, {
          all: state.all.map(currentShortlist => {
            if (currentShortlist.id === shortlist.id) {
              return shortlist
            }

            return currentShortlist
          })
        })
      }

      break
    case SHORTLISTS_RECEIVED:
      // Check if the current shortlist has been deleted and replace it with the first remaining shortlist if it has
      currentShortlist = state.current.shortlist
      if (typeof currentShortlist !== 'undefined') {
        currentShortlist = action.shortlists.find(shortlist => shortlist.id === state.current.shortlist.id)
        if (null === currentShortlist && action.shortlists.length > 0) {
          currentShortlist = action.shortlists[0]
        }
      }

      state = Object.assign({}, state, {
        all: action.shortlists,
        current: {
          shortlist: currentShortlist,
          products: currentShortlist !== undefined && currentShortlist !== null ? currentShortlist.products : [],
          guest: state.current.guest,
        },
      })
      break
    case CURRENT_SHORTLIST_SET:
      state = Object.assign({}, state, {
        current: {
          ...state.current,
          shortlist: action.shortlist,
          products: action.shortlist.products,
        },
      })
      break
    case SHORTLIST_CREATED:
      all = state.all.concat([action.shortlist])
      state = Object.assign({}, state, {
        all,
        current: {
          shortlist: action.shortlist,
          products: action.shortlist.products,
          guest: false,
        }
      })
      break
    case SHORTLIST_RENAMED:
      all = state.all.map(currentShortlist => currentShortlist.id === action.shortlist.id ? action.shortlist : currentShortlist)
      currentShortlist = state.current.shortlist.id === action.shortlist.id ? action.shortlist : state.current.shortlist;
      if (state.current)
      state = Object.assign({}, state, {
        all,
        current: {
          ...state.current,
          shortlist: currentShortlist,
        }
      })
      break
    case SHORTLIST_DELETED:
      all = state.all.filter(shortlist => shortlist.id !== action.shortlist.id)
      currentShortlist = state.current.shortlist
      if (currentShortlist.id === action.shortlist.id) {
        currentShortlist = all.length > 0 ? all[0] : null
      }

      state = Object.assign({}, state, {
        all,
        current: {
          ...state.current,
          shortlist: currentShortlist,
          products: currentShortlist !== undefined && currentShortlist !== null ? currentShortlist.products : [],
        }
      })
      break
    case SHORTLISTS_MERGED:
      all = state.all.map(shortlist => action.shortlist.id === shortlist.id ? action.shortlist : shortlist)
      currentShortlist = state.current.shortlist
      if (action.shortlist.id === currentShortlist.id) {
        currentShortlist = action.shortlist
      }
      state = Object.assign({}, state, {
        all,
        current: {
          ...state.current,
          shortlist: currentShortlist,
          products: currentShortlist !== undefined && currentShortlist !== null ? currentShortlist.products : [],
        }
      })
      break
    case PRODUCT_ADDED_TO_SHORTLIST:
      products = state.current.products.concat([action.product])

      products = []

      shortlist = state.all.find(shortlist => shortlist.id === action.shortlistId)
      if (!shortlist.products) {
        shortlist.products = []
      }
      products = shortlist.products.concat([action.product])
      shortlist = Object.assign({}, shortlist, {
        products,
      })
      all = state.all.map(currentShortlist => {
        if (currentShortlist.id === shortlist.id) {
          return shortlist
        }

        return currentShortlist
      })

      state = Object.assign({}, state, {
        all,
        current: Object.assign({}, state.current, {
          products,
          shortlist,
          shortlistUpdatedInSession: true,
        }),
      })
      break
    case PRODUCT_REMOVED_FROM_SHORTLIST:
      // Update the current shortlist if it is the one being altered
      if (state.current.shortlist && state.current.shortlist.id === action.shortlistId) {
        products = state.current.products.filter(product => product.id !== action.productId)

        state = Object.assign({}, state, {
          current: Object.assign({}, state.current, {
            products,
          })
        })
        products = []
      }

      shortlist = state.all.find(shortlist => shortlist.id === action.shortlistId)
      if (!shortlist.products) {
        shortlist.products = []
      }
      products = shortlist.products.filter(product => product.id !== action.productId)
      shortlist = Object.assign({}, shortlist, {products})
      all = state.all.map(currentShortlist => {
        if (currentShortlist.id === shortlist.id) {
          return shortlist
        }

        return currentShortlist
      })

      state = Object.assign({}, state, {
        all,
      })
      break
    case USER_INVITED_TO_SHORTLIST:
      // If a user has been invited to the current shortlist add it to the current shortlist data
      currentShortlist = {...state.current.shortlist}
      if (state.current.shortlist.id === action.shortlist.id) {
        currentShortlist.invitations.push(action.invitation)
      }

      // Add the new user invite to the shortlist
      all = state.all.map(currentShortlist => currentShortlist.id !== action.shortlist.id ? currentShortlist : {
        ...currentShortlist,
        invitations: currentShortlist.invitations.concat([action.invitation])
      })

      state = Object.assign({
        ...state,
        all,
        current: {
          ...state.current,
          shortlist: currentShortlist,
        }
      })
      break
    case INVITATION_REMOVED_FROM_SHORTLIST:
      // If an invite has been removed from the current shortlist remove it from the current shortlist data
      currentShortlist = {...state.current.shortlist}
      if (state.current.shortlist.id === action.shortlist.id) {
        currentShortlist.invitations = currentShortlist.invitations.filter(currentInvitation => currentInvitation.id !== action.invitationId)
      }

      // Remove the invite from the all shortlists data
      all = state.all.map(currentShortlist => currentShortlist.id !== action.shortlist.id ? currentShortlist : {
        ...currentShortlist,
        invitations: currentShortlist.invitations.filter(currentInvitation => currentInvitation.id !== action.invitationId)
      })

      state = Object.assign({
        ...state,
        all,
        current: {
          ...state.current,
          shortlist: currentShortlist,
        }
      })
      break
    case SHOWING_SHORTLIST_COMPONENT_UPDATED:
      state = {
        ...state,
        showingShortlistComponent: action.showing_shortlist_component,
      }
  }

  return state
}

export default shortlists
