import Axios from "axios"
import getShortlists from './get-shortlists'
import store from '../store'

export const USER_INVITED_TO_SHORTLIST = 'USER_INVITED_TO_SHORTLIST'

const inviteUserToShortlist = (shortlist, email) => (
    dispatch => {
      return Axios.post(`/api/shortlists/${shortlist.id}/invitations`, {
        email: email,
        role: 'READ_WRITE',
      }).then((response) => {
        dispatch({
          type: USER_INVITED_TO_SHORTLIST,
          shortlist,
          email,
          invitation: response.data.data,
        })

        return true
      })
      .catch(error => {
        // If the shortlist could not be found reload shortlist data to get the latest
        if (error.response && error.response.status === 404) {
          store.dispatch(getShortlists())
        } else {
          throw error
        }
      })
    }
)

export default inviteUserToShortlist

