import React, { Component } from 'react'
import styled from 'styled-components'
import OverallRanking from './OverallRanking'
import SelectButton from './SelectButton'
import { mediaBreakpointDownMd } from "styled-bootstrap-responsive-breakpoints"
import CompareButton from '../common/CompareButton'

const RankingCardContainer = styled.div`
  align-items: center;
  display: flex;
  background-color: #FFFFFF;
  padding: 1.5rem 1rem;
  position: relative;
  border-top: 1px solid #EBEBEB;

  &:first-child {
    border-top: 0;
  }
`

const Label = styled.span`
  position: absolute;
  font-size: 12px;
  color: ${props => labelColours[props.labelStyle]};
  background: ${props => labelStyles[props.labelStyle]};
  padding: .3rem .5rem;
  border-radius: 11px;
  top: -10px;
  left: 2rem;
  line-height: 1;
`

const labelStyles = {
  primary: '#FFBF4A',
  secondary: '#EBEBEB',
  tertiary: '#087F5B'
}

const labelColours = {
  primary: '#161616',
  secondary: '#161616',
  tertiary: '#FFFFFF'
}

const Number = styled.span`
  flex: 1;
  font-weight: 500;
  flex-grow: 0;
  padding: 1rem;
  text-align: center;
`

const IconContainer = styled.span`
  flex: 1;
  display: flex;
`

const Icon = styled.img`
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 4px 8px 0 rgba(33,37,41,0.12);
  border: 1px solid #EBEBEB;
  border-radius: 100%;
  width: 56px;
  height: 56px;
  object-fit: contain;
  padding: .2rem;
  
    &:hover {
    cursor: pointer;
  }
`

const Title = styled.span`
  flex: 4;
  padding-left: 1rem;
  font-weight: 600;
  
  &:hover {
    cursor: pointer;
  }
`

const DetailsContainer = styled.div`
  flex: 4;
  display: flex;
  align-items: center;

  ${mediaBreakpointDownMd`
    flex: 2;
  `}
`

const GroupedDataContainer = styled.div`
  display: flex;
  flex: 5;

  ${mediaBreakpointDownMd`
    flex: 1;
  `}
`

const GroupedData = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;

  ${mediaBreakpointDownMd`
    display: none;
  `}
`

const OverallRankingContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
`

const CompareContainer = styled.div`
  flex: 1;
  text-align: center;
`

export default class RankingCard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected: false,
    }

    this.selectRanking = this.selectRanking.bind(this)
  }

  selectRanking() {
    this.setState({ selected: !this.state.selected }, this.handleSelect)
  }

  handleSelect() {
    this.props.onClick(this.state.selected)
  }

  render() {
    const { selected } = this.state

    return (
      <RankingCardContainer>
        {this.props.label && <Label labelStyle={this.props.labelStyle}>{this.props.label}</Label>}
        <DetailsContainer className="details">
          <Number>{this.props.number}</Number>
          <IconContainer onClick={() => { window.location=`/software/${this.props.slug}` }}>
            <Icon src={this.props.image} />
          </IconContainer>
          <Title onClick={() => {window.location=`/software/${this.props.slug}` }}>{this.props.name}</Title>
        </DetailsContainer>
        <GroupedDataContainer className="grouped-data">
          <GroupedData>
            {this.props.popularity}
          </GroupedData>
          <GroupedData>
            {this.props.reviews}
          </GroupedData>
          <GroupedData>
            {this.props.company}
          </GroupedData>
          <OverallRankingContainer>
            <OverallRanking ranking={this.props.overall} />
          </OverallRankingContainer>
        </GroupedDataContainer>
        <CompareContainer>
         <CompareButton productSlug={this.props.slug} />
        </CompareContainer>
      </RankingCardContainer>
    )
  }
}
