import React from  'react'
import styled from 'styled-components'

import ReactSVG from 'react-svg'
import ChevronUpPath from '../../svg/new-chevron-up.svg'
import ChevronDownPath from '../../svg/new-chevron-down.svg'

const ChevronUp = (props) => (
  <ReactSVG src={ChevronUpPath} {...props} wrapper="span" />
)

const ChevronDown = (props) => (
  <ReactSVG src={ChevronDownPath} {...props} wrapper="span" />
)

const ChevronIconContainer = styled.span`
  svg path {
    fill: ${props => props.active ? '#98A0F0' : '#BBBBBB'} 
  }
`

const ChevronIcon = ({ active }) => (
  <ChevronIconContainer active={+active}>
    {active && <ChevronUp />}
    {!active && <ChevronDown />}
  </ChevronIconContainer>
)

export default ChevronIcon