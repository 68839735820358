import React from 'react'
import styled from 'styled-components'
import { NavLink as DefaultNavLink } from 'reactstrap'
import { mediaBreakpointDownMd } from "styled-bootstrap-responsive-breakpoints"

const NavLink = styled(DefaultNavLink)`
  color: ${props => props.highlight ? '#3040E1' : '#161616'} !important;
  display: flex;
  align-items: center;
  font-family: "HK Grotesk";

  ${mediaBreakpointDownMd`
    padding-left: 0;
  `}
`

export default NavLink