import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
   label::before,
   label::after {
    position: absolute;
    content: "";
    
    /*Needed for the line-height to take effect*/
    display: inline-block;
  }

  /*Outer box of the fake checkbox*/
   label::before{
    height: 1rem;
    width: 1rem;
    
    border-radius: 5px;
    border: 1px solid #bbbbbb;
    left: 0px;
    background: #fff;

    top: 3px;
  }

  /* Checkmark of the fake checkbox*/
  label::after {
    height: 4px;
    width: 8px;
    border-left: 2px solid;
    border-bottom: 2px solid;
    color: #fff;

    transform: rotate(-45deg);

    left: 4px;
    top: 8px;
  }

  /*Hide the checkmark by default*/
   input[type="checkbox"] + label::after {
    content: none;
  }

  input[type="checkbox"]:checked + label::before {
    background: #979ff0;
  }

  /*Unhide on the checked state*/
   input[type="checkbox"]:checked + label::after {
    content: "";
  }
`

const Label = styled.label`
  position: relative;
  display: inline-block;
  user-select: none;
  margin: 0;

  /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
  padding-left: 22px;
`

const Checkbox = styled.input`
  opacity: 0;
  position: absolute;
`

const CustomCheckbox = (props) => (
  <Container>
    <Checkbox type="checkbox" {...props} />
    <Label htmlFor={props.id}>{props.label}</Label>
  </Container>
)

export default CustomCheckbox