import React from 'react'
import ReactSVG from 'react-svg'
import styled from 'styled-components'
import SearchPath from '../../svg/new-search.svg'

const UnstyledSearchIcon = (props) => (
  <ReactSVG src={SearchPath} wrapper="span" {...props} />
)

const SearchIcon = styled(UnstyledSearchIcon)`
  position: relative;

  svg {
    position: absolute;
    top: -0.2rem;
    bottom: 0;
    height: 1.5rem;
    width: 1.5rem;
    left: 1rem;
  }
`

const Container = styled.div`
  position: relative;
`

const Input = styled.input`
  box-shadow: inset 0 2px 2px 0 rgba(22, 22, 22, 0.08);
  border-radius: 11px;
  border: 1px solid #eee;
  color: #161616;
  font-family: "HK Grotesk";
  height: 3.75rem;
  outline: 0;
  width: 100%;
  padding-left: 3.25rem;

  &::placeholder {
    color: #cccccc;
  }
`

const Button = styled.button`
  background: #3040e1;
  border: 0;
  border-radius: 12px;
  color: #fff;
  font-family: "HK Grotesk";
  height: 48px;
  padding: 0.75rem 1.25rem;
  position: absolute;
  right: 0.5rem;
  top: 0.45rem;

  &:focus {
    outline: 0;
  }
`

const SearchInput = (props) => (
  <Container>
    <SearchIcon />
    <Input {...props} />
    <Button>Search</Button>
  </Container>
)

export default SearchInput