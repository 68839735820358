import Axios from "axios";
import getShortlists from './get-shortlists'
import store from '../store'
import {SHORTLIST_DELETED} from './delete-shortlist'

export const SHORTLISTS_MERGED = 'SHORTLISTS_MERGED'

const mergeShortlists = (currentShortlist, selectedShortlist) => (
    dispatch => {
      return Axios.post(`/api/shortlists/${currentShortlist.id}/merge/${selectedShortlist.id}`)
          .then(response => {
              // Send the new current shortlist data to the redux store
              dispatch({
                type: SHORTLISTS_MERGED,
                shortlist: response.data,
              })

              // Remove the deleted shortlist from the redux store
              dispatch({
                type: SHORTLIST_DELETED,
                shortlist: selectedShortlist,
              })

            return shortlist
          })
          .catch(error => {
            if (error.response && error.response.status === 404) {
              store.dispatch(getShortlists())
            }
          })
    }
)

export default mergeShortlists
