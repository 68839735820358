import {isAuthenticated, isGuest} from "../helpers";
import Axios from "axios";
import getUser from "./get-user";

export const CURRENT_SHORTLIST_RECEIVED = 'CURRENT_SHORTLIST_RECEIVED'

const getCurrentShortlist = () => (
    dispatch => {
      if (isAuthenticated()) {
        return Axios.get(`/api/shortlists/current`).then(response => {
          if ('data' in response.data && 'products' in response.data.data) {
            const shortlist = response.data.data
            const products = shortlist.products

            dispatch({
              type: CURRENT_SHORTLIST_RECEIVED,
              shortlist,
              showing_shortlist_component: response.data.showing_shortlist_component,
              products,
              guest: false,
            })
          }
        })
      }

      if (isGuest()) {
        return Axios.get(`/api/shortlists/pending-products`, { params: {
            session_id: window.session_id
          }}).then(response => {
          const products = response.data.data

          dispatch({
            type: CURRENT_SHORTLIST_RECEIVED,
            shortlist: {},
            showing_shortlist_component: response.data.showing_shortlist_component,
            products,
            guest: true,
          })
        })
      }
    }
)

export default getCurrentShortlist
