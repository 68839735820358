import React, { Component } from 'react'
import ReactSVG from 'react-svg'
import styled from 'styled-components'
import { Col, Row, Container } from 'reactstrap'

import Screenshots from '../components/result/Screenshots'
import Pricing from '../components/result/Pricing'
import Reviews from '../components/result/Reviews'
import Company from '../components/result/Company'
import Features from '../components/result/Features'
import VendorInformation from '../components/result/VendorInformation'
import Similar from '../components/result/Similar'
import VendorText from '../components/result/VendorText';
import ShortlistPicker from '../components/common/ShortlistPicker'
import GuestShortlistButton from '../components/result/GuestShortlistButton'

import Label from '../components/common/Label';
import LinkButton from '../components/common/LinkButton';

import { isAuthenticated, isGuest } from '../helpers'

import AccoladePath from '../svg/new-accolade.svg'
import UpPath from '../svg/new-up.svg'
import DownPath from '../svg/new-down.svg'

const Accolade = (props) => (
  <ReactSVG src={AccoladePath} {...props} />
)

const Up = (props) => (
  <ReactSVG src={UpPath} {...props} />
)

const Down = (props) => (
  <ReactSVG src={DownPath} {...props} />
)

const Button = styled.a`
  background: ${props => (props.primary ? '#3040E1' : '#fff')};
  border: 1px solid ${props => (props.primary ? '#3040E1' : '#f2f2f2')};
  color: ${props => (props.primary ? '#fff' : '#585858')};
  text-align: center;
  font-family: "HK Grotesk";
  width: 100%;
  padding: .75rem 1rem;
  display: block;
  border-radius: 12px;
  font-weight: 600;
  margin-bottom: 0.5rem;

  &:hover {
    color: #fff;
    color: ${props => (props.primary ? '#fff' : '#585858')};
    text-decoration: none;
  }
`

const Card = styled.div`
  padding: 1rem;
  border-radius: 12px;
  background-color: #FFFFFF;
  box-shadow: 0 -1px 2px 0 rgba(22,22,22,0.04), 0 2px 4px 0 rgba(22,22,22,0.12);
`

const Image = styled.img`
  width: 5rem;
  height: 5rem;
  border: 1px solid #EBEBEB;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(33,37,41,0.12);}
  padding: 0.2rem;
  object-fit: contain;
`

const HeadingContainer = styled.div`
  display: flex;
  padding: 0 1rem 0 1.5rem;
  flex-direction: column;
  align-items: flex-start;
`

const IconContainer = styled.div`
  padding: 0 .3rem;
`

const LinkContainer = styled.div`
  margin-top: 1rem;
`

const StatContainer = styled.div`
  display: flex;
  font-size: 0.875rem;
  padding-top: 0.5rem;
`

export default class Result extends Component {
  render() {
    const labels = this.props.data.categories.map((category, index) => category.short_name ? <Label href={`/${category.slug}`} key={index}>{category.short_name}</Label> : '');

    // Use intro from text generation with description as default
    let intro = <div className="py-4 m-0" dangerouslySetInnerHTML={{__html:this.props.data.description}} />;
    if (this.props.data.text.intro.length > 0) {
      intro = this.props.data.text.intro.map((text, index) => <p key={index} className="py-4 m-0">{text}</p>);
    }

    const features = this.props.data.text.features.map((text, index) => <p key={index} className="py-4 m-0">{text}</p>);
    const pricingText = this.props.data.text.pricing.map((text, index) => <p key={index} className="py-4 m-0">{text}</p>);
    const pricing = <Pricing text={pricingText} customerSize={this.props.data.customerSize} freeTrialDays={this.props.data.freeTrialDays} pricing={this.props.data.pricingPlans} product={this.props.data.product} />;

    let featureContainer = '';
    if (this.props.data.features.length > 0) {
      featureContainer =  <Features text={features} categories={this.props.data.categories} features={this.props.data.features} />;
    }

    let reviews = '';
    if (this.props.data.reviewMeta && this.props.data.reviewMeta.total > 0) {
      reviews =  <Reviews reviews={this.props.data.reviewData} meta={this.props.data.reviewMeta} />;
    }

    let similarContainer = '';
    if (this.props.data.alternatives.length > 0) {
      similarContainer = <Similar alternatives={this.props.data.alternatives} />;
    }

    let screenshots = '';
    if (this.props.data.screenshots.length > 0) {
      screenshots = <Screenshots screenshots={this.props.data.screenshots} />;
    }

    const rankingBest = [];
    const rankingRunnerUp = [];
    const rankingGreatAlternative = [];
    const rankingTopTen = [];
    const rankingTopTwenty = [];
    window.ranks = (this.props.data.rankingLabels);
    if (this.props.data.categories.length > 0) {
        Object.keys(this.props.data.rankingLabels).forEach((category) => {
            switch (this.props.data.rankingLabels[category]) {
                case 'best':
                    rankingBest.push(
                        <StatContainer>
                            <IconContainer>
                                <Accolade />
                            </IconContainer>
                            <div>
                                Rated #1 for {category.replace('_', ' ')} in {this.props.data.categories[0].name}
                            </div>
                        </StatContainer>
                    );
                    break;
                case 'runner_up':
                    rankingRunnerUp.push(
                        <StatContainer>
                            <IconContainer>
                                <Accolade/>
                            </IconContainer>
                            <div>
                                Rated #2 for {category.replace('_', ' ')} in {this.props.data.categories[0].name}
                            </div>
                        </StatContainer>
                    );
                    break;
                case 'great_alternative':
                    rankingGreatAlternative.push(
                        <StatContainer>
                            <IconContainer>
                                <Accolade/>
                            </IconContainer>
                            <div>
                                Rated #3 for {category.replace('_', ' ')} in {this.props.data.categories[0].name}
                            </div>
                        </StatContainer>
                    );
                    break;
                case 'top_ten':
                    rankingTopTen.push(
                        <StatContainer>
                            <IconContainer>
                                <Accolade/>
                            </IconContainer>
                            <div>
                                Top 10 for {category.replace('_', ' ')} in {this.props.data.categories[0].name}
                            </div>
                        </StatContainer>
                    );
                    break;
                case 'top_twenty':
                    rankingTopTwenty.push(
                        <StatContainer>
                            <IconContainer>
                                <Accolade/>
                            </IconContainer>
                            <div>
                                Top 20 for {category.replace('_', ' ')} in {this.props.data.categories[0].name}
                            </div>
                        </StatContainer>
                    );
                    break;
                default:
                    return null;
            }
        });
    }


    return (
      <Container>
        <Row>
          <Col sm={12} md={9}>
            <div className="d-flex py-3">
              <div><Image src={ this.props.data.product.image ? this.props.data.product.image : "https://via.placeholder.com/82x82"} /></div>
              <HeadingContainer>
                <h1>{this.props.data.product.name}</h1>
                <div>
                  {labels}
                </div>
              </HeadingContainer>
            </div>

            <LinkContainer>
              <LinkButton href="#overview" selected={true}>
                Overview
              </LinkButton>
              <LinkButton href="#pricing">
                Pricing
              </LinkButton>
              {similarContainer !== '' && <LinkButton href="#alternatives">
                Alternatives
              </LinkButton>}
            </LinkContainer>

            <div id="overview">
              {intro}
            </div>
            {featureContainer}

            {screenshots}

            {pricing}

            {reviews}

            {/*<Company />*/}

            <VendorInformation vendor={this.props.data.vendor} clearbit={this.props.data.clearbit} />

            {similarContainer}
          </Col>
          <Col sm={12} md={3}>
            <Card>
              <div>
                {isGuest() && <GuestShortlistButton productSlug={this.props.data.product.slug} />}
                {isAuthenticated() && <ShortlistPicker productSlug={this.props.data.product.slug} />}
              </div>
              <div>
                <Button href={this.props.data.vendor.website}>Go to Website</Button>
              </div>

              {rankingBest}
              {rankingRunnerUp}
              {rankingGreatAlternative}
              {rankingTopTen}
              {rankingTopTwenty}
              {/*<StatContainer>*/}
              {/*  <IconContainer>*/}
              {/*    <Up />*/}
              {/*  </IconContainer>*/}
              {/*  <div>*/}
              {/*    More popular in HRS since last year*/}
              {/*  </div>*/}
              {/*</StatContainer>*/}
              {/*<StatContainer>*/}
              {/*  <IconContainer>*/}
              {/*    <Down />*/}
              {/*  </IconContainer>*/}
              {/*  <div>*/}
              {/*    Less popular in Business Software since last year*/}
              {/*  </div>*/}
              {/*</StatContainer>*/}
            </Card>
            <VendorText verified={this.props.data.product.verified} />
          </Col>
        </Row>
      </Container>
    )
  }
}
