import React, { Component } from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'
import { mediaBreakpointDownMd } from 'styled-bootstrap-responsive-breakpoints'
import axios from 'axios';

import 'react-picky/dist/picky.css';

import Button from '../components/common/Button'
import Picker from '../components/common/Picker'
import Toggle from '../components/common/Toggle'

import RankingCard from '../components/rankings/RankingCard'
import RankingCardHeading from '../components/rankings/RankingCardHeading'

import { manySelectedPlaceholder } from '../helpers'
import LinkButton from "../components/common/LinkButton";

const LinkContainer = styled.div`
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
`

const RankingContainer = styled.div`
  border-radius: 12px;
  box-shadow: 0 -1px 2px 0 rgba(22,22,22,0.04), 0 2px 4px 0 rgba(22,22,22,0.12);
  margin-bottom: 2rem;
`

const Heading = styled.h1`
  color: #161616;
  font-size: 1.5rem;
  font-weight: bold;
`

const Description = styled.p`
  color: #585858;
  font-family: 'HK Grotesk';
  margin-top: 0.75rem;
`

const GroupedHeadingsContainer = styled.div`
  display: flex;
  flex: 5;
  font-size: 12px;
`

const GroupedHeading = styled.div`
  flex: 1;
  text-align: center;
  
  ${mediaBreakpointDownMd`
    display: ${props => props.hideOnMobile ? 'none' : null};
  `}
`

const transformRankingData = (data) => {
    return data.map((ranking, index) => ({
      name: ranking.product.name,
      image: ranking.product.image,
      number: index + 1,
      popularity: ranking.popularity,
      reviews: ranking.reviews,
      trust: ranking.trust,
      company: ranking.company,
      overall: ranking.overall_score,
      label: ranking.label,
      labelStyle: ranking.labelStyle,
      slug: ranking.product.slug,
    }));
};

export default class Rankings extends Component {
  constructor(props) {
    super(props);

    const filters = {
      rankings: this.props.filters.rankings !== null ? this.props.filters.rankings : [],
      platforms: this.props.filters.platforms !== null ? this.props.filters.platforms : [],
      categories: this.props.filters.categories !== null ? this.props.filters.categories : [],
      industries: this.props.filters.industries !== null ? this.props.filters.industries : [],
    };

    this.state = {
      products: transformRankingData(this.props.data),
      rankings: filters.rankings.map(ranking => ({
        name: ranking.name,
        id: ranking.id,
      })),
      platforms: filters.platforms.map(dataValue => ({
        name: dataValue.name,
        id: dataValue.id,
      })),
      categories: filters.categories.map(category => ({
        name: category.name,
        id: category.id,
      })),
      industries: filters.industries.map(dataValue => ({
        name: dataValue.name,
        id: dataValue.id,
      })),
      selectedRankings: [],
      selectedPlatforms: [],
      selectedCategories: [],
      selectedIndustries: [],
    };

    this.onToggleChange = this.onToggleChange.bind(this);
    this.selectRankings = this.selectRankings.bind(this);
    this.selectPlatforms = this.selectPlatforms.bind(this);
    this.selectCategories = this.selectCategories.bind(this);
    this.selectIndustries = this.selectIndustries.bind(this);
    this.updateProducts = this.updateProducts.bind(this);
  }

  onToggleChange() {
    console.log('toggled')
  }

  selectRankings(value) {
    this.setState({ selectedRankings: value });

    if (value.length > 0) {
      this.updateProducts({
        order_by: value[0].id,
      });
    } else {
      this.updateProducts({
        order_by: null,
      })
    }
  }

  selectCategories(value) {
    this.setState({ selectedCategories: value });

    this.updateProducts({
      categories: value.map(selected => selected.id),
    });
  }

  selectPlatforms(value) {
    this.setState({ selectedPlatforms: value });

    this.updateProducts({
      platforms: value.map(selected => selected.id),
    });
  }

  selectIndustries(value) {
    this.setState({ selectedIndustries: value });

    this.updateProducts({
      industries: value.map(selected => selected.id),
    });
  }

  updateSelected() {
    console.log('ranking clicked');
  }

  updateProducts(params) {
    params = Object.assign({
      order_by: this.state.selectedRankings.length > 0 ? this.state.selectedRankings[0].id : null,
      industries: this.state.selectedIndustries.map(selected => selected.id),
      platforms: this.state.selectedPlatforms.map(selected => selected.id),
      categories: this.state.selectedCategories.map(selected => selected.id),
    }, params);

    let url = '/rankings';
    if (this.props.category) {
      url = `/${category.slug}/top`;
    }
    axios.get(url, {
      params,
    }).then(response => {
      this.setState({
        products: transformRankingData(response.data.rankings)
      });
    })
  }

  render() {
    const {
      products,
      categories,
      selectedCategories,
      rankings,
      selectedRankings,
      platforms,
      selectedPlatforms,
      industries,
      selectedIndustries,
    } = this.state

    let categoryPicker = '';

    if (this.props.category == null) {
      categoryPicker = <Picker
          numberDisplayed={1}
          options={categories}
          valueKey="id"
          labelKey="name"
          multiple={true}
          onChange={this.selectCategories}
          value={selectedCategories}
          placeholder="All Categories"
          manySelectedPlaceholder={manySelectedPlaceholder(selectedCategories)}
          allSelectedPlaceholder={manySelectedPlaceholder(selectedCategories)}
      />;
    }

    let heading  = <Heading>Rankings</Heading>;
    if (this.props.category) {
      heading = (<div>
        <Heading>
          {(this.props.category.short_name ? this.props.category.short_name : this.props.category.slug) + ' Software'}
        </Heading>

        {(this.props.category.ranking_description) &&
        <Description dangerouslySetInnerHTML={{ __html: this.props.category.ranking_description }} />
        }

        {(this.props.category.description && !this.props.category.ranking_description) &&
        <Description dangerouslySetInnerHTML={{ __html: this.props.category.description }} />
        }

        <LinkContainer>
          <LinkButton href={`/${this.props.category.slug}`}>
            All Results
          </LinkButton>
          <LinkButton href="#" selected={true}>
            Top 10
          </LinkButton>
          {this.props.categoryPages.guidePageVisible && <LinkButton href={`/guides?category_id=${this.props.category.id}`}>
            Guides
          </LinkButton>}
        </LinkContainer>
      </div>)
    }

    return (
      <div>
        <Container>
          {heading}
          <Row>
            <Col md={9}>
              <RankingCardHeading>
                <div style={{ flex: 4 }}></div>
                <GroupedHeadingsContainer>
                  <GroupedHeading hideOnMobile={true}>
                    Popularity
                  </GroupedHeading>
                  <GroupedHeading hideOnMobile={true}>
                    Reviews
                  </GroupedHeading>
                  <GroupedHeading hideOnMobile={true}>
                    Company
                  </GroupedHeading>
                  <GroupedHeading hideOnMobile={false}>
                    Overall
                  </GroupedHeading>
                </GroupedHeadingsContainer>
                <div style={{ flex: 1, textAlign: 'center' }}></div>
              </RankingCardHeading>
              <RankingContainer>
                {products.map((product, index) => (
                  <RankingCard
                    key={index}
                    name={product.name}
                    image={product.image}
                    number={product.number}
                    label={product.label}
                    labelStyle={product.labelStyle}
                    popularity={product.popularity}
                    reviews={product.reviews}
                    trust={product.trust}
                    company={product.company}
                    overall={product.overall}
                    slug={product.slug}
                    onClick={this.updateSelected}
                  />
                ))}
              </RankingContainer>
            </Col>
            <Col style={{ marginTop: '36px' }}>
              <Picker
                  numberDisplayed={1}
                  options={rankings}
                  valueKey="id"
                  labelKey="name"
                  multiple={true}
                  onChange={this.selectRankings}
                  value={selectedRankings}
                  placeholder="Sort by Ranking"
                  manySelectedPlaceholder={manySelectedPlaceholder(selectedRankings)}
                  allSelectedPlaceholder={manySelectedPlaceholder(selectedRankings)}
              />

              <Picker
                  numberDisplayed={1}
                  options={platforms}
                  valueKey="id"
                  labelKey="name"
                  multiple={true}
                  onChange={this.selectPlatforms}
                  value={selectedPlatforms}
                  placeholder="All Platforms"
                  manySelectedPlaceholder={manySelectedPlaceholder(selectedPlatforms)}
                  allSelectedPlaceholder={manySelectedPlaceholder(selectedPlatforms)}
              />

              {categoryPicker}

              <Picker
                  numberDisplayed={1}
                  options={industries}
                  valueKey="id"
                  labelKey="name"
                  multiple={true}
                  onChange={this.selectIndustries}
                  value={selectedIndustries}
                  placeholder="All Industries"
                  manySelectedPlaceholder={manySelectedPlaceholder(selectedIndustries)}
                  allSelectedPlaceholder={manySelectedPlaceholder(selectedIndustries)}
              />

              <hr />

              {/*<Toggle*/}
              {/*  label="View Shortlist only"*/}
              {/*  onChange={this.onToggleChange}*/}
              {/*/>*/}
            </Col>
          </Row>

        </Container>
      </div>
    )
  }
}
