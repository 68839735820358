import {Shortlist, ShortlistMenuButton as MenuButton, ShortlistCloseButton, ShortlistMinimiseButton, Notification, NotificationActionButton as ActionButton} from 'software-path-components'
import React from 'react'
import { connect } from 'react-redux'
import first from 'lodash/first'
import styled from 'styled-components';
import getCurrentShortlist from "../../actions/get-current-shortlist";
import removeProductFromCurrentShortlist from "../../actions/remove-product-from-current-shortlist";
import RenameShortlistModal from './rename-shortlist-modal'
import deleteShortlist from '../../actions/delete-shortlist'
import setCurrentShortlist from '../../actions/set-current-shortlist'
import setShowingShortlistComponent from '../../actions/set-showing-shortlist-component'
import mergeShortlists from '../../actions/merge-shortlists'
import CollaboratorsModal from "./collaborators-modal";

const ShortlistComponentContainer = styled.div`
  a:hover {
    color: black;
    text-decoration: none;
  }
`

const NotificationContainer = styled.div`
  position: fixed;
  bottom: 1.5rem;
  width: 100%;
  right: 1.5rem;
  max-width: 22.5rem;
`

class ShortlistComponent extends React.Component
{
  constructor(props) {
    super(props)

    this.state = {
      minimised: false,
      notificationMode: false,
      renameModalOpen: false,
      collaboratorsModalOpen: false,
    }

    this.handleShortlistChange = this.handleShortlistChange.bind(this)
    this.handleDeleteShortlist = this.handleDeleteShortlist.bind(this)
    this.handleMergeShortlists = this.handleMergeShortlists.bind(this)
    this.handleCloseShortlistComponent = this.handleCloseShortlistComponent.bind(this)
    this.renderGuestShortlistComponent = this.renderGuestShortlistComponent.bind(this)
    this.renderNotification = this.renderNotification.bind(this)
    this.removeShortlistItem = this.removeShortlistItem.bind(this)
  }
  handleShortlistChange(shortlistId) {
    const shortlist = this.props.shortlists.find(shortlist => shortlist.id === shortlistId)

    this.props.setCurrentShortlist(shortlist)
  }
  handleDeleteShortlist() {
    this.props.deleteShortlist(this.props.currentShortlist)
  }
  handleMergeShortlists(selectedShortlist) {
    this.props.mergeShortlists(this.props.currentShortlist, selectedShortlist)
  }
  handleCloseShortlistComponent() {
    this.props.setShowingShortlistComponent(false)
    this.setState({notificationMode: true})
  }
  renderNotification() {
    return (
        <NotificationContainer>
          <Notification title="Success"
                        onCloseButtonClicked={() => this.setState({notificationMode: false})}
                        action={
                          <ActionButton onClick={() => { window.location = `/shortlists/current` }}>
                            View shortlists
                          </ActionButton>
                        }
          >
            Shortlist saved
          </Notification>
        </NotificationContainer>
    )
  }
  removeShortlistItem(item) {
    this.props.removeProductFromCurrentShortlist(item.id);
  }
  renderGuestShortlistComponent() {
    if (this.props.currentShortlistProducts.length === 0) {
      return '';
    }

    return <ShortlistComponentContainer>
      <Shortlist
          key={'guest'}
          defaultShortlistId="guest"
          style={{zIndex: '2'}}
          removeItem={this.removeShortlistItem}
          shortlists={
           [{
              ...this.props.currentShortlist,
              name: 'Shortlist',
              id: 'guest',
              items: this.props.currentShortlistProducts.map(shortlistItem => ({
                id: shortlistItem.id,
                name: shortlistItem.product.name,
                image: shortlistItem.product.display_logo,
                link: shortlistItem.product.link,
              }))
            }]
          }
          onShortlistChange={this.handleShortlistChange}
          onCompareButtonClicked={() => { window.location = '/shortlist' }}
          maximiseShortlistComponent={() => this.setState({minimised: false})}
          minimised={this.state.minimised}
          menu={[]}
          hideMenu={true}
          toolbarItems={[
            <ShortlistMinimiseButton key="minimise" onClick={() => { this.setState({ minimised: !this.state.minimised }) }} />,
            <ShortlistCloseButton key="close" onClick={this.handleCloseShortlistComponent} />
          ]}
      />
    </ShortlistComponentContainer>
  }
  render() {
    const loggedIn = typeof this.props.user.id !== 'undefined'

    if (this.state.notificationMode) {
      return this.renderNotification()
    }

    // Don't render the component if there are no shortlists
    // If the shortlist has been update in this session render the component unless it has been closed by the user
    if (this.props.currentShortlist === null || typeof this.props.currentShortlist === 'undefined' || !this.props.showingShortlistComponent) {
      return '';
    }

    if (!loggedIn) {
      return this.renderGuestShortlistComponent()
    }

    const menuOptions = [
      <MenuButton key="button-rename" onClick={() => this.setState({renameModalOpen: true})}>
        Rename
      </MenuButton>,
    ]

    if (this.props.shortlists.length > 1) {
      menuOptions.push(
          <MenuButton
              key="button-merge"
              onClick={() => {}}
              subMenu={
                this.props.shortlists.filter(shortlist => shortlist.id !== this.props.currentShortlist.id)
                    .map(shortlist =>
                        <MenuButton key={shortlist.id} onClick={() => this.handleMergeShortlists(shortlist)}>
                          {shortlist.name}
                        </MenuButton>)
              }
          >
            Merge
          </MenuButton>
      )
    }

    menuOptions.push(...[
      <MenuButton key="button-share" onClick={() => this.setState({collaboratorsModalOpen: true})}>
        Share
      </MenuButton>,
      <MenuButton key="button-delete" onClick={this.handleDeleteShortlist}>
        Delete
      </MenuButton>
    ])

    return <ShortlistComponentContainer>
      <Shortlist
          key={this.props.currentShortlist.id} // This triggers the component to remount when the current shortlist changes
          defaultShortlistId={this.props.currentShortlist.id}
          removeItem={this.removeShortlistItem}
          shortlists={
            this.props.shortlists.map(shortlist => ({
              ...shortlist,
              items: shortlist.products.map(shortlistItem => ({
                id: shortlistItem.id,
                name: shortlistItem.product.name,
                image: shortlistItem.product.display_logo,
                link: shortlistItem.product.link,
              }))
            }))
          }
          onShortlistChange={this.handleShortlistChange}
          onCompareButtonClicked={() => { window.location = '/shortlist' }}
          maximiseShortlistComponent={() => this.setState({minimised: false})}
          minimised={this.state.minimised}
          menu={menuOptions}
          toolbarItems={[
            <ShortlistMinimiseButton key="minimise" onClick={() => { this.setState({ minimised: !this.state.minimised }) }} />,
            <ShortlistCloseButton key="close" onClick={this.handleCloseShortlistComponent} />
          ]}
      />
      <RenameShortlistModal currentShortlist={this.props.currentShortlist} isOpen={this.state.renameModalOpen} closeModal={() => this.setState({renameModalOpen: false})} />
      <CollaboratorsModal isOpen={this.state.collaboratorsModalOpen} closeModal={() => this.setState({collaboratorsModalOpen: false})} />
    </ShortlistComponentContainer>
  }
}

const mapStateToProps = state => ({
  shortlists: state.shortlists.all,
  showingShortlistComponent: state.shortlists.showingShortlistComponent,
  currentShortlist: state.shortlists.current.shortlist,
  shortlistUpdatedInSession: state.shortlists.current.shortlistUpdatedInSession,
  currentShortlistProducts: state.shortlists.current.products,
  user: state.user,
})

const mapDispatchToProps = dispatch => ({
  setCurrentShortlist: (shortlist) => dispatch(setCurrentShortlist(shortlist)),
  setShowingShortlistComponent: (showingShortlistComponent) => dispatch(setShowingShortlistComponent(showingShortlistComponent)),
  deleteShortlist: (shortlist) => dispatch(deleteShortlist(shortlist)),
  mergeShortlists: (currentShortlist, selectedShortlist) => dispatch(mergeShortlists(currentShortlist, selectedShortlist)),
  removeProductFromCurrentShortlist: (productId) => dispatch(removeProductFromCurrentShortlist(productId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ShortlistComponent)
