import React, { Component } from 'react'
import styled from 'styled-components'

import Review from './reviews/Review'
import Rating from './reviews/Rating'

import FeatureContainer from './common/FeatureContainer'
import Heading from './common/Heading'
import Link from '../common/Link'

const RatingContainer = styled.div`
  margin-left: 0.75rem;
`

export default class Reviews extends Component {
  constructor(props) {
    super(props);

    this.state = {
      numberToDisplay: 3,
    };

    this.showMore = this.showMore.bind(this);
  }
  showMore() {
    this.setState({
      numberToDisplay: this.state.numberToDisplay + 3,
    });
  }
  render() {
    const reviews = this.props.reviews.slice(0, this.state.numberToDisplay).map(review => {
      let date = new Date(review.created_at);
      let dateString = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

      return <Review
          name={review.name}
          review={review.review}
          date={dateString}
          companyName={review.company_name}
          companyUrl={review.company_url}
          photo={null}
          rating={review.star_rating}
      />;
    });

    return (
      <FeatureContainer>
        <div className="d-flex">
          <Heading>{this.props.meta.total.toLocaleString()} Review{this.props.meta.total > 1 ? 's' : ''}</Heading>
          <RatingContainer>
            <Rating rating={this.props.meta.average} fill="#ffbe4d" height="22px" width="20px" />
          </RatingContainer>
        </div>
        {reviews}

        {reviews.length > this.state.numberToDisplay && <Link onClick={() => {this.showMore();}}>Show more Reviews</Link>}
      </FeatureContainer>
    )
  }
}
