import React from 'react'
import ReactSVG from 'react-svg'
import styled from 'styled-components'
import ChevronUpPath from '../../svg/new-chevron-up.svg'
import ChevronDownPath from '../../svg/new-chevron-down.svg'

const ChevronUpReactIcon = (props) => (
  <ReactSVG src={ChevronUpPath} {...props} wrapper="span" />
)

const ChevronDownReactIcon = (props) => (
  <ReactSVG src={ChevronDownPath} {...props} wrapper="span" />
)

const ChevronUpIcon = styled(ChevronUpReactIcon)`
  margin-top: -0.5rem;
  margin-left: 0.5rem;
`

const ChevronDownIcon = styled(ChevronDownReactIcon)`
  margin-top: -0.5rem;
  margin-left: 0.5rem;
`

const SectionContainer = styled.div`
  margin-top: 0px;
  border-top: 1px solid #fff;
  padding-top: 30px;
  padding-bottom: ${props => props.show ? '38px' : '0px'};
  position: relative;
`

const SectionHeading = styled.h3`
  position: absolute;
  top: -0.8rem;
  font-size: 1.25rem;
`

const IconButton = styled.button`
  background: none;
  border: 0;

  &:focus {
    outline: 0;
  }
`

const SectionWrapper = ({ children, heading, hidden, onClick, section }) => (
  <SectionContainer show={!hidden}>
    <SectionHeading onClick={() => onClick(section)}>
      {heading}
      <IconButton>
        {!hidden && <ChevronUpIcon />}
        {hidden && <ChevronDownIcon />}
      </IconButton>
    </SectionHeading>
    {!hidden && <span>{children}</span>}
  </SectionContainer>
)

export default SectionWrapper
