import { USER_RECEIVED } from '../actions/get-user'

const initialState = {};

const user = (state = initialState, action) => {
  switch (action.type) {
    case USER_RECEIVED:
        state = action.user;
      break;
  }

  return state
}

export default user
