import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import styled from 'styled-components'
import axios from 'axios';

import Picker from '../components/common/Picker'
import Listing from '../components/common/Listing'
import Toggle from '../components/common/Toggle'
import LinkButton from '../components/common/LinkButton'
import ListingErrorBoundary from '../error-boundaries/ListingErrorBoundary'

import { manySelectedPlaceholder } from '../helpers'
import PickerErrorBoundary from "../error-boundaries/PickerErrorBoundary";

const ListingsContainer = styled.div`
  box-shadow: 0 -1px 2px 0 rgba(22,22,22,0.04), 0 2px 4px 0 rgba(22,22,22,0.12);
  border-radius: 12px;
`

const Heading = styled.h1`
  color: #161616;
  font-size: 1.5rem;
  font-weight: bold;
`

const Description = styled.p`
  color: #585858;
  font-family: 'HK Grotesk';
  margin-top: 0.75rem;
`

const LinkContainer = styled.div`
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
`

export default class Listings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rankings: [
        { name: "overall_rating", id: "overall_rating" },
      ],
      platforms: this.props.data.filters.platforms.map(dataValue => ({
        name: dataValue.name,
        id: dataValue.id,
      })).sort((a, b) => a.name < b.name ? -1 : 1),
      categories: this.props.data.categories.map(category => ({
        name: category.name,
        id: category.id,
      })).sort((a, b) => a.name < b.name ? -1 : 1),
      industries: this.props.data.filters.industries.map(dataValue => ({
        name: dataValue.name,
        id: dataValue.id,
      })).sort((a, b) => a.name < b.name ? -1 : 1),
      customerSizes: this.props.data.filters.customer_sizes.map(dataValue => ({
        name: dataValue.name,
        id: dataValue.id,
      })).sort((a, b) => a.name < b.name ? -1 : 1),
      features: this.props.data.filters.features.map(dataValue => ({
        name: dataValue.name,
        id: dataValue.id,
      })).sort((a, b) => a.name < b.name ? -1 : 1),
      specialities: this.props.data.filters.specialities.map(dataValue => ({
        name: dataValue.name,
        id: dataValue.id,
      })).sort((a, b) => a.name < b.name ? -1 : 1),
      selectedRankings: [],
      selectedPlatforms: [],
      selectedCategories: [],
      selectedIndustries: [],
      selectedCustomerSizes: [],
      selectedFeatures: [],
      selectedSpecialities: [],
      search: this.props.data.query,
      listings: this.props.data.products.map(
          product => {
            let description = '';
            if (product,product.description) {
              description = product.description.substr(0, 140) + (product.description.length > 140 ? '...' : '');
            }
            return Object.assign({}, product, {
              labels: product.categories.map(category => ({name: null !== category.short_name ? category.short_name : category.slug, link: `/${category.slug}`})),
              image: product.display_logo,
              description,
            })
          }
      ),
    };

    this.onToggleChange = this.onToggleChange.bind(this);
    this.selectListing = this.selectListing.bind(this);
    this.selectRankings = this.selectRankings.bind(this);
    this.selectPlatforms = this.selectPlatforms.bind(this);
    this.selectCategories = this.selectCategories.bind(this);
    this.selectIndustries = this.selectIndustries.bind(this);
    this.selectCustomerSizes = this.selectCustomerSizes.bind(this);
    this.selectFeatures = this.selectFeatures.bind(this);
    this.selectSpecialities = this.selectSpecialities.bind(this);
    this.updateListing = this.updateListing.bind(this);
  }

  onToggleChange() {
    console.log('toggled')
  }

  selectRankings(value) {
    this.setState({ selectedRankings: value });
  }

  selectCategories(value) {
    this.setState({ selectedCategories: value });

    this.updateListing({
      category_ids: value.map(category => category.id),
    });
  }

  selectPlatforms(value) {
    this.setState({ selectedPlatforms: value });

    this.updateListing({
      data_values_group_21: value.map(value => value.id),
    });
  }

  selectIndustries(value) {
    this.setState({ selectedIndustries: value });

    this.updateListing({
      data_values_group_1: value.map(value => value.id),
    });
  }

  selectCustomerSizes(value) {
    this.setState({ selectedCustomerSizes: value });

    this.updateListing({
      data_values_group_3: value.map(value => value.id),
    });
  }

  selectFeatures(value) {
    this.setState({ selectedFeatures: value });

    this.updateListing({
      data_values_group_2: value.map(value => value.id),
    });
  }

  selectSpecialities(value) {
    this.setState({ selectedSpecialities: value });

    this.updateListing({
      data_values_group_38: value.map(value => value.id),
    });
  }

  selectListing() {
    console.log('select')
  }

  updateListing(params) {
    params = Object.assign({
      category_ids: this.state.selectedCategories.map(category => category.id),
      q: this.state.search,
      data_values_group_1: this.state.selectedIndustries.map(industry => industry.id),
      data_values_group_21: this.state.selectedPlatforms.map(platform => platform.id),
    }, params);

    axios.get(window.location.pathname, {
      params,
    }).then(response => {
      const listings = response.data.products.map(
          product => Object.assign({}, product, {
            labels: product.categories.map(category => ({name: null !== category.short_name ? category.short_name : category.slug, link: `/${category.slug}`})),
            image: product.display_logo,
            description: product.description ? product.description.substr(0, 140) + (product.description.length > 140 ? '...' : '') : '',
          })
      );

      this.setState({
        listings,
      });
    });
  }

  render() {
    const {
      categories,
      selectedCategories,
      rankings,
      selectedRankings,
      platforms,
      selectedPlatforms,
      industries,
      selectedIndustries,
      customerSizes,
      features,
      specialities,
      selectedCustomerSizes,
      selectedFeatures,
      selectedSpecialities,
    } = this.state

    return (
      <div>
        <Container>
          <Heading>
            {null !== this.props.data.mainCategory && null !== this.props.data.mainCategory.short_name ? this.props.data.mainCategory.short_name + ' Software' : `Search Results for ${this.props.data.query}`}
          </Heading>

          {(this.props.data.mainCategory && this.props.data.mainCategory.description) &&
            <Description dangerouslySetInnerHTML={{ __html: this.props.data.mainCategory.description }} />
          }

          {this.props.data.mainCategory && <LinkContainer>
            <LinkButton href="#" selected={true}>
              All Results ({this.props.data.products.length})
            </LinkButton>
            {this.props.data.categoryPages.rankingPageVisible &&
              <LinkButton href={`/${this.props.data.mainCategory.slug}/top`}>
                Top 10
              </LinkButton>
            }
            {this.props.data.categoryPages.guidePageVisible &&
              <LinkButton href={`/guides?category_id=${this.props.data.mainCategory.id}`}>
              Guides
              </LinkButton>
            }
          </LinkContainer>}

          <Row>
            <Col sm={12} md={9}>
              <ListingsContainer>
                {this.state.listings.map((listing, index) => (
                  <ListingErrorBoundary key={index} name={listing.name}>
                    <Listing
                      image={listing.image}
                      name={listing.name}
                      slug={listing.slug}
                      description={listing.description}
                      link={listing.link}
                      labels={listing.labels}
                      onClick={this.selectListing}
                    />
                  </ListingErrorBoundary>
                ))}
              </ListingsContainer>
            </Col>
            <Col sm={12} md={3}>
              <PickerErrorBoundary>
                {/*<Picker*/}
                {/*numberDisplayed={1}*/}
                {/*options={rankings}*/}
                {/*valueKey="id"*/}
                {/*labelKey="name"*/}
                {/*multiple={true}*/}
                {/*onChange={this.selectRankings}*/}
                {/*value={selectedRankings}*/}
                {/*placeholder="Sort by Relevance"*/}
                {/*manySelectedPlaceholder={manySelectedPlaceholder(selectedRankings)}*/}
                {/*allSelectedPlaceholder={manySelectedPlaceholder(selectedRankings)}*/}
                {/*/>*/}

                {/*<Picker*/}
                {/*  numberDisplayed={1}*/}
                {/*  options={platforms}*/}
                {/*  valueKey="id"*/}
                {/*  labelKey="name"*/}
                {/*  multiple={true}*/}
                {/*  onChange={this.selectPlatforms}*/}
                {/*  value={selectedPlatforms}*/}
                {/*  placeholder="All Platforms"*/}
                {/*  manySelectedPlaceholder={manySelectedPlaceholder(selectedPlatforms)}*/}
                {/*  allSelectedPlaceholder={manySelectedPlaceholder(selectedPlatforms)}*/}
                {/*/>*/}

                <Picker
                  numberDisplayed={1}
                  options={categories}
                  valueKey="id"
                  labelKey="name"
                  multiple={true}
                  onChange={this.selectCategories}
                  value={selectedCategories}
                  placeholder="All Categories"
                  manySelectedPlaceholder={manySelectedPlaceholder(selectedCategories)}
                  allSelectedPlaceholder={manySelectedPlaceholder(selectedCategories)}
                />

                {this.props.data.mainCategory && this.props.data.mainCategory.slug === 'erp' && <Picker
                  numberDisplayed={1}
                  options={industries}
                  valueKey="id"
                  labelKey="name"
                  multiple={true}
                  onChange={this.selectIndustries}
                  value={selectedIndustries}
                  placeholder="All Industries"
                  manySelectedPlaceholder={manySelectedPlaceholder(selectedIndustries)}
                  allSelectedPlaceholder={manySelectedPlaceholder(selectedIndustries)}
                />}

                <Picker
                    numberDisplayed={1}
                    options={customerSizes}
                    valueKey="id"
                    labelKey="name"
                    multiple={true}
                    onChange={this.selectCustomerSizes}
                    value={selectedCustomerSizes}
                    placeholder="All Customer Sizes"
                    manySelectedPlaceholder={manySelectedPlaceholder(selectedCustomerSizes)}
                    allSelectedPlaceholder={manySelectedPlaceholder(selectedCustomerSizes)}
                />

                <Picker
                    numberDisplayed={1}
                    options={features}
                    valueKey="id"
                    labelKey="name"
                    multiple={true}
                    onChange={this.selectFeatures}
                    value={selectedFeatures}
                    placeholder="All Features"
                    manySelectedPlaceholder={manySelectedPlaceholder(selectedFeatures)}
                    allSelectedPlaceholder={manySelectedPlaceholder(selectedFeatures)}
                />

                {this.props.data.mainCategory && this.props.data.mainCategory.slug === 'ehr' && <Picker
                    numberDisplayed={1}
                    options={specialities}
                    valueKey="id"
                    labelKey="name"
                    multiple={true}
                    onChange={this.selectSpecialities}
                    value={selectedSpecialities}
                    placeholder="All Specialties"
                    manySelectedPlaceholder={manySelectedPlaceholder(selectedSpecialities)}
                    allSelectedPlaceholder={manySelectedPlaceholder(selectedSpecialities)}
                />}
              </PickerErrorBoundary>

              <hr />

              {/*<Toggle*/}
              {/*  label="View Shortlist only"*/}
              {/*  onChange={this.onToggleChange}*/}
              {/*/>*/}
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}
