import Axios from "axios"

export const SHOWING_SHORTLIST_COMPONENT_UPDATED = 'SHOWING_SHORTLIST_COMPONENT_UPDATED'

const setShowingShortlistComponent = (showingShortlistComponent) => (
    dispatch => {
      dispatch({
        type: SHOWING_SHORTLIST_COMPONENT_UPDATED,
        showing_shortlist_component: showingShortlistComponent,
      })

      return Axios.post(`/api/shortlists/showing-shortlist-component`, {
        showing_shortlist_component: showingShortlistComponent,
      })
    }
)

export default setShowingShortlistComponent
