import React from 'react'
import ReactSVG from 'react-svg'
import styled from 'styled-components'

import SearchIconPath from '../../svg/new-search.svg'

const SearchDefaultIcon = (props) => (
  <ReactSVG src={SearchIconPath} wrapper="span" {...props} />
)

const SearchIcon = styled(SearchDefaultIcon)`
  svg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 5%;
    height: 100%;
  }
`

const InputContainer = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`

const Input = styled.input`
  border-radius: 7px;	
  border: 1px solid #eee;
  box-shadow: inset 0 2px 2px 0 rgba(22,22,22,0.08);
  flex: 1;
  height: 2.5rem;
  font-family: "HK Grotesk";
  padding-left: 16%;

  &:focus {
    outline: 0;
  }
`

const SearchInput = (props) => (
  <InputContainer>
    <SearchIcon />
    <Input {...props}></Input>
  </InputContainer>
)

export default SearchInput