import React, { Component } from 'react'
import ReactSVG from 'react-svg'
import styled from 'styled-components'

import DeleteButton from './DeleteButton'

import Button from '../common/Button'

import ShortlistPath from '../../svg/new-shortlist.svg'

import { isAuthenticated } from '../../helpers'

import getCurrentShortlist from '../../actions/get-current-shortlist'
import removeProductFromCurrentShortlist from '../../actions/remove-product-from-current-shortlist'
import { connect } from 'react-redux';

const ShortlistIcon = (props) => (
  <ReactSVG src={ShortlistPath} {...props} wrapper="span" />
)

const Container = styled.div`
  position: relative;
  margin-left: 1rem;
`

const ProductImage = styled.img`
  width: 24px;
  height: 24px;
  border: 1px solid #ebebeb;
  border-radius: 50%;
  object-fit: contain;
  margin-right: 0.5rem;
  background: #fff;
  padding: 0.1rem;
`

const ShortlistDisplayContainer = styled.div`
  margin-left: auto;
  padding-right: ${props => props.hasProfilePicture ? '0.8rem' : '0' };
  margin-top: auto;
  margin-bottom: auto;

  svg {
    height: 100%;
  }
`

const ShortlistCount = styled.span`
  color: #3040e1;
  font-family: "HK Grotesk";
  padding-left: 0.4rem;
  vertical-align: middle;
`

const ShortlistToggle = styled.button`
  background: #e6e8fc;
  border-radius: 22px;
  display: flex;
  height: 40px;
  width: ${props => props.hasProfilePicture ? '94px' : 'auto' };
  padding: ${props => props.hasProfilePicture ? '0' : '0 1rem' };
  margin: 0;
  border: 0;

  &:focus {
    outline: 0;
  }
`

const ListContainer = styled.div`
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 4px 0 rgba(22, 22, 22, 0.12), 0 -1px 2px 0 rgba(22, 22, 22, 0.04);
  z-index: 3;
  position: absolute;
  padding: 1rem;
  right: 0;
  width: 238px;
  font-family: "HK Grotesk";
`

const ProductList = styled.ul`
  padding: 0;
  list-style-type: none;
`

const ProductListItem = styled.li`
  padding: 0.5rem;
  font-size: 14px;
  color: #585858;
  display: flex;
  
  &:hover {
    background: #e6e8fc
    border-radius: 0.5rem;
    color: #3040e1;
    cursor: pointer;

    .delete-button {
      display: flex;
    }
  }
`

const ProfileImage = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
`

const CompareButton = styled(Button)`
  padding: .5rem 1rem;
`

class Dropdown extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
    }

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this)
    this.compareProducts = this.compareProducts.bind(this)
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  setWrapperRef(node) {
    this.wrapperRef = node;
  }
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({open: false})
    }
  }
  toggleOpen() {
    if (!this.state.open) {
      this.props.getCurrentShortlist()
          .then(this.setState({
            open: true,
          }))
    }

    this.setState({
      open: !this.state.open
    })
  }
  compareProducts() {
    window.location = '/shortlist'
  }
  render() {
    return (
        <div>
          {this.props.products.length === 0 && (
              <Container>
                {(this.props.hasProfilePicture && this.props.user.avatar_url) &&
                <ProfileImage className="rounded-circle" src={this.props.user.avatar_url}/>}
              </Container>
          )}
          {this.props.products.length > 0 && (
              <Container ref={this.setWrapperRef}>
                <ShortlistToggle hasProfilePicture={this.props.hasProfilePicture} onClick={() => this.toggleOpen()}>
                  <div>
                    {this.props.user.avatar_url &&
                    <ProfileImage className="rounded-circle" src={this.props.user.avatar_url}/>}
                  </div>
                  <ShortlistDisplayContainer hasProfilePicture={this.props.hasProfilePicture}>
                    <ShortlistIcon/>
                    {this.props.products && <ShortlistCount>{this.props.products.length}</ShortlistCount>}
                  </ShortlistDisplayContainer>
                </ShortlistToggle>

                {this.state.open && (
                    <ListContainer>
                      <ProductList>
                        {this.props.products && this.props.products.map((product, index) => (
                            <ProductListItem key={index}>
                              <ProductImage src={product.product.display_logo}/>
                              {product.product.name}
                              <DeleteButton onClick={() => this.props.removeProductFromCurrentShortlist(product.id)}/>
                            </ProductListItem>
                        ))}
                      </ProductList>

                      <CompareButton
                          className="mt-3"
                          primary={true}
                          onClick={() => this.compareProducts()}
                      >
                        {this.props.products.length < 2 ? 'View Shortlist' : 'Compare Shortlist'}
                      </CompareButton>
                    </ListContainer>
                )}
              </Container>
          )}
        </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
  shortlist: state.shortlists.current.shortlist,
  products: state.shortlists.current.products,
})

const mapDispatchToProps = dispatch => ({
  getCurrentShortlist: () => dispatch(getCurrentShortlist()),
  removeProductFromCurrentShortlist: (productId) => dispatch(removeProductFromCurrentShortlist(productId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Dropdown)
