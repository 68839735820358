import React, { Component } from 'react'
import ReactSVG from 'react-svg'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'
import chunk from 'lodash/chunk'
import take from 'lodash/take'
import { mediaBreakpointDownMd } from "styled-bootstrap-responsive-breakpoints";

import VisualCheckPath from '../../../svg/new-visual-check.svg'

const VisualCheck = (props) => (
  <ReactSVG src={VisualCheckPath} {...props} />
)

const FeatureContainer = styled.div`
  margin: 1.5rem 0;
`

const FeatureRow = styled(Row)`
  margin-top: 1rem;
`

const CategoryFilter = styled.a`
  margin-right: 1rem;
  font-size: 0.75rem;
  color: ${props => props.selected ? '#3040e1' : '#585858'};
  font-weight: ${props => props.selected ? 600 : 500};

  &:hover {
    text-decoration: none;
    color: #3040e1;
  }
`

const FeatureRowContainer = styled.div`
  position: relative;
`

const StyledVisualCheck = styled(VisualCheck)`
  margin-right: 0.5rem;
`

const GradientOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 70%, rgb(255, 255, 255) 100%);
`

const ShowMoreButton = styled.button`
  background: none;
  border: 0;
  padding: 0;
  color: #3040e1;
  font-weight: 600;

  &:focus {
    outline: 0;
  }
`

export default class FeaturesList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      limit: 18,
      category: null,
      features: [],
      expanded: false,
    }

    this.expand = this.expand.bind(this)
    this.selectCategory = this.selectCategory.bind(this)
  }

  componentDidMount() {
    this.filterFeatures()
  }

  expand() {
    this.setState({
      expanded: true,
    }, () => this.filterFeatures())
  }

  filterFeatures() {
    const { category } = this.state

    let features = this.props.features.filter(feature => (
      category === null || (feature.categories !== undefined && feature.categories.map(category => category.short_name).includes(category))
    ))

    if (!this.state.expanded) {
      features = take(features, this.state.limit)
    }

    this.setState({
      features,
    })
  }

  isExpanded() {
    return this.props.features.length <= this.state.limit || this.state.expanded
  }

  selectCategory(id, event) {
    event.preventDefault();

    this.setState({ category: id }, () => this.filterFeatures())
  }

  render() {
    return (
      <div>
        <FeatureContainer>
          {this.props.categories.map((category, index) => (
            <CategoryFilter
              key={index}
              href="#"
              selected={category.id === this.state.category}
              onClick={(event) => this.selectCategory(category.id, event)}
            >
              {category.text}
            </CategoryFilter>
          ))}

          <FeatureRowContainer>
            {chunk(this.state.features, 3).map((featureRow, index) => (
              <FeatureRow key={index}>
                {featureRow.map((feature, index) => (
                  <Col className="d-flex" key={index} xs={12} sm={6} md={4}><StyledVisualCheck /> {feature.feature}</Col>
                ))}
              </FeatureRow>
            ))}

            {!this.isExpanded() && <GradientOverlay />}
          </FeatureRowContainer>

        </FeatureContainer>

        {!this.isExpanded() && (
          <ShowMoreButton onClick={this.expand}>
            Show all {this.props.features.length} features
          </ShowMoreButton>
        )}
      </div>
    )
  }
}
