import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { mediaBreakpointDownSm } from 'styled-bootstrap-responsive-breakpoints'

import DefaultCard from '../Card'
import Icon from './Icon'
import DefaultButtonLink from '../ButtonLink'

const Card = styled(DefaultCard)`
  ${mediaBreakpointDownSm`
    align-items: center;
    flex-direction: row;
    height: auto;
    margin-bottom: 1rem;
    margin-right: 0;
    min-height: auto;
    max-height: 100%;
    padding: 1rem;
  `}
`

const LinkWrapper = styled.div`
  display: flex;
  margin-top: auto;

  ${mediaBreakpointDownSm`
    margin-top: 0;
  `}
`

const CardHeader = styled.h5`
  flex: 1;
  font-size: 1.5rem;

  ${mediaBreakpointDownSm`
    font-size: 1.25rem;
    margin-top: auto;
    margin-bottom: auto;
  `}
`

const ButtonLink = styled(DefaultButtonLink)`
  ${mediaBreakpointDownSm`
    display: none;
  `}
`

const MobileButtonLink = styled(ButtonLink)`
  display: none;
  min-width: 3.75rem;

  ${mediaBreakpointDownSm`
    display: flex;
  `}
`

const CategoryCard = ({ header, productCount, linkProps, iconProps, ...props }) => (
  <Card {...props}>
    <Icon {...iconProps} />
    <CardHeader>{header}</CardHeader>
    
    <LinkWrapper>
      <ButtonLink {...linkProps}>{productCount} products</ButtonLink>
      <MobileButtonLink {...linkProps}>{productCount}</MobileButtonLink>
    </LinkWrapper>
  </Card>
)

CategoryCard.defaultProps = {
  linkProps: {},
  iconProps: {},
  productCount: 0,
}

CategoryCard.propTypes = {
  header: PropTypes.string.isRequired,
  linkProps: PropTypes.object,
  iconProps: PropTypes.object,
  productCount: PropTypes.number,
}

export default CategoryCard