import React, { Component } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'
import axios from 'axios'

import Button from './Button'
import Input from './Input'
import InputGroup from './InputGroup'
import Label from './Label'

const LogInText = styled.p`
  font-size: 0.875rem;
  text-align: center;
  padding-top: 1.25rem;
  font-weight: 500;

  a {
    font-weight: 600;
    color: #3040e1;
  }
`

const TermsText = styled.p`
  color: #585858;
  font-size: 0.75rem;
  font-weight: 500;

  a {
    font-weight: 600;
    color: #3040e1;
  }
`

const ErrorText = styled.p`
  color: red;
`

const initialErrors = {
  general: null,
  name: null,
  email: null,
  company: null,
  work_contact_number: null,
  password: null,
}

export default class RegisterForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: '',
      email: '',
      company: '',
      work_contact_number: '',
      password: '',
      errors: Object.assign({}, initialErrors),
    }

    this.handleChange = this.handleChange.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }
  submitForm() {
    const formData = {...this.state}
    axios.post('/register', formData).then((response) => {
      window.location = response.request.responseURL;
    }).catch((error) => {
      let newState = {
        errors: Object.assign(
          {},
            initialErrors,
            {general: ['There was an issue when submitting the registration form']}
          )
      };

      if (error.response.data.hasOwnProperty('errors')) {
        newState = {errors: Object.assign({}, initialErrors, error.response.data.errors)};
      } else if (error.response.data.hasOwnProperty('message')) {
        newState = {
          errors: Object.assign(
              {},
              initialErrors,
              {general: [error.response.data.message]}
          )
        };
      }

      this.setState(newState);
    });
  }
  render() {
    const errorDisplay = {};
    for (const property in this.state.errors) {
      if (this.state.errors.hasOwnProperty(property) && Array.isArray(this.state.errors[property])) {
        errorDisplay[property] = <ErrorText>{this.state.errors[property].join(', ')}</ErrorText>;
      }
    }

    return (
      <div>
        { errorDisplay.general ? errorDisplay.general : '' }
        <InputGroup>
          { errorDisplay.name ? errorDisplay.name : '' }
          <Label htmlFor="name">
            Full Name
          </Label>
          <Input placeholder="Enter your name" id="name" name="name" onChange={this.handleChange} />
        </InputGroup>
        <InputGroup>
          { errorDisplay.email ? errorDisplay.email : '' }
          <Label htmlFor="email">
            Email
          </Label>
          <Input placeholder="you@email.com" id="email" name="email" onChange={this.handleChange} />
        </InputGroup>
        <Row>
          <Col>
            <InputGroup>
              { errorDisplay.company ? errorDisplay.company : '' }
              <Label htmlFor="company">
                Company
              </Label>
              <Input placeholder="Company Name" id="company" name="company" onChange={this.handleChange} />
            </InputGroup>
          </Col>
          <Col>
            <InputGroup>
              { errorDisplay.work_contact_number ? errorDisplay.work_contact_number : '' }
              <Label htmlFor="work_contact_number">
                Work Contact No
              </Label>
              <Input type="tel" placeholder="+1 541 754 3010" id="work_contact_number" name="work_contact_number" onChange={this.handleChange} />
            </InputGroup>
          </Col>
        </Row>
        <InputGroup>
            { errorDisplay.password ? errorDisplay.password : '' }
            <Label htmlFor="password">
              Password
            </Label>
          <Input type="password" placeholder="8 digits or more" id="password" name="password" onChange={this.handleChange} />
        </InputGroup>

        <TermsText>
          For information on our data policies, see our <a href="/terms">Terms of Service</a> and <a href="/privacy">Privacy Policy</a>.
        </TermsText>

        <Button onClick={this.submitForm}>Sign Up</Button>

        <LogInText>Already have an account? <a href="/login">Log In</a></LogInText>
      </div>
    )
  }
}
