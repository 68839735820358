import React from 'react'
import styled from 'styled-components'
import { colors } from 'prospect-path-base'

const { silver, light } = colors
const { silverLight } = silver

const Button = styled.button`
  background: ${props => (props.primary ? '#3040E1' : '#fff')};
  border: 1px solid ${props => (props.primary ? '#3040E1' : '#f2f2f2')};
  color: ${props => (props.primary ? '#fff' : '#585858')};
  text-align: center;
  font-family: "HK Grotesk";
  width: 100%;
  padding: .75rem 1rem;
  display: block;
  border-radius: 12px;
  font-weight: 600;
  margin-bottom: 0.5rem;
  user-select: none;

  &:focus {
    outline: 0;
  }

  &:disabled {
    background: ${silverLight};
    color: ${light};
    border: 1px solid transparent;
  }

  &:hover {
    color: #fff;
    color: ${props => (props.primary ? '#fff' : '#585858')};
    text-decoration: none;
  }
`

export default Button