import React from 'react'
import styled from 'styled-components'
import { Row as DefaultRow, Col } from 'reactstrap'
import { mediaBreakpointDownSm } from 'styled-bootstrap-responsive-breakpoints'

import { default as DefaultContainer } from '../Container'
import ButtonLink from '../ButtonLink'

import CategoryCard from '../categories/CategoryCard'

import AccountingIcon from '../../../svg/icons/accounting-finance.svg'
import AIIcon from '../../../svg/icons/ai.svg'
import CustomerServicesIcon from '../../../svg/icons/customer-services.svg'
import ERPIcon from '../../../svg/icons/erp.svg'
import CollaborationIcon from '../../../svg/icons/collaboration.svg'
import SupplyIcon from '../../../svg/icons/supply-chain-logistics.svg'
import SecurityIcon from '../../../svg/icons/security.svg'
import TravelIcon from '../../../svg/icons/travel.svg'

const Section = styled.div`
  max-width: 70rem;
  margin-left: auto;
  margin-right: auto;

  ${mediaBreakpointDownSm`
    margin-left: 0;
    margin-right: 0;
  `}
`

const Container = styled(DefaultContainer)`
  margin: 10rem 0;

  ${mediaBreakpointDownSm`
    margin: 3.75rem 0;
    padding: 1.25rem;
  `}
`

const Row = styled(DefaultRow)`
  margin-bottom: 1.5rem;

  ${mediaBreakpointDownSm`
    margin-bottom: 0;
  `}
`

const Header = styled.h2`
  font-family: 'Tiempos Headline', serif;
  color: #000749;
  font-size: 2.75rem;
  text-align: center;
  margin-bottom: 3rem;

  ${mediaBreakpointDownSm`
    text-align: left;
    font-size: 2rem;
    margin-bottom: 2rem;
  `}
`

const CategoryLink = styled(ButtonLink)`
  background: #3040E1;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  max-width: 20rem;
  width: 100%;

  ${mediaBreakpointDownSm`
    margin-top: 1.5rem;
    max-width: 100%;
  `}
`

const CategoriesSection = ({selectedCategories}) => (
  <Section>
    <Container>
      <Header>
        Tools to make the job easier
      </Header>

      <Row>
        <Col sm={12} md={3}>
          <CategoryCard
            header={selectedCategories[0].category.name}
            iconProps={{
              icon: selectedCategories[0].icon,
              iconBackground: '#E1EFEB',
              iconFill: '#087F5B',
            }}
            linkProps={{ href: `/${selectedCategories[0].category.slug}` }}
            productCount={selectedCategories[0].category.products}
          />
        </Col>
        <Col sm={12} md={3}>
          <CategoryCard
            header={selectedCategories[1].category.name}
            iconProps={{
              icon: selectedCategories[1].icon,
              iconBackground: '#FFF7E9',
              iconFill: '#FFBF4A',
            }}
            linkProps={{ href: `/${selectedCategories[1].category.slug}` }}
            productCount={selectedCategories[1].category.products}
          />
        </Col>
        <Col sm={12} md={3}>
          <CategoryCard
            header={selectedCategories[2].category.name}
            iconProps={{
              icon: selectedCategories[2].icon,
              iconBackground: '#E6E8FC',
              iconFill: '#3040E1',
            }}
            linkProps={{ href: `/${selectedCategories[2].category.slug}` }}
            productCount={selectedCategories[2].category.products}s
          />
        </Col>
        <Col sm={12} md={3}>
          <CategoryCard
            header={selectedCategories[3].category.name}
            iconProps={{
              icon: selectedCategories[3].icon,
              iconBackground: '#E1EFEB',
              iconFill: '#087F5B',
            }}
            linkProps={{ href: `/${selectedCategories[3].category.slug}` }}
            productCount={selectedCategories[3].category.products}
          />
        </Col>
      </Row>

      <Row>
        <Col sm={12} md={3}>
          <CategoryCard
              header={selectedCategories[4].category.name}
              iconProps={{
                icon: selectedCategories[4].icon,
                iconBackground: '#FFF7E9',
                iconFill: '#FFBF4A',
              }}
              linkProps={{ href: `/${selectedCategories[4].category.slug}` }}
              productCount={selectedCategories[4].category.products}
          />
        </Col>
        <Col sm={12} md={3}>
          <CategoryCard
            header={selectedCategories[5].category.name}
            iconProps={{
              icon: selectedCategories[5].icon,
              iconBackground: '#E6E8FC',
              iconFill: '#3040E1',
            }}
            linkProps={{ href: `/${selectedCategories[5].category.slug}` }}
            productCount={selectedCategories[5].category.products}
          />
        </Col>
        <Col sm={12} md={3}>
          <CategoryCard
            header={selectedCategories[6].category.name}
            iconProps={{
              icon: selectedCategories[6].icon,
              iconBackground: '#E1EFEB',
              iconFill: '#087F5B',
            }}
            linkProps={{ href: `/${selectedCategories[6].category.slug}` }}
            productCount={selectedCategories[6].category.products}
          />
        </Col>
        <Col sm={12} md={3}>
          <CategoryCard
            header={selectedCategories[7].category.name}
            iconProps={{
              icon: selectedCategories[7].icon,
              iconBackground: '#FFF7E9',
              iconFill: '#FFBF4A',
            }}
            linkProps={{ href: `/${selectedCategories[7].category.slug}` }}
            productCount={selectedCategories[7].category.products}
          />
        </Col>
      </Row>

      <CategoryLink href="/categories">View all categories</CategoryLink>
    </Container>
  </Section>
)

export default CategoriesSection
