import React from 'react'
import styled from 'styled-components'
import { NavItem as DefaultNavItem } from 'reactstrap'

const NavItem = styled(DefaultNavItem)`
  display: flex;
  font-weight: 400;
  margin: 0 16px;
`

export default NavItem
