import React from 'react'
import ReactSVG from 'react-svg'
import styled from 'styled-components'
import range from 'lodash/range'

import StarIcon from '../icons/StarIcon'

export default ({ rating }) => {
  const ratingRange = range(0, Math.round(rating))
  const emptyRating = range(0, 5 - Math.round(rating))

  return (
    <span>
      {Math.round(rating) === 5 && (
        <span>
          {ratingRange.map((key) => (
            <StarIcon key={key} fill="#087f5b" width="16px" height="16px"></StarIcon>
          ))}
        </span>
      )}

      {Math.round(rating) !== 5 && (
        <span>
          {ratingRange.map(key => (
            <StarIcon key={key} fill="#ffbf4a" width="16px" height="16px"></StarIcon>
          ))}
          {emptyRating.map(key => (
            <StarIcon key={key} fill="#ebebeb" width="16px" height="16px"></StarIcon>
          ))}
        </span>
      )}
    </span>
  )
}
