import styled from 'styled-components'

const Card = styled.div`
  background-color: #fff;	
  border-radius: 24px;	
  box-shadow: 0 -1px 2px 0 rgba(22,22,22,0.04), 0 2px 4px 0 rgba(22,22,22,0.12);
  min-height: 240px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 2.125rem;
  width: 100%;

  &:hover {
    border-radius: 25.2px;	
    background-color: #fff;	
    box-shadow: 0 -1px 2px 0 rgba(22,22,22,0.04), 0 12px 24px -8px rgba(0,0,0,0.24);
  }

  &:last-of-type {
    margin-right: 0;
  }
`

export default Card