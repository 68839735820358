import React from 'react'
import styled from 'styled-components'
import { mediaBreakpointDownMd } from 'styled-bootstrap-responsive-breakpoints'

import CircleImage from '../../../img/hero-image.png'
import HeroPattern from '../../../svg/hero-pattern.svg'
import StarRating from './StarRating';

const ImageContainer = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  
  ${mediaBreakpointDownMd`
    max-width: 20.875rem;
    margin-top: 2rem;
  `}
`

const Pattern = styled.img`
  position: absolute;
  top: 3.6rem;
  left: 1.6rem;
  width: 20%;

  ${mediaBreakpointDownMd`
    top: 2rem;
    left: 1.6rem;
  `}
`

const Image = styled.img`
  position: relative;
  width: 100%;
  max-width: 31.5rem;
`

const HeroImage = () => (
  <ImageContainer>
    <Pattern src={HeroPattern} />
    <Image src={CircleImage} />
    <StarRating style={{ position: 'absolute', top: '80%', left: '15px' }} rating={3} />
    <StarRating style={{ position: 'absolute', top: '68%', right: '15px' }} rating={4} />
  </ImageContainer>
)

export default HeroImage
