import React from 'react'
import styled from 'styled-components'
import { mediaBreakpointUpSm, mediaBreakpointUpMd, mediaBreakpointUpLg, mediaBreakpointUpXl } from 'styled-bootstrap-responsive-breakpoints'

const InfiniteContainer = styled.div`
  padding-left: 15px;
  margin-left: auto;
  margin-top: 3rem;

  ${mediaBreakpointUpSm`
    margin-left: calc(50vw - 270px);
  `}

  ${mediaBreakpointUpMd`
      margin-left: calc(50vw - 360px);
  `}

  ${mediaBreakpointUpLg`
    margin-left: calc(50vw - 480px);
  `}

  ${mediaBreakpointUpXl`
    margin-left: calc(50vw - 570px);
  `}
`

export default InfiniteContainer