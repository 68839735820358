import React from 'react'
import styled from 'styled-components'
import { Navbar as BSNavbar } from 'reactstrap'

const Navbar = styled(BSNavbar)`
  margin-bottom: 2rem;
  padding: 1rem;
  z-index: 7;
  ${props => props.focused ? `
    box-shadow: 0 -1px 2px 0 rgba(22,22,22,0.04), 0 2px 4px 0 rgba(22,22,22,0.12);
  ` :  '' }
`

export default Navbar