import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactSVG from 'react-svg'
import Slider from 'react-slick'
import { BaseButton } from 'prospect-path-base'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import ChevronLeftPath from '../../svg/new-chevron-small-left.svg'
import ChevronRightPath from '../../svg/new-chevron-small-right.svg'

const ChevronLeft = (props) => (
  <ReactSVG src={ChevronLeftPath} {...props} />
)

const ChevronRight = (props) => (
  <ReactSVG src={ChevronRightPath} {...props} />
)

import FeatureContainer from './common/FeatureContainer'
import Heading from './common/Heading'

const ControlButton = styled(BaseButton)`
  font-size: 100%;
  margin: 0 0.1rem;
  padding: 0.25rem;
  border: 0;

  &:focus {
    outline: 0;
  }
`

const ScreenshotImage = styled.img`
  width: auto;
  height: 160px;
  margin-right: 1rem;
`

const ScreenshotContainer = styled(FeatureContainer)`
  ${props => props.divider ? `
    border-top: 1px solid #ebebeb;
  ` : `
    border-top: none;
  `}
`

export default class Screenshots extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.openLightbox = this.openLightbox.bind(this)
    this.closeLightbox = this.closeLightbox.bind(this)

    this.state = {
      showLightbox: false,
      slide: null,
    }
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  openLightbox() {
    this.setState({ showLightbox: true })
  }
  closeLightbox() {
    this.setState({ showLightbox: false })
  }
  goToLightboxSlide(slide) {
    this.openLightbox()
    this.setState({ slide })
  }
  render() {
    const screenshots = this.props.screenshots.map(screenshot => screenshot.screenshot_url);

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3.2,
      slidesToScroll: 1,
      arrows: false,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2.5,
            slidesToScroll: 1,
            variableWidth: true,
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            variableWidth: true,
          }
        },
        {
          breakpoint: 550,
          settings: {
            slidesToShow: 1.8,
            slidesToScroll: 1,
            variableWidth: true,
          }
        }
      ]
    };

    return (
      <ScreenshotContainer divider={this.props.divider}>
        <div className="d-flex">
          <Heading>Screenshots</Heading>

          {this.state.showLightbox && (
            <Lightbox
                reactModalStyle={{ content: { minWidth: '100%' } }}
                mainSrc={screenshots[this.state.slide]}
                nextSrc={screenshots[(this.state.slide + 1) % screenshots.length]}
                prevSrc={screenshots[(this.state.slide + screenshots.length - 1) % screenshots.length]}
                onCloseRequest={() => this.closeLightbox()}
                onMovePrevRequest={() =>
                    this.setState({
                      slide: (this.state.slide + screenshots.length - 1) % screenshots.length,
                    })
                }
                onMoveNextRequest={() =>
                    this.setState({
                      slide: (this.state.slide + 1) % screenshots.length,
                    })
                }
                enableZoom={false}
            />
          )}

          <div style={{ textAlign: "center", marginLeft: 'auto' }}>
            <ControlButton onClick={this.previous}>
              <ChevronLeft />
            </ControlButton>
            <ControlButton onClick={this.next}>
              <ChevronRight />
            </ControlButton>
          </div>
        </div>

        <br />

        <Slider ref={slider => (this.slider = slider)} {...settings}>
          {screenshots.map((screenshot, index) => (
            <div onClick={() => this.goToLightboxSlide(index)} key={index}>
              <ScreenshotImage src={screenshot} />
            </div>
          ))}
        </Slider>
      </ScreenshotContainer>
    )
  }
}

Screenshots.propTypes = {
  divider: PropTypes.bool,
}

Screenshots.defaultProps = {
  divider: true,
}
