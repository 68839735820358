import React, { Component } from 'react'
import ReactSVG from 'react-svg'
import styled from 'styled-components'

import VisualFlagPath from '../../svg/new-visual-flag.svg'
import VisualCheckPath from '../../svg/new-visual-check.svg'

const VisualFlag = (props) => (
  <ReactSVG src={VisualFlagPath} wrapper="span" {...props} />
)

const VisualCheck = (props) => (
  <ReactSVG src={VisualCheckPath} wrapper="span" {...props} />
)


const VendorTextContainer = styled.div`
  color: #585858;
  margin-top: 1rem;
  font-size: 0.75rem;
  text-align: center;
`

const StyledVisualCheck = styled(VisualCheck)`
  rect {
    fill: #fff;
  }

  path {
    fill: #83bfad;
  }
`

const VendorLink = styled.a`
  color: #585858;
  text-align: center;

  &:hover {
    color: #585858;
    text-decoration: none;
  }
`

const VendorText = ({ verified }) => (
  <VendorTextContainer>
    {!verified && <VendorLink href="https://app.prospectpath.com/signup"><VisualFlag /> Work for this vendor?</VendorLink>}
    {verified && <span><StyledVisualCheck /> Profile verified by vendor</span>}
  </VendorTextContainer>
)

export default VendorText
