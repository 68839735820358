import styled from 'styled-components'

const ProductImage = styled.img`
  border-radius: 50%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #ebebeb;
  width: 96px;
  height: 96px;
  object-fit: contain;
  padding: 0.3rem;
`

export default ProductImage