import React, { Component } from 'react'
import styled from 'styled-components'

const PlanContainer = styled.div.attrs({
  className: 'plan'
})`
  background-color: ${props => props.featured ? '#f5faf9' : null};
  display: flex;
  flex: 1;
  padding: 1.5rem;
  flex-direction: row;
  flex-wrap: wrap;
  border-right: 1px solid #ebebeb;

  &:last-child {
    border: 0;
  }
`

const DetailsContainer = styled.div.attrs({
  className: 'plan-details'
})`
  flex: 1;
`

const LabelContainer = styled.div.attrs({
  className: 'plan-label'
})`
  display: flex;
  align-items: center;
`

const PriceContainer = styled.div.attrs({
  className: 'plan-price'
})`
  display: flex;
  align-items: center;
`

const Name = styled.h4`
  margin-bottom: 0;
`

const Period = styled.p`
  margin-bottom: 0;
`

const Currency = styled.span`
  font-size: 0.875rem;
  vertical-align: top;
  padding-right: 0.25rem;
`

const Price = styled.span`
  padding-left: 2rem;
  color: #161616;
  font-size: 1.25rem;
`

const Label = styled.span`
  background: #087f5b;
  border-radius: 8px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  font-size: 0.75rem;
`

export default class Plan extends Component {
  render() {
    return (
      <PlanContainer featured={this.props.featured}>
        <DetailsContainer>
          <Name>{this.props.name}</Name>
          {this.props.period && <Period>{this.props.period}</Period>}
        </DetailsContainer>
        <LabelContainer>
          {this.props.cheapest && <Label>Cheapest</Label>}
        </LabelContainer>
        <PriceContainer>
          <Price>
            <Currency>{this.props.currency}</Currency> {this.props.price}
          </Price>
        </PriceContainer>
      </PlanContainer >
    )
  }
}