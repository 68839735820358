import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import range from 'lodash/range'
import { mediaBreakpointDownMd } from 'styled-bootstrap-responsive-breakpoints'

const scroll = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(calc(-6rem * 7))}
`

const SliderContainer = styled.div`
  height: 4.625rem;
  margin: auto;
  overflow: hidden;
  position: relative;
  display: flex;

  ${mediaBreakpointDownMd`
    display: none;
  `}

  &::before,
  &::after {
    content: "";
    height: 4.625rem;
    position: absolute;
    width: 2.25rem;
    z-index: 2;
    background: linear-gradient(270deg, rgba(255,255,255,0.5) 0%, #FFFFFF 100%);
  }

	&::after {
		right: 0;
		top: 0;
    transform: scaleX(-1);
	}

	&::before {
		left: 0;
		top: 0;
	}
`

const Track = styled.div`
  animation: ${scroll} 40s linear infinite;
  display: flex;
  width: calc(6rem * 14);
  margin-top: auto;
  margin-bottom: auto;
`

const Slide = styled.div`
  height: 4rem;
  min-width: 6rem;
`

const Image = styled.img`
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  user-select: none;
`

const Slider = ({ imageUrls }) => (
  <SliderContainer>
    <Track>
      {range(0, 2).map(key => (
        <Fragment key={key}>
          {imageUrls.map((imageUrl) => (
            <Slide key={imageUrl}>
              <Image src={imageUrl}></Image>
            </Slide>
          ))}
        </Fragment>
      ))}
    </Track>
  </SliderContainer>
)

Slider.propTypes = {
  imageUrls: PropTypes.arrayOf(PropTypes.string)
}

Slider.defaultProps = {
  imageUrls: []
}

export default Slider
