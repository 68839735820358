import styled from 'styled-components';

const Link = styled.a`
  font-weight: 600;
  color: #3040e1;
  cursor: pointer;

  &:hover {
    color: #3040e1;
    text-decoration: none;
  }
`

export default Link
