import React from 'react'
import styled from 'styled-components'
import { Col, Row as DefaultRow } from 'reactstrap'
import { mediaBreakpointDownMd } from 'styled-bootstrap-responsive-breakpoints'

import { default as DefaultContainer } from '../Container'
import ToolCard from '../trending/ToolCard'
import Pattern from '../../../svg/pattern.svg'

const Section = styled.section`

`

const Container = styled(DefaultContainer)`
  max-width: 1120px;
  margin-top: 10rem;
  margin-bottom: 9.75rem;

  ${mediaBreakpointDownMd`
    margin-top: 3.75rem;
    padding: 0;
  `}
`

const LeftPattern = styled.img`
  position: absolute;
  bottom: -1.125rem;
  left: -2.125rem;
  z-index: -1;

  ${mediaBreakpointDownMd`
    bottom: -1.5rem;
    left: 1.4rem;
  `}
`

const RightPattern = styled.img`
  position: absolute;
  right: -2.8rem;
  top: -1.1rem;
  z-index: -1;

  ${mediaBreakpointDownMd`
    display: none;
  `}
`

const Heading = styled.h3`
  text-align: center;
  margin-bottom: 2rem;
  
  ${mediaBreakpointDownMd`
    text-align: left;
    padding: 0 2rem;
  `}
`

const Row = styled(DefaultRow)`
  position: relative; 
  flex-wrap: nowrap;
  padding: 1rem 0;

  ${mediaBreakpointDownMd`
    padding-left: 20px;
    flex-wrap: nowrap;
    overflow-x: scroll;
  `}
`

const ToolContainer = styled.div`
  position: relative;

  ${mediaBreakpointDownMd`
    padding: 0 1rem;
  `}
`

const TrendingToolsSection = ({trendingProducts}) => (
  <Section>
    <Container>
      <Heading>Trending Tools</Heading>

      <ToolContainer>
        <LeftPattern src={Pattern} />
        <Row>
          {trendingProducts.map(product =>
            <Col key={product.slug}>
              <ToolCard
                  header={product.name}
                  logoUrl={product.image}
                  linkProps={{href: product.link}}
                  tags={product.categories.slice(0, 2)}
              />
            </Col>
          )}
        </Row>
        <RightPattern src={Pattern} />
      </ToolContainer>
    </Container>
  </Section>
)

export default TrendingToolsSection
