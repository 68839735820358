import Axios from "axios";
import getShortlists from './get-shortlists'
import store from '../store'

export const PRODUCT_REMOVED_FROM_SHORTLIST = 'PRODUCT_REMOVED_FROM_SHORTLIST'

const removeProductFromShortlist = (productId, shortlistId) => (
    dispatch => {
      Axios.delete(`/api/shortlists/${shortlistId}/products/${productId}`).then(() => {
        dispatch({
          type: PRODUCT_REMOVED_FROM_SHORTLIST,
          shortlistId,
          productId
        })
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          store.dispatch(getShortlists())
        }
      })
    }
)

export default removeProductFromShortlist
