import React from 'react'
import styled from 'styled-components'
import ReactSVG from 'react-svg'

import ChevronDownPath from '../../svg/new-chevron-down.svg'

const ChevronDownReactIcon = (props) => (
  <ReactSVG src={ChevronDownPath} wrapper="span" {...props} />
)

const ChevronDownIcon = styled(ChevronDownReactIcon)`
  svg {
    path {
      fill: #bbbbbb;
    }
  }
`

export default ChevronDownIcon