import Axios from "axios";
import getShortlists from './get-shortlists'
import store from '../store'

export const SHORTLIST_DELETED = 'SHORTLIST_DELETED'

const deleteShortlist = (shortlist) => (
    dispatch => {
      return Axios.delete(`/api/shortlists/${shortlist.id}`, {
        ...shortlist,
      }).then(response => {
        dispatch({
          type: SHORTLIST_DELETED,
          shortlist,
        })

        return shortlist
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          store.dispatch(getShortlists())
        }
      })
    }
)

export default deleteShortlist
