import React from 'react'
import { connect } from 'react-redux'
import {Modal, ModalCloseButton, ModalHeader, Input, Label, Button, Invitee} from 'software-path-components'
import inviteUserToShortlist from '../../actions/invite-user-to-shortlist'
import removeInvitationFromShortlist from '../../actions/remove-invitation-from-shortlist'
import removeUserFromShortlist from '../../actions/remove-user-from-shortlist'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'

const TooltipContainer = styled.div`
  .__react_component_tooltip.show {
    opacity: 1 !important;
    margin-left: -80px;
    
    &::after {
      margin-left: -125px;
    }
  }
`

const ErrorMessage = styled.p`
  text-weight: 600;
  color: darkred;
`

const customModalStyle = {
  overlay: {
    position: 'fixed',
  },
}

class CollaboratorsModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      errorMessage: '',
      inviteEmail: '',
    }

    this.inviteUsers = this.inviteUsers.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }
  inviteUsers() {
    const emails = this.state.inviteEmail.split(',')
    const invitePromises = emails.map(
        email => this.props.inviteUserToShortlist(this.props.shortlist, email)
    )

    this.setState({errorMessage: ''})

    Promise.all(invitePromises)
        .then(() => {
          this.setState({inviteEmail: ''})
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
              this.setState({errorMessage: error.response.data.message})
          }
        })
  }
  closeModal() {
    this.setState({inviteUser: ''})
    this.props.closeModal()
  }
  render() {
    Modal.setAppElement('body')

    return (
        <Modal style={customModalStyle} isOpen={this.props.isOpen}>
          <ModalCloseButton style={{float: 'right'}} onClick={this.closeModal} />
          <ModalHeader>
            <h3>Collaborators</h3>
          </ModalHeader>
          <p>Invite others to view or help with your shortlist</p>
          {this.state.errorMessage.length > 0 && <ErrorMessage>{this.state.errorMessage}</ErrorMessage>}
          <Label style={{marginTop: '2rem'}}>
            Invitee Email Address
            <Input
                data-tip="Separate multiple invitees with a comma"
                value={this.state.inviteEmail}
                onChange={event => this.setState({inviteEmail: event.target.value})}
            />
          </Label>

          {typeof this.props.shortlist !== 'undefined' && this.props.shortlist.users
              .filter(shortlistUser => shortlistUser.user.id !== this.props.currentUser.id)
              .map(shortlistUser =>
                  <div key={shortlistUser.id} style={{margin: '1rem 0'}}>
                    <Invitee
                        email={shortlistUser.user.name}
                        removeInvitee={() => this.props.removeUserFromShortlist(this.props.shortlist, shortlistUser.id)}
                    />
                  </div>
              )
          }

          {typeof this.props.shortlist !== 'undefined' && this.props.shortlist.invitations
              .map(invitation =>
                  <div key={invitation.id} style={{margin: '1rem 0'}}>
                    <Invitee
                        email={invitation.email}
                        removeInvitee={() => this.props.removeInvitationFromShortlist(this.props.shortlist, invitation.id)}
                    />
                  </div>
              )
          }

          <Button
              colour="primary"
              style={{width: '100%', marginTop: '1rem'}}
              onClick={this.inviteUsers}
          >
            Invite Collaborator{this.state.inviteEmail.split(',').length > 1 ? 's' : ''}
          </Button>
          <TooltipContainer>
            <ReactTooltip place="bottom" type="dark" effect="solid" />
          </TooltipContainer>
        </Modal>
    )
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user,
  shortlist: state.shortlists.current.shortlist,
})

const mapDispatchToProps = dispatch => ({
  inviteUserToShortlist: (shortlist, email) => dispatch(inviteUserToShortlist(shortlist, email)),
  removeUserFromShortlist: (shortlist, shortlistUserId) => dispatch(removeUserFromShortlist(shortlist, shortlistUserId)),
  removeInvitationFromShortlist: (shortlist, invitationId) => dispatch(removeInvitationFromShortlist(shortlist, invitationId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CollaboratorsModal)
