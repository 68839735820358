import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import addProductToCurrentShortlist from '../../actions/add-product-to-current-shortlist'
import removeProductFromCurrentShortlist from '../../actions/remove-product-from-current-shortlist'

const Button = styled.button`
  background: ${props => props.selected ? '#E6E8FC' : '#fff'};
  color: ${props => props.selected ? '#3040E1' : '#161616'};
  border: 1px solid ${props => props.selected ? '#E6E8FC' : '#ebebeb'};
  padding: 0.5rem 1rem;
  border-radius: 12px;
  font-weight: 500;

  &:focus {
    outline: 0;
  }

  &:hover {
    background: #3040e1;
    color: #fff;
    border: 1px solid #3040e1;
  }
`

class GuestCompareButton extends Component {
  constructor(props) {
    super(props)

    this.state = {}

    this.selectCompare = this.selectCompare.bind(this)
    this.productIsAdded = this.productIsAdded.bind(this)
    this.getProductId = this.getProductId.bind(this)
  }
  productIsAdded() {
    const product = this.props.shortlistProducts.find(product => (
        product.product.slug === this.props.productSlug
    ))

    return product !== undefined
  }
  getProductId() {
    const product = this.props.shortlistProducts.find(product => (
        product.product.slug === this.props.productSlug
    ))

    if (product !== undefined) {
      return product.id
    }

    return undefined
  }
  selectCompare() {
    if (this.productIsAdded() === false) {
      this.props.addProductToCurrentShortlist(this.props.productSlug)
    }

    if (this.productIsAdded()) {
      this.props.removeProductFromCurrentShortlist(this.getProductId())
    }
  }
  render() {
    return (
      <div>
        <Button
          selected={this.productIsAdded()}
          onClick={() => this.selectCompare()}
        >
          {this.props.buttonText}
        </Button>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  shortlistProducts: state.shortlists.current.products,
})

const mapDispatchToProps = dispatch => ({
  addProductToCurrentShortlist: productSlug => dispatch(addProductToCurrentShortlist(productSlug)),
  removeProductFromCurrentShortlist: productId => dispatch(removeProductFromCurrentShortlist(productId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(GuestCompareButton)
