import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Feature from "./Feature";
import { deleteLeadInformation, loadLeadInformation, saveLeadInformation} from "../../storage";

const Container = styled.div`
    max-width: 1100px;
    margin: auto;
    padding: 80px 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    color: #161616;
`;

const Form = styled.form`
    [disabled] {
        opacity: 0.5;
        pointer-events: none;
    }
`;

const FieldsetSplitter = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
`;

const Fieldset = styled.fieldset`
    margin-top: 15px;
    display: flex;
    flex-direction: column;
`;

const Label = styled.label`
    margin: 0;
    user-select: none;
`;

const Input = styled.input`
    margin: 0;
    padding: 10px;
    border: 1px solid #a0a0a0;
    border-radius: 10px;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    
    &::placeholder {
        color: #a0a0a0;
    }
`;

const ButtonContainer = styled.div`
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 20px;
`;

const Button = styled.button`
    margin: 0;
    height: 50px;
    padding: 10px;
    border: 0;
    border-radius: 10px;
    font-family: inherit;
    font-size: inherit;
    font-weight: 700;
    user-select: none;
    color: #ffffff;
    background: #3040e1;
`;

const Spacer = styled.div`
    height: 100px;
`;

const Footnote = styled.p`
    margin: 20px 0;
    font-size: 12px;
`;

const Link = styled.a`
  color: #3040e1;
`;

function LeadCaptureForm({ whenCompleted }) {
  const formElement = React.useRef()
  const nameInput = React.useRef()
  const emailInput = React.useRef()
  const companyInput = React.useRef()
  const contactNumberInput = React.useRef()

  const saveFormValues = () => {
    const data = {
      name: nameInput.current.value,
      email: emailInput.current.value,
      company: companyInput.current.value,
      contactNumber: contactNumberInput.current.value,
    }

    saveLeadInformation(data)
  }

  const loadFormValues = () => {
    const data = loadLeadInformation()

    nameInput.current.value = data.name
    emailInput.current.value = data.email
    companyInput.current.value = data.company
    contactNumberInput.current.value = data.work_contact_number
  }

  React.useEffect(loadFormValues, [])

  /**
   * @param {React.FocusEvent<HTMLInputElement>} event
   */
  const cleanInputValue = (event) => {
    const input = event.currentTarget

    input.value = input.value.trim().replaceAll(/\s{2,}/g, ' ')

    if (input === contactNumberInput.current) {
      input.value = input.value.replaceAll(/\s/g, '')
    }
  }

  /**
   * @param {React.FormEvent<HTMLInputElement>} event
   */
  const clearInputValidationMessage = (event) => {
    event.currentTarget.setCustomValidity("")
  }

  /**
   * @param {React.FormEvent<HTMLFormElement>} event
   */
  const clearForm = (event) => {
    event.preventDefault()

    nameInput.current.value = ''
    emailInput.current.value = ''
    companyInput.current.value = ''
    contactNumberInput.current.value = ''

    deleteLeadInformation()
  }

  /**
   * @param {React.FormEvent<HTMLFormElement>} event
   */
  const submitForm = (event) => {
    event.preventDefault()

    const name = nameInput.current.value
    const contactNumber = contactNumberInput.current.value

    // Make sure the full name contains at least one space
    if (/\s+/.test(name) === false) {
      nameInput.current.setCustomValidity("Please provide your full name")
      nameInput.current.reportValidity()
      return
    }

    // Make sure the contact number resembles a phone number
    if (/^\+?[0-9\s\-()]{7,}$/.test(contactNumber) === false) {
      contactNumberInput.current.setCustomValidity("Please provide a valid phone number")
      contactNumberInput.current.reportValidity()
      return
    }

    saveFormValues()

    const form = event.currentTarget

    form.style.opacity = "0.5";
    form.style.pointerEvents = "none";

    const data = new FormData(form)
    const request = fetch("/api/session/lead-capture-form", {
      method: "POST",
      body: data
    })

    request.then((response) => response.json()).then((data) => {
      if (data.success === true) {
        whenCompleted()
      }
    })
  }

  return <Container>
    <Feature />
    <Form ref={formElement} autoCapitalize="off" autoComplete="off" onSubmit={submitForm}>
      <Spacer />
      <Fieldset>
        <Label>Full Name</Label>
        <Input
          ref={nameInput}
          name="name"
          type="text"
          placeholder="Enter your name"
          required
          onBlur={cleanInputValue}
          onInput={clearInputValidationMessage}
        />
      </Fieldset>
      <Fieldset>
        <Label>Email</Label>
        <Input
          ref={emailInput}
          name="email"
          type="email"
          placeholder="you@email.com"
          required
          onBlur={cleanInputValue}
          onInput={clearInputValidationMessage}
        />
      </Fieldset>
      <FieldsetSplitter>
        <Fieldset>
          <Label>Company</Label>
          <Input
            ref={companyInput}
            name="company"
            type="text"
            placeholder="Company name"
            required
            onBlur={cleanInputValue}
            onInput={clearInputValidationMessage}
          />
        </Fieldset>
        <Fieldset>
          <Label>Work Contact No.</Label>
          <Input
            ref={contactNumberInput}
            name="work_contact_number"
            type="tel"
            placeholder="+1 234 567 8900"
            required
            onBlur={cleanInputValue}
            onInput={clearInputValidationMessage}
          />
        </Fieldset>
      </FieldsetSplitter>
      <Footnote>
        For information on our data policies, see our <Link href="/terms">Terms of Service</Link> and <Link href="/privacy">Privacy Policy</Link>
      </Footnote>
      <ButtonContainer>
        <Button onClick={clearForm}>Clear</Button>
        <Button>Save and Compare</Button>
      </ButtonContainer>
      <Input name="session_id" type="hidden" defaultValue={window.session_id} />
    </Form>
  </Container>
}

LeadCaptureForm.propTypes = {
  whenCompleted: PropTypes.func.isRequired
}

export default LeadCaptureForm
