import React, { Component, Fragment } from 'react'
import ReactSVG from 'react-svg'
import {
  Container,
  Row,
  Col,
  NavbarBrand,
  Nav as BSNav
} from 'reactstrap'
import styled from 'styled-components'
import { mediaBreakpointDownSm } from 'styled-bootstrap-responsive-breakpoints'

import Navbar from './Navbar'
import NavbarToggler from './NavbarToggler'
import NavLink from './NavLink'
import NavItem from './NavItem'
import CustomDropdownMenu from './CustomDropdownMenu'
import SubNavItem from './SubNavItem'
import SearchButton from './SearchButton'
import SearchInput from './SearchInput'
import CancelButton from './CancelButton'
import Button from './CallToActionButton'
import MobileNav from './MobileNav'
import Dropdown from '../shortlist-dropdown/Dropdown'

import { isGuest, isAuthenticated } from '../../helpers'

import HamburgerIconPath from '../../svg/new-hamburger.svg'

const PlainHamburgerIcon = (props) => (
  <ReactSVG src={HamburgerIconPath} {...props} wrapper="span" />
)

const NavButton = styled.button`
  text-decoration: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: ${props => props.highlight ? '#3040E1' : '#161616'} !important;
  display: flex;
  align-items: center;
  font-family: "HK Grotesk";
  border-bottom: none;
  border-top: none;
  border-left: none;
  border-right: none;
  padding-top: 0.5em;
  background-color: transparent;
`

const HamburgerIcon = styled(PlainHamburgerIcon)`
  svg {
    height: 1.3rem;
  }
`

const BrandLogo = styled.img`
  width: 8.5rem;

  ${mediaBreakpointDownSm`
    width: 6.5rem;
  `}
`

const ExpandedContents = styled.div`
  width: 100vw;
  position: absolute;
  left: calc(-1 * (100vw - 100%) / 2);
  background: #fff;
  z-index: 2;
  margin-top: 0.4rem;
  padding: 2rem 0;
`

const AuthContainer = styled.div`
  display: flex;
  margin-left: 1rem;
`

export default class Nav extends Component {
  constructor(props) {
    super(props);

    this.categories = window.navCategories
    this.navItems = window.primaryNavigation
    this.toggleNav = this.toggleNav.bind(this)
    this.toggleSearch = this.toggleSearch.bind(this)
    this.state = {
      isNavOpen: false,
      isSearchOpen: false,
      query: props.searchQuery,
    }
  }
  toggleSearch() {
    this.setState({
      isNavOpen: false,
      isSearchOpen: !this.state.isSearchOpen
    })
  }
  toggleNav() {
    this.setState({
      isNavOpen: !this.state.isNavOpen
    })
  }
  componentDidMount() {
    document.querySelector('.l-sp-footer-wrapper').style.display = 'block';
  }
  handleQueryChange(event) {
    this.setState({
      query: event.target.value,
    })
  }

  render() {
    let showAccountButtons = false;
    let showDropdown = location.pathname.startsWith("/shortlist") === false;
    let loginElement = null;
    let logoutElement = null;
    let dropdownElement = null;

    if (showAccountButtons && isGuest()) {
      loginElement = (
        <AuthContainer>
          <NavItem>
            <NavLink href="/login" highlight={1}>Log In</NavLink>
          </NavItem>
          <NavItem>
            <Button onClick={() => window.location = '/register'} >Sign up</Button>
          </NavItem>
        </AuthContainer>
      );
    }

    if (showAccountButtons && isGuest() === false) {
      logoutElement = (
        <NavItem>
          <form action="/logout" method="post">
            <NavButton type="submit">Log Out</NavButton>
          </form>
        </NavItem>
      );
    }

    if (showDropdown) {
      dropdownElement = (
        <Dropdown hasProfilePicture={isAuthenticated()}></Dropdown>
      );
    }

    return (
      <Navbar
        expand="md"
        className="bg-custom"
        focused={+this.state.isSearchOpen}
        expanded={+this.state.isNavOpen}
      >
        <Container fluid={false}>
          {!this.state.isSearchOpen && (
            <NavbarBrand href="/">
              <BrandLogo src="/images/Logo/Default/Default.svg" alt="Software Path" />
            </NavbarBrand>
          )}

          <div className="mr-auto ml-3 d-none d-md-flex d-lg-flex d-xl-flex">
            <form method="get" action="/search">
              <SearchInput onChange={(event) => this.handleQueryChange(event)} placeholder="Search" name="q" value={this.state.query} style={{ width: '15rem' }} />
            </form>
          </div>

          <div className="d-flex flex-grow-1 d-md-none d-lg-none d-xl-none">
            {this.state.isSearchOpen && (
              <Fragment>
                <form className="d-flex flex-grow-1" method="get" action="/search">
                  <SearchInput onChange={(event) => this.handleQueryChange(event)} placeholder="Search" value={this.state.query}  name="q" />
                </form>
                <CancelButton onClick={this.toggleSearch}>Cancel</CancelButton>
              </Fragment>
            )}

            {!this.state.isSearchOpen && (
              <Fragment>
                <SearchButton onClick={this.toggleSearch} />

                <Dropdown hasProfilePicture={isAuthenticated()}></Dropdown>

                <NavbarToggler onClick={this.toggleNav}>
                  <HamburgerIcon></HamburgerIcon>
                </NavbarToggler>
              </Fragment>
            )}
          </div>

          <MobileNav isOpen={this.state.isNavOpen}></MobileNav>
          <BSNav className="ml-auto d-none d-sm-none d-md-flex" navbar>
            <CustomDropdownMenu>
              <ExpandedContents>
                <Container>
                  <Row>
                    {this.categories.map((categoryCol, index) => (
                      <Col key={index}>
                        {Object.values(categoryCol).map((category, index) => (
                          <SubNavItem
                            key={index}
                            href={`/${category.slug}`}
                            highlight={category.highlight !== undefined ? category.highlight : false}
                          >
                            {category.name}
                          </SubNavItem>
                        ))}
                      </Col>
                    ))}
                  </Row>
                </Container>
              </ExpandedContents>
            </CustomDropdownMenu>
              {this.navItems.map((item) => (
                <NavItem key={item.title}>
                    <NavLink href={`${item.path}`} title={item.title}>{item.name}</NavLink>
                </NavItem>
              ))}
            {logoutElement}
            {loginElement}
            {dropdownElement}
          </BSNav>
        </Container>
      </Navbar>
    )
  }
}
