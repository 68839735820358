import { isAuthenticated, isGuest } from '../../helpers'
import GuestCompareButton from "./GuestCompareButton"
import React from "react"
import ShortlistPicker from "./ShortlistPicker";

const CompareButton = props => {
  const buttonText = props.hasOwnProperty('buttonText') ? props.buttonText : 'Compare';

  return (<div>
    {isGuest() && <GuestCompareButton productSlug={props.productSlug} buttonText={buttonText} />}
    {isAuthenticated() && <ShortlistPicker isCompareButton={true} productSlug={props.productSlug} buttonText={buttonText} />}
  </div>);x
};

export default CompareButton;
