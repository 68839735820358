import React from 'react'
import styled from 'styled-components'
import ReactSVG from 'react-svg'

import CrossPath from '../../svg/new-feature-cross.svg'

const CrossReactIcon = (props) => (
  <ReactSVG src={CrossPath} wrapper="span" {...props} />
)

const CrossIcon = styled(CrossReactIcon)`
  svg path {
    fill: #bbbbbb;
  }
`

export default CrossIcon
