import styled from 'styled-components'

const CellContainer = styled.div`
  background: ${(props => props.featured ? '#f5faf9' : '#f8f8f8')};
  margin: 2px;
  text-align: center;
  padding: 0.625rem 0;
  min-height: 2.75rem;
  height: auto;
  ${props => props.fixedHeight ? 'max-height: 2.75rem;' : null}
  ${props => props.minHeight ? 'min-height: ' +  props.minHeight : null}
  ${props => props.firstItem ? 'border-radius: 8px 0 0 8px;' : null}
  ${props => props.lastItem ? 'border-radius: 0 8px 8px 0;' : null}
`

CellContainer.defaultProps = {
  fixedHeight: false,
}

export default CellContainer
