import React, { Component } from 'react'
import styled from 'styled-components'
import { Container as BootstrapContainer } from 'reactstrap'
import Axios from 'axios';
import { parse, format } from 'date-fns'

import InfiniteContainer from '../components/common/InfiniteContainer'
import Product from '../components/shortlists/Product'
import ShortlistModal from '../components/shortlists/ShortlistModal'
import Button from '../components/common/Button'

const Container = styled(BootstrapContainer)`
  display: flex;
`

const ShortlistButton = styled(Button)`
  padding: 0.75rem 1.25rem;
  margin: 0;
`

const Heading = styled.h1`
  font-size: 1.75rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin: 0;
`

const ShortlistContainer = styled.div`
  border-bottom: 1px solid #EBEBEB;
  display: flex;
  padding: 2.5rem 0;
  overflow: hidden;

  &:first-child {
    padding-top: 0;
  }
`

const ListingsContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-grow: 1;
  min-width: 100%;
  min-height: 100%;
`

const CompareButton = styled(Button)`
  width: auto;
  padding: 0.5rem 1rem;
  
  &:hover {
    background: #3040e1;
    color: #fff;
  }
`

const ShortlistTitle = styled.h3`
  font-family: "HK Grotesk";
  font-size: 1.25rem;
  color: #161616;
`

const ShortlistMeta = styled.div`
  font-size: 0.75rem;
  font-family: "HK Grotesk";
`

class ShortlistListing extends Component {
  constructor(props) {
    super(props)

    this.goToShortlist = this.goToShortlist.bind(this)
    this.deleteProduct = this.deleteProduct.bind(this)
  }
  goToShortlist() {
    window.location.href = `/shortlists/${this.props.id}`
  }
  deleteProduct(shortlistId, productId) {
    this.props.deleteProduct(shortlistId, productId)
  }
  render() {
    return (
      <ShortlistContainer>
        <div style={{ minWidth: '15rem' }}>
          <ShortlistTitle>{this.props.name}</ShortlistTitle>
          <ShortlistMeta>{this.props.products.length} products</ShortlistMeta>
          <ShortlistMeta>Last updated {format(parse(this.props.updatedAt), 'D MMMM, YY')}</ShortlistMeta>

          <CompareButton className="mt-3" onClick={() => this.goToShortlist()}>Compare List</CompareButton>
        </div>
        <div style={{ overflowX: 'auto' }}>
          <ListingsContainer>
            {this.props.products.map(product => (
              <Product 
                key={product.id}
                shortlistId={this.props.id}
                id={product.id}
                product={product.product} 
                deleteProduct={this.deleteProduct} 
              />
            ))}
          </ListingsContainer>
        </div>
      </ShortlistContainer>
    )
  }
}

export default class Shortlists extends Component {
  constructor(props) {
    super(props)

    this.state = {
      shortlists: [],
      shortlistModal: false,
    }
    
    this.deleteProduct = this.deleteProduct.bind(this)
    this.openShortlistModal = this.openShortlistModal.bind(this)
    this.closeShortlistModal = this.closeShortlistModal.bind(this)
  }
  openShortlistModal() {
    this.setState({ shortlistModal: true })
  }
  closeShortlistModal() {
    this.setState({ shortlistModal: false })
  }
  deleteProduct(shortlistId, productId) {
    const shortlists = [ ...this.state.shortlists ]
    const shortlistIndex = shortlists.findIndex(shortlist => {
      return shortlistId === shortlist.id
    })
    const shortlist = {...this.state.shortlists[shortlistIndex]};
    const productIndex = shortlist.products.findIndex(product => productId === product.id)
    const product = shortlist.products[productIndex]

    Axios.delete(`/api/shortlists/${shortlist.id}/products/${product.id}`).then(() => {
      shortlist.products.splice(productIndex, 1)
      shortlists.splice(shortlistIndex, 1, shortlist)
  
      this.setState({ shortlists })
    })
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <Container>
          <Heading>My Shortlists</Heading>
          <div className="ml-auto">
            <ShortlistButton onClick={this.openShortlistModal}>
              Create New Shortlist
            </ShortlistButton>

            <ShortlistModal
              closeModal={this.closeShortlistModal}
              onRequestClose={this.closeShortlistModal}
              shouldCloseOnOverlayClick={true}            
              isOpen={this.state.shortlistModal}
              ariaHideApp={false}
            ></ShortlistModal>
          </div>
        </Container>

        <InfiniteContainer>
          {this.state.shortlists
            .map(shortlist => (
              <ShortlistListing
                key={shortlist.id}
                id={shortlist.id}
                name={shortlist.name}
                products={shortlist.products}
                updatedAt={shortlist.updated_at}
                deleteProduct={this.deleteProduct}
              />
            ))
          }
        </InfiniteContainer>
      </div>
    )
  }
}
