import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import SearchSection from '../components/home/sections/SearchSection'
import SliderSection from '../components/home/sections/SliderSection'
import CategoriesSection from '../components/home/sections/CategoriesSection'
import TreeSection from '../components/home/sections/TreeSection'
import TrendingToolsSection from '../components/home/sections/TrendingToolsSection'

const Home = ({data, trendingTools}) => (
  <div>
    <SearchSection icons={data.company_icons} />

    <SliderSection featureText={data.feature_text}/>

    <CategoriesSection selectedCategories={data.selected_categories} />

    <TreeSection treeCount={data.tree_count} />

    <TrendingToolsSection trendingProducts={trendingProducts} />
  </div>
)

export default Home
