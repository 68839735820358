import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Button from '../../components/common/Button'

import { connect } from 'react-redux'
import addProductToCurrentShortlist from '../../actions/add-product-to-current-shortlist'
import removeProductFromCurrentShortlist from '../../actions/remove-product-from-current-shortlist'

class GuestShortlistButton extends Component {
  constructor(props) {
    super(props)

    this.state = {}

    this.selectShortlist = this.selectShortlist.bind(this)
    this.productIsAdded = this.productIsAdded.bind(this)
    this.getProductId = this.getProductId.bind(this)
  }
  productIsAdded() {
    const product = this.props.shortlistProducts.find(product => (
        product.product.slug === this.props.productSlug
    ))

    return product !== undefined
  }
  getProductId() {
    const product = this.props.shortlistProducts.find(product => (
        product.product.slug === this.props.productSlug
    ))

    if (product !== undefined) {
      return product.id
    }

    return undefined
  }
  selectShortlist() {
    if (this.productIsAdded() === false) {
      this.props.addProductToCurrentShortlist(this.props.productSlug)
    } else {
      this.props.removeProductFromCurrentShortlist(this.getProductId())
    }
  }
  render() {
    return (
      <div>
        <Button
          primary={true}
          onClick={() => this.selectShortlist()}
          disabled={this.props.disabled}
        >
          {this.productIsAdded() && 'Added to Shortlist'}
          {!this.productIsAdded() && 'Add to Shortlist'}
        </Button>
      </div>
    )
  }
}

GuestShortlistButton.propTypes = {
  disabled: PropTypes.bool,
}

GuestShortlistButton.defaultProps = {
  disabled: false,
}

const mapStateToProps = state => ({
  shortlistProducts: state.shortlists.current.products,
})

const mapDispatchToProps = dispatch => ({
  addProductToCurrentShortlist: productSlug => dispatch(addProductToCurrentShortlist(productSlug)),
  removeProductFromCurrentShortlist: productId => dispatch(removeProductFromCurrentShortlist(productId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(GuestShortlistButton)
