import React, { Component } from 'react'
import styled from 'styled-components'
import ReactSVG from 'react-svg'
import range from 'lodash/range'

import StarIcon from '../../icons/StarIcon'

export default class Rating extends Component {
  render() {
    const rating = range(0, Math.round(this.props.rating))
    const emptyRating = range(0, 5 - Math.round(this.props.rating))

    return (
      <div>
        {rating.map((rating, index) => (
          <StarIcon
            key={index}
            fill={this.props.fill}
            height={this.props.height}
            width={this.props.width}
          />
        ))}
        {emptyRating.map((rating, index) => (
          <StarIcon
            key={index}
            height={this.props.height}
            width={this.props.width}
          />
        ))}
      </div>
    )
  }
}
