import React from 'react'
import ReactDOM from 'react-dom'
import 'react-picky/dist/picky.css';
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Home from './pages/Home';
import Listings from './pages/Listings';
import Rankings from './pages/Rankings';
import Result from './pages/Result';
import ResultSkeleton from './pages/ResultSkeleton';
import ResultErrorBoundary from './error-boundaries/ResultErrorBoundary';
import Register from './pages/Register';
import Login from './pages/Login';
import Reset from './pages/Reset';
import PasswordReset from './pages/PasswordReset';
import Shortlists from './pages/Shortlists';
import Shortlist from './pages/Shortlist';

import Dropdown from './components/shortlist-dropdown/Dropdown'
import Nav from './components/nav/Nav'
import CategoriesSearch from './components/categories/CategoriesSearch'
import ShortlistComponent from './components/shortlist-component/shortlist-component'

import { Provider } from 'react-redux'
import store from './store'
import getUser from './actions/get-user'
import getCurrentShortlist from './actions/get-current-shortlist'
import getShortlists from './actions/get-shortlists'

store.dispatch(getUser())
store.dispatch(getCurrentShortlist())
store.dispatch(getShortlists())

if (document.getElementById('home')) {
  ReactDOM.render(
      <Provider store={store}>
        <Home data={window.homeData.homepage} trendingProducts={window.trendingProducts} />
      </Provider>,
      document.getElementById('home')
  )
}

if (document.getElementById('listings')) {
  ReactDOM.render(
      <Provider store={store}>
        <Listings data={window.listingData} />
      </Provider>,
      document.getElementById('listings')
  )
}

if (document.getElementById('rankings')) {
  ReactDOM.render(
      <Provider store={store}>
        <Rankings data={window.rankings} category={window.category} filters={window.filters} categoryPages={window.categoryPages} />
      </Provider>,
      document.getElementById('rankings')
  )
}

if (document.getElementById('result')) {
  ReactDOM.render(
      <Provider store={store}><ResultErrorBoundary>
        {window.productData.features.length >= 5 ?
            <Result data={window.productData} /> :
            <ResultSkeleton data={window.productData} />
        }
        </ResultErrorBoundary>
      </Provider>,
      document.getElementById('result')
  )
}

if (document.getElementById('register')) {
  ReactDOM.render(<Register />, document.getElementById('register'))
}

if (document.getElementById('login')) {
  ReactDOM.render(<Login />, document.getElementById('login'))
}

if (document.getElementById('request-reset')) {
  ReactDOM.render(<Reset />, document.getElementById('request-reset'))
}

if (document.getElementById('password-reset')) {
  ReactDOM.render(<PasswordReset />, document.getElementById('password-reset'))
}

if (document.getElementById('shortlists')) {
  ReactDOM.render(<Shortlists />, document.getElementById('shortlists'))
}

if (document.getElementById('shortlist')) {
  ReactDOM.render(<Shortlist />, document.getElementById('shortlist'))
}

if (document.getElementById('shortlist-dropdown')) {
  ReactDOM.render(<Dropdown />, document.getElementById('shortlist-dropdown'))
}

if (document.getElementById('new-nav')) {
  let searchQuery = ''
  if (window.listingData && null !== window.listingData.query) {
    searchQuery = window.listingData.query
  }
  ReactDOM.render(
      <Provider store={store}>
        <Nav searchQuery={searchQuery} />
      </Provider>,
      document.getElementById('new-nav')
  )
}

if (document.getElementById('categories-search')) {
  ReactDOM.render(<CategoriesSearch />, document.getElementById('categories-search'))
}

if (document.getElementById('shortlist-component')) {
  ReactDOM.render(
      <Provider store={store}>
        <ShortlistComponent />
      </Provider>,
      document.getElementById('shortlist-component')
  )
}
