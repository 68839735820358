import React, { Component } from 'react'
import { Container } from 'reactstrap'
import styled from 'styled-components'
import Axios from 'axios';
import truncate from 'lodash/truncate'

import LeadCaptureForm from "../components/register/LeadCaptureForm";
import ShortlistItem from '../components/shortlist/ShortlistItem'
import SectionWrapper from '../components/shortlist/SectionWrapper'
import InfiniteContainer from '../components/common/InfiniteContainer'
import { loadFormVisibility, saveFormVisibility } from "../storage";

// If an element is not an array, wrap it in an array
const wrap = item => Array.isArray(item) ? item : [item];

const PageHeading = styled.div`
  display: flex;
  margin: 1.5rem 0;
`

const Title = styled.h1`
  font-size: 1.75rem;
`

const ShortlistContainer = styled.div`
  display: flex;
`

const TableHeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  min-width: 200px;
`

const SectionHeading = styled.h4`
  color: #161616;
  font-size: 14px;
  padding: 0.625rem 0;
  margin: 2px;
  height: 2.75rem;
  display: flex;
  align-items: center;
  ${props => props.minHeight ? 'min-height: ' +  props.minHeight : null}
`

const ShortlistSpacer = styled.div`
  height: 10rem;
`

const ListingContainer = styled.div`
  overflow-x: auto;
`

const ListingInnerContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-grow: 1;
`

const Link = styled.a`
  color: #3040e1;
`

export default class Shortlist extends Component {
  constructor(props) {
    super(props)

    this.state = {
      shortlist: {},
      meta: {},
      sectionHidden: {
        overview: false,
        pricing: false,
        features: false,
        support: false,
        compliance: false,
        deployment: false,
        industry: false,
        speciality: false,
        languages: false,
      },
      cellHeights: {
        operatingSystem: 24,
      },
      currentPricingPlans: [],
      formVisibility: loadFormVisibility()
    }

    this.toggleSection = this.toggleSection.bind(this)
    this.shouldDisplaySection = this.shouldDisplaySection.bind(this);
    this.shouldDisplayPricingSection = this.shouldDisplayPricingSection.bind(this);
    this.setCurrentPricingPlan = this.setCurrentPricingPlan.bind(this);
    this.updateCellHeight = this.updateCellHeight.bind(this);
  }
  componentDidMount() {
    // const shortlistId = window.shortlist.id

    Axios.get('/api/session/shortlist').then(response => {
      const responseData = response.data
      const shortlist = {
        products: responseData.data
      }
      const meta = responseData.meta

      for (let dataGroup in meta) {
        if (meta.hasOwnProperty(dataGroup)) {
          meta[dataGroup] = meta[dataGroup].sort((a, b) => a.name < b.name ? -1 : 1)
        }
      }

      this.setState({ shortlist, meta })
    })
  }
  toggleSection(section) {
    const sectionHidden = this.state.sectionHidden
    sectionHidden[section] = !sectionHidden[section]

    this.setState({ sectionHidden })
  }
  filterProductDataGroup(dataGroup, product) {
    const values = product.values;

    return [...this.state.meta[dataGroup]].map((value) => {
      return values.find(productValue => productValue.slug === value.slug) !== undefined
    })
  }
  filterProductDataGroups(dataGroups, product) {
    // Takes an array of data groups and returns the results from all of them passed to filterProductDataGroup
    // concatenated together
    return [].concat.apply([], dataGroups.map(dataGroup => this.filterProductDataGroup(dataGroup, product)));
  }
  shouldDisplaySection(dataGroups) {
    // If a single data group is passed put it into an array
    dataGroups = wrap(dataGroups);
    const numberOfItems = dataGroups.map(
        dataGroup => dataGroup in this.state.meta ? this.state.meta[dataGroup].length : 0
    ).reduce((item, sum) => item + sum);

    return numberOfItems > 0;
  }
  /**
   * Check whether a certain pricing field is present for any of the products
   */
  shouldDisplayPricingSection(section) {
    let currentPricingPlans = this
        .state
        .currentPricingPlans;

    if (Array.isArray(currentPricingPlans)) {
      return currentPricingPlans
        .filter(pricingPlan => null !== pricingPlan[section] && '' !== pricingPlan[section] && false !== pricingPlan[section])
        .length > 0;
    }

    return true;
  }
  /**
   *  Update an array of the pricing plans currently being displayed in the element state
   */
  setCurrentPricingPlan(productId, pricingPlan) {
    if (!productId || !pricingPlan) {
      return;
    }

    let currentPricingPlans = this.state.currentPricingPlans;
    if (!Array.isArray(currentPricingPlans)) {
      currentPricingPlans = [];
    }

    currentPricingPlans = currentPricingPlans.filter(pricingPlan => pricingPlan.productId !== productId);

    currentPricingPlans.push(Object.assign({}, pricingPlan, {
      productId,
    }));

    this.setState({currentPricingPlans});
  }
  updateCellHeight(cellName, height) {
    if (height > this.state.cellHeights[cellName]) {
      let cellHeights = Object.assign({}, this.state.cellHeights);
      cellHeights[cellName] = height;

      this.setState({cellHeights});
    }
  }
  render() {
    if (this.state.formVisibility.showLeadCapture) {
      const whenCompleted = () => {
        saveFormVisibility({ showLeadCapture: false })
        this.setState({ formVisibility: loadFormVisibility() })
      }

      return <LeadCaptureForm whenCompleted={whenCompleted} />
    }

    // Set whether certain pricing fields should be hidden
    let pricingBlank = false;
    let productsWithPlans = [];
    if (this.state.shortlist.products) {
      productsWithPlans = this.state.shortlist.products.filter(product => product.product.pricing_plans.length > 0);
    }
    if (productsWithPlans.length < 1) {
      pricingBlank = true;
    }

    // Set whether review volume should be hidden
    let reviewVolumeBlank = false;
    let productsWithAverageReviews = [];
    if (this.state.shortlist.products) {
      productsWithAverageReviews = this.state.shortlist.products.filter(product => product.shortlist_data.average_reviews != 'N/A');
    }
    if (productsWithAverageReviews.length < 1) {
      reviewVolumeBlank = true;
    }

    // Set whether operating systems should be hidden
    let operatingSystemsBlank = false;
    let productsWithOperatingSystems = [];
    if (this.state.shortlist.products) {
      productsWithOperatingSystems = this.state.shortlist.products.filter(product => product.shortlist_data.operating_systems.length > 0);
    }
    if (productsWithOperatingSystems.length < 1) {
      operatingSystemsBlank = true;
    }

    return (
      <div>
        <Container>
          <PageHeading>
            <Title>Product Shortlist</Title>
          </PageHeading>
          <p>Have a look at our <Link href="/categories" style={{ color: "#3040e1"}}>product categories</Link> if you would like to compare more!</p>
        </Container>
        <InfiniteContainer>
          <ShortlistContainer className="mt-4">
            <TableHeadingContainer>
              <ShortlistSpacer></ShortlistSpacer>
              <SectionWrapper
                heading="Overview"
                hidden={this.state.sectionHidden.overview}
                section="overview"
                onClick={this.toggleSection}
              >
                <SectionHeading>Verified Profile</SectionHeading>
                <SectionHeading>Average Reviews</SectionHeading>
                {!reviewVolumeBlank && <SectionHeading>Review Volume</SectionHeading>}
                {!operatingSystemsBlank && <SectionHeading minHeight={`${this.state.cellHeights.operatingSystem}px`}>Operating Systems</SectionHeading>}
                <SectionHeading>Overall Ranking</SectionHeading>
                <SectionHeading>Popularity</SectionHeading>
                <SectionHeading>Reviews</SectionHeading>
                <SectionHeading>Company</SectionHeading>
              </SectionWrapper>

              <SectionWrapper
                heading="Pricing"
                hidden={this.state.sectionHidden.pricing}
                section="pricing"
                onClick={this.toggleSection}
              >
                <SectionHeading style={{ height: '3.5rem' }}>Pricing Plans</SectionHeading>
                <SectionHeading>Free Trial</SectionHeading>
                {!pricingBlank && <SectionHeading>Pricing Model</SectionHeading>}
                {!pricingBlank && <SectionHeading>Price</SectionHeading>}
                {!pricingBlank && <SectionHeading>Price Per</SectionHeading>}
                {!pricingBlank && this.shouldDisplayPricingSection('extra_fee') && <SectionHeading>Extra Fee</SectionHeading>}
                {!pricingBlank && this.shouldDisplayPricingSection('extra_fee_per') && <SectionHeading>Extra Fee Per</SectionHeading>}
                {!pricingBlank && this.shouldDisplayPricingSection('annual_discount') && <SectionHeading>Annual Discount?</SectionHeading>}
                {!pricingBlank && this.shouldDisplayPricingSection('from_plan') && <SectionHeading>From Plan</SectionHeading>}
                {!pricingBlank && this.shouldDisplayPricingSection('minimum_cost') && <SectionHeading>Minimum Cost</SectionHeading>}
                {!pricingBlank && this.shouldDisplayPricingSection('user_min') && <SectionHeading>User min</SectionHeading>}
                {!pricingBlank && this.shouldDisplayPricingSection('user_max') && <SectionHeading>User max</SectionHeading>}
              </SectionWrapper>

              { this.shouldDisplaySection('features') && <SectionWrapper
                heading="Features"
                hidden={this.state.sectionHidden.features}
                section="features"
                onClick={this.toggleSection}
              >
                {this.state.meta.features && this.state.meta.features.map((feature, index) => (
                  <SectionHeading key={index}>{truncate(feature.name, {
                    'length': 20,
                    'separator': '&hellip;'
                  })}</SectionHeading>
                ))}
              </SectionWrapper>}

              { this.shouldDisplaySection('support') && <SectionWrapper
                  heading="Support"
                  hidden={this.state.sectionHidden.support}
                  section="support"
                  onClick={this.toggleSection}
              >
                {this.state.meta.support && this.state.meta.support.map((support, index) => (
                    <SectionHeading key={index}>{truncate(support.name, {
                      'length': 20,
                      'separator': '&hellip;'
                    })}</SectionHeading>
                ))}
              </SectionWrapper>}

              { this.shouldDisplaySection('compliance') && <SectionWrapper
                  heading="Compliance"
                  hidden={this.state.sectionHidden.compliance}
                  section="compliance"
                  onClick={this.toggleSection}
              >
                {this.state.meta.compliance && this.state.meta.compliance.map((compliance, index) => (
                    <SectionHeading key={index}>{truncate(compliance.name, {
                      'length': 20,
                      'separator': '&hellip;'
                    })}</SectionHeading>
                ))}
              </SectionWrapper>}

              { this.shouldDisplaySection(['system_hosting', 'platform', 'delivery_platform']) && <SectionWrapper
                  heading="Deployment"
                  hidden={this.state.sectionHidden.deployment}
                  section="deployment"
                  onClick={this.toggleSection}
              >
                {this.state.meta.system_hosting && this.state.meta.system_hosting.map((system_hosting, index) => (
                    <SectionHeading key={index}>{truncate(system_hosting.name, {
                      'length': 20,
                      'separator': '&hellip;'
                    })}</SectionHeading>
                ))}
                {this.state.meta.platform && this.state.meta.platform.map((platform, index) => (
                    <SectionHeading key={index}>{truncate(platform.name, {
                      'length': 20,
                      'separator': '&hellip;'
                    })}</SectionHeading>
                ))}
                {this.state.meta.delivery_platform && this.state.meta.delivery_platform.map((delivery_platform, index) => (
                    <SectionHeading key={index}>{truncate(delivery_platform.name, {
                      'length': 20,
                      'separator': '&hellip;'
                    })}</SectionHeading>
                ))}
              </SectionWrapper>}

              { this.shouldDisplaySection('industry') && <SectionWrapper
                  heading="Industry"
                  hidden={this.state.sectionHidden.industry}
                  section="industry"
                  onClick={this.toggleSection}
              >
                {this.state.meta.industry && this.state.meta.industry.map((industry, index) => (
                    <SectionHeading key={index}>{truncate(industry.name, {
                      'length': 20,
                      'separator': '&hellip;'
                    })}</SectionHeading>
                ))}
              </SectionWrapper>}

              { this.shouldDisplaySection('specialty') && <SectionWrapper
                  heading="Speciality"
                  hidden={this.state.sectionHidden.specialty}
                  section="speciality"
                  onClick={this.toggleSection}
              >
                {this.state.meta.specialty && this.state.meta.specialty.map((specialty, index) => (
                    <SectionHeading key={index}>{truncate(specialty.name, {
                      'length': 20,
                      'separator': '&hellip;'
                    })}</SectionHeading>
                ))}
              </SectionWrapper>}
            </TableHeadingContainer>
            <ListingContainer>
              <ListingInnerContainer>
                {this.state.shortlist.products && this.state.shortlist.products.map((product, index) => {
                  /*showElement is separate from hideSection flags because it allows whitespace for the section title*/
                  /* These flags completely remove the section when there is no data*/
                  return (
                      <ShortlistItem
                          updateCellHeight={this.updateCellHeight}
                          cellHeights={this.state.cellHeights}
                          key={index}
                          featured={index === 0}
                          showReviews={false}
                          rating={5}
                          reviewCount={10}
                          reviewVolumeBlank={reviewVolumeBlank}
                          verified={product.product.verified}
                          productId={product.id}
                          name={product.product.name}
                          slug={product.product.slug}
                          image={product.product.display_logo}
                          plans={product.product.pricing_plans}
                          pricingBlank={pricingBlank}
                          trial={product.product.free_trial}
                          features={this.filterProductDataGroup('features', product)}
                          support={this.filterProductDataGroup('support', product)}
                          compliance={this.filterProductDataGroup('compliance', product)}
                          industry={this.filterProductDataGroup('industry', product)}
                          specialty={this.filterProductDataGroup('specialty', product)}
                          deployment={this.filterProductDataGroups(['system_hosting', 'platform', 'delivery_platform'], product)}
                          firstItem={index === 0}
                          lastItem={this.state.shortlist.products.length === index + 1}
                          hideOverview={this.state.sectionHidden.overview}
                          hidePricing={this.state.sectionHidden.pricing}
                          hideFeatures={this.state.sectionHidden.features}
                          hideIndustry={this.state.sectionHidden.industry}
                          hideSupport={this.state.sectionHidden.support}
                          hideCompliance={this.state.sectionHidden.compliance}
                          hideDeployment={this.state.sectionHidden.deployment}
                          hideSpecialty={this.state.sectionHidden.speciality}
                          hideLanguages={this.state.sectionHidden.languages}

                          showFeatures={this.shouldDisplaySection('features')}
                          showSupport={this.shouldDisplaySection('support')}
                          showCompliance={this.shouldDisplaySection('compliance')}
                          showDeployment={this.shouldDisplaySection(['system_hosting', 'platform', 'delivery_platform'])}
                          showIndustry={this.shouldDisplaySection('industry')}
                          showSpecialty={this.shouldDisplaySection('specialty')}
                          showLanguages={true}

                          setCurrentPricingPlan={this.setCurrentPricingPlan}
                          shouldDisplayPricingSection={this.shouldDisplayPricingSection}

                          averageReviews={product.shortlist_data.average_reviews}
                          reviewVolume={product.shortlist_data.review_volume}
                          operatingSystems={product.shortlist_data.operating_systems.map(os => os.name).join(', ')}
                          operatingSystemsBlank={operatingSystemsBlank}
                          rankings={product.product.ranking}
                      />
                    );
                })}
              </ListingInnerContainer>
            </ListingContainer>
          </ShortlistContainer>
        </InfiniteContainer>
      </div>
    )
  }
}
