import Axios from "axios";
import getShortlists from './get-shortlists'
import store from '../store'

export const SHORTLIST_RENAMED = 'SHORTLIST_RENAMED'

const renameShortlist = (shortlist, newName) => (
    dispatch => {
      return Axios.put(`/api/shortlists/${shortlist.id}`, {
        ...shortlist,
        name: newName,
      }).then(response => {
        const shortlist = response.data.data
        dispatch({
          type: SHORTLIST_RENAMED,
          shortlist,
        })

        return shortlist
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          store.dispatch(getShortlists())
        }
      })
    }
)

export default renameShortlist
