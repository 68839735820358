import React, { Component } from 'react'
import styled from 'styled-components'
import ReactModal from 'react-modal';
import validator from 'email-validator'

import Button from '../common/Button'
import Label from '../register/Label'
import Input from '../register/Input'
import InputGroup from '../register/InputGroup'

import Invitee from './Invitee'
import Axios from 'axios';

const Heading = styled.h3`
  font-family: "HK Grotesk";
  padding-bottom: 1.25rem;
`

const Instructions = styled.p`
  color: #585858;
  font-family: "HK Grotesk";
`

const Rule = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  border-top: 1px solid #EBEBEB;
`

const Subheading = styled.h4`
  font-size: 1.25rem;
  color: #161616;
`

const CloseButton = styled.button`
  border: 0;

  &:focus {
    outline: 0;
  }
`

class ShortlistModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: '',
      email: '',
      invitees: [],
      createButtonDisabled: false
    }

    this.handleEmailEntry = this.handleEmailEntry.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.onRemoveInvitee = this.onRemoveInvitee.bind(this)
    this.submitShortlist = this.submitShortlist.bind(this)
  }
  handleEmailEntry(event) {
    if (event.key === 'Enter') {
      if (validator.validate(this.state.email)) {
        this.setState({ invitees : [...this.state.invitees, this.state.email], email: '' })
      }
    }
  }
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  onRemoveInvitee(email) {
    const invitees = [...this.state.invitees];
    const inviteeIndex = invitees.findIndex((inviteeEmail) => inviteeEmail === email)
    invitees.splice(inviteeIndex, 1)

    this.setState({ invitees })
  }
  submitShortlist() {
    this.setState({ createButtonDisabled: true }, () => {
      Axios.post(`/api/shortlists`, {
        name: this.state.name == '' ? 'Untitled shortlist' : this.state.name,
      }).then((response) => {
        const shortlistId = response.data.data.id

        const inviteRequests = [this.state.email]
            .filter(email => email !== '')
            .map((email) => {
              return Axios.post(`/api/shortlists/${shortlistId}/invitations`, {
                email,
                role: 'READ_WRITE'
              })
            })

        Promise.all(inviteRequests).then(() => {
          this.resetState()
          this.props.closeModal()
        }).catch(() => {
          this.setState({ createButtonDisabled: false })
        })
      }).catch(() => {
        this.setState({ createButtonDisabled: false })
      })
    })
  }
  resetState() {
    this.setState({
      name: '',
      email: '',
      invitees: [],
      createButtonDisabled: false
    })
  }
  render() {
    return (
      <ReactModal
        style={{
          overlay: {
            backgroundColor: 'rgba(22, 22, 22, 0.32)',
            zIndex: 9,
          },
          content: {
            padding: '1.75rem 2rem',
            zIndex: 10,
            top: '20%',
            width: '30%',
            bottom: 'auto',
            left: 0,
            right: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
            borderRadius: '12px',
            border: 0,
            boxShadow: '0 2px 4px 0 rgba(22, 22, 22, 0.12), 0 -1px 2px 0 rgba(22, 22, 22, 0.04)',
          }
        }}
        {...this.props}
      >
        <div style={{ display: 'flex' }}>
          <div>
            <Heading>New Shortlist</Heading>
          </div>
          <div className="ml-auto">
            <CloseButton onClick={() => this.props.closeModal()}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path fill="#BBB" fillRule="evenodd" d="M10.586 12L5.293 6.707a1 1 0 0 1 1.414-1.414L12 10.586l5.293-5.293a1 1 0 0 1 1.414 1.414L13.414 12l5.293 5.293a1 1 0 0 1-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 1 1-1.414-1.414L10.586 12z"/>
              </svg>
            </CloseButton>
          </div>
        </div>

        <InputGroup>
          <Label>Shortlist Name</Label>
          <Input
            type="text"
            placeholder="My Shortlist"
            name="name"
            value={this.state.name}
            onChange={this.handleChange}
          />
        </InputGroup>

        <Rule />

        <Subheading>Collaborators</Subheading>
        <Instructions>Invite others to view or help with your shortlist</Instructions>

        <InputGroup>
          <Label htmlFor="email">Invitee Email Address</Label>
          <Input
            id="email"
            type="email"
            placeholder="you@email.com"
            name="email"
            value={this.state.email}
            onKeyDown={this.handleEmailEntry}
            onChange={this.handleChange}
          />
        </InputGroup>

        {this.state.invitees.map((email, index) => (
          <Invitee
            key={index}
            email={email}
            removeInvitee={this.onRemoveInvitee}
          ></Invitee>
        ))}

        <Button
          primary={true}
          disabled={this.state.createButtonDisabled}
          onClick={() => this.submitShortlist()}
        >
          Create Shortlist
        </Button>
      </ReactModal>
    )
  }
}

export default ShortlistModal
