import React from 'react';
import styled from 'styled-components';

const ErrorText = styled.p`
  color: red;
`;

class ResultErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorText>There was an issue when trying to display the product.</ErrorText>;
    }

    return this.props.children;
  }
}

export default ResultErrorBoundary;
