import React from 'react'
import styled from 'styled-components'

const SubNavContainer = styled.div`
  margin-bottom: 0.75rem;
`

const SubNavLink = styled.a`
  color: ${props => props.highlight ? '#3040E1' : '#161616'};
  font-weight: ${props => props.highlight ? 600 : 400};
  font-size: 1rem;
  font-family: "HK Grotesk";
  margin-bottom: 0.75rem;

  &:hover {
    color: ${props => props.highlight ? '#3040E1' : '#161616'};
    text-decoration: none;
  }
`

const SubNavItem = (props) => (
  <SubNavContainer>
    <SubNavLink {...props}></SubNavLink>
  </SubNavContainer>
)

export default SubNavItem