import React from 'react';
import styled from 'styled-components';
import StarSvg from '../../../svg/star.svg';
import EmptyStarSvg from '../../../svg/empty-star.svg';


const Container = styled.div`
  height: 10%;
  width: 36%;
  border-radius: 32px;
  background-color: #FFFFFF;
  box-shadow: 0 11px 15px -8px rgba(0,0,0,0.16);
  padding: 2% 3%;
`;

const Star = styled.img`
  width: 18%;
  margin: 1%;
`;

const EmptyStar = styled.img`
  width: 18%;
  margin: 1%;
`;

const StarRating = ({ rating, ...props }) => {
  const stars = [];
  for (let i = 0; i < 5; i++) {
    stars.push(rating > i ? <Star key={i} src={StarSvg} /> : <EmptyStar key={i} src={EmptyStarSvg}/>)
  }

  return (
    <Container {...props}>
      {stars}
    </Container>
  );
};

export default StarRating;
