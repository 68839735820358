import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Card from '../Card'
import ButtonLink from '../ButtonLink'
import Label from '../../common/Label'

const CardContainer = styled(Card)`
  background: #fff;
  border-radius: 1.5rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 1.5rem;
  margin-right: auto;
  min-width: 15.8125rem;
  max-width: 16rem;
  max-height: 16.5rem;
  min-height: 16.5rem;
  padding: 2rem;
  position: relative;
  text-align: center;
  width: 100%;

  &:last-of-type {
    margin-right: 0;
  }
`

const LogoContainer = styled.div`
  border-radius: 100%;
  border: 1px solid #EBEBEB;
  height: 4.125rem;
  width: 4.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 0 auto;
`

const Header = styled.h4`
  font-weight: bold;
  margin-top: 0.25rem;
  margin-bottom: 0;
`

const LogoImage = styled.img`
  max-width: 2.25rem;
  max-height: 2.25rem;
`

const ToolCard = ({ header, linkProps, logoUrl, children, tags, ...props }) => (
  <CardContainer {...props}>
    {logoUrl && (
      <LogoContainer>
        <LogoImage src={logoUrl} />
      </LogoContainer>
    )}

    <Header>
      {header}
    </Header>

    <div>
      {tags.map(tag => (
        <Label key={tag}>{tag}</Label>
      ))}
    </div>

    <div style={{ marginTop: 'auto' }}>
      <ButtonLink variant="secondary" {...linkProps}>View Profile</ButtonLink>
    </div>
  </CardContainer>
)

ToolCard.defaultProps = {
  linkProps: {},
  logoUrl: null,
  tags: [],
}

ToolCard.propTypes = {
  header: PropTypes.string.isRequired,
  logoUrl: PropTypes.string,
  tags: PropTypes.array,
}

export default ToolCard
