import styled from 'styled-components'

const Input = styled.input`
  border-radius: 11px;
  box-shadow: inset 0 2px 2px 0 rgba(22, 22, 22, 0.08);
  border: 2px solid rgba(22, 22, 22, 0.08);
  color: #585858;
  padding: 0.5rem 1rem 0.2rem 1rem;
  line-height: 24px;
  font-size: 1rem;
  width: 100%;
  font-family: "HK Grotesk";
  height: 46px;

  &:focus {
    outline: none;
    border: 2px solid #979ff0;
  }

  &::placeholder {
    color: #cccccc;
  }
`

export default Input