import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactSVG from 'react-svg'
import { mediaBreakpointDownSm } from 'styled-bootstrap-responsive-breakpoints'

const IconContainer = styled.div`
  height: 44px;	
  width: 44px;
  background-color: ${props => props.iconBackground};
  border-radius: 50%;
  margin-right: 0.75rem;
  margin-bottom: 1rem;
  min-height: 44px;
  min-width: 44px;

  ${mediaBreakpointDownSm`
    margin-bottom: 0;
  `}
`

const IconSvg = styled(ReactSVG)`
  span {
    align-items: center;
    display: flex;
    height: 100%;
    margin: auto;
    justify-content: center;
  }
  
  #Icons path {
    fill: ${props => props.fill};
  }
`

const Icon = ({ icon, iconBackground, iconFill }) => (
  <IconContainer iconBackground={iconBackground}>
    <IconSvg src={icon} wrapper="span" fill={iconFill} />
  </IconContainer>
)

Icon.defaultProps = {
  iconBackground: '#E1EFEB',
  iconFill: '#087F5B',
}

Icon.propTypes = {
  iconBackground: PropTypes.string,
  iconFill: PropTypes.string,
}

export default Icon