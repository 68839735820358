import React from 'react'
import styled from 'styled-components'
import { mediaBreakpointDownMd } from 'styled-bootstrap-responsive-breakpoints'

import ShortlistImage from '../../../svg/shortlist.svg'
import ShortlistImage2 from '../../../svg/shortlist2.svg'
import ShortlistImage3 from '../../../svg/shortlist3.svg'
import PatternImage from '../../../svg/pattern.svg'

import { motion } from 'framer-motion';

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  min-height: 28rem;
  
  ${mediaBreakpointDownMd`
    min-height: 24rem;
  `}
`

const Pattern = styled.img`
  position: absolute;
  z-index: -1;
  top: -1rem;
  left: -1.7rem;
`

const SliderHero = (props) => (
  <ImageContainer className="image-container" {...props}>
    <Pattern src={PatternImage} />
    <motion.img
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          maxWidth: '32.5rem',
          display: 'flex',
        }}
        alt="Illustration of the Software Path shortlists"
        src={ShortlistImage}
        animate={{ opacity: props.firstImageOpacity }}
    />
    <motion.img
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '91%',
          maxWidth: '29.5rem',
          display: 'flex',
        }}
        alt="Illustration of the Software Path shortlists"
        src={ShortlistImage2}
        animate={{ opacity: props.secondImageOpacity }}
    />
    <motion.img
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '91%',
          maxWidth: '29.5rem',
          display: 'flex',
        }}
        alt="Illustration of the Software Path shortlists"
        src={ShortlistImage3}
        animate={{ opacity: props.thirdImageOpacity }}
    />
  </ImageContainer>
)

export default SliderHero
