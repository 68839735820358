import React, { Component } from 'react'
import styled from 'styled-components'

import Rating from './Rating'

const ReviewContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 2rem;
`

const ProfilePicture = styled.img`
  border-radius: 50%;
  margin: 0.5rem;
  margin-left: 0;
  width: 42px;
  height: 42px;
`

const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1;
  margin: 0.5rem;
  justify-content: center;
`

const RatingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  display: flex;
`

export default class Review extends Component {
  render() {
    return (
      <ReviewContainer>
        <div className="d-flex" style={{ lineHeight: 1 }}>
          <ProfilePicture src={this.props.photo} />
          <ProfileDetails>
            <span>{this.props.name}</span>
            <small>{ this.props.companyUrl ? <a href={this.props.companyUrl}>{this.props.companyName}</a> : this.props.companyName }, {this.props.date}</small>
          </ProfileDetails>
          <RatingContainer>
            <Rating rating={this.props.rating} fill="#575757" width="1rem" height="1rem" />
          </RatingContainer>
        </div>

        <p>
          {this.props.review}
        </p>
      </ReviewContainer>
    )
  }
}
