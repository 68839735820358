import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'
import { mediaBreakpointDownMd } from 'styled-bootstrap-responsive-breakpoints'

import DefaultContainer from '../Container'
import Trees from '../../../img/trees.png'
import Treeaid from '../../../img/treeaid.png'
import PatternGreen from '../../../svg/pattern-green.svg'

const Section = styled.section`
  background: #F5FAF9;
`

const Container = styled(DefaultContainer)`
  max-width: 1120px;
  width: 100%;
  align-items: center;
  padding-top: 5rem;
  padding-bottom: 8.25rem;

  ${mediaBreakpointDownMd`
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  `}
`

const Title = styled.h1`
  font-family: 'Tiempos Headline'; 
  color: #087F5B;
  font-size: 4.75rem;
  font-weight: bold;
`

const Subtitle = styled.span`
  font-family: 'HK Grotesk';
  font-size: 1.25rem;
  font-weight: normal;
  display: flex;
  align-items: center;
`

const Logo = styled.img`
  width: 2rem;
  height: 2rem;
  margin-left: 0.5rem;
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`

const Content = styled.div`
  margin-top: 2rem;
`

const ImageContainer = styled.div`
  position: relative;
`

const ImagePattern = styled.img`
  position: absolute;
  right: -34px;
  top: 26px;
  z-index: 1;

  ${mediaBreakpointDownMd`
    display: none;
  `}
`

const TreeImage = styled.img`
  position: relative;
  z-index: 2;
  width: 100%;
  display: flex;
  margin-left: auto;
`

const ReviewLink = styled.a`
  background: #fff;
  color: #161616;
  display: inline-block;
  width: auto;
  padding: 0.6875rem 1.3125rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  border-radius: 12px;
  border: 1px solid #E1EFEB;

  &:hover {
    text-decoration: none;
    color: #161616;
  }
`

const TreeSection = ({treeCount}) => (
  <Section>
    <Container>
      <Row>
        <Col xs={{ order: 2, size: 12 }} md={{ order: 1, size: 6 }}>
          <InnerContainer>
            <Title>
              {treeCount}
              <Subtitle>
                trees planted, in partnership with <Logo src={Treeaid} alt="TREE AID" />
              </Subtitle>
            </Title>

            <Content>
              <p>
                In the face of the climate crisis,
                we’re taking a small first step to help by planting a tree each time you buy or review software.
              </p>
            </Content>

            <div>
              <ReviewLink href="https://info.softwarepath.com/reviews/">Write a review and plant a tree</ReviewLink>
            </div>
          </InnerContainer>
        </Col>
        <Col xs={{ order: 1, size: 12 }} md={{ order: 2, size: 6 }}>
          <ImageContainer>
            <ImagePattern src={PatternGreen} />
            <TreeImage src={Trees} />
          </ImageContainer>
        </Col>
      </Row>
    </Container>
  </Section>
)

export default TreeSection
