import styled from 'styled-components'
import { NavbarToggler as DefaultNavbarToggler } from 'reactstrap'

const NavbarToggler = styled(DefaultNavbarToggler)`
  padding-right: 0;
  padding-left: 1rem;

  &:focus {
    outline: 0;
  }
`

export default NavbarToggler