import React from 'react'
import styled from 'styled-components'
import { Col, Row } from 'reactstrap'
import { mediaBreakpointDownMd } from 'styled-bootstrap-responsive-breakpoints'

import { default as DefaultContainer } from '../Container'
import HeroImage from '../search/HeroImage'
import SearchForm from '../search/SearchForm'
import Slider from '../search/Slider'

const Heading = styled.h1`
  font-family: 'Tiempos Headline', serif;
  font-size: 4rem;
  color: #000749;
  line-height: 4.75rem;

  ${mediaBreakpointDownMd`
    font-size: 2.25rem;
    line-height: 2.75rem;
  `}
`

const Container = styled(DefaultContainer)`
  margin-bottom: 13.625rem;
  margin-top: 8rem;

  ${mediaBreakpointDownMd`
    margin-top: 2.5rem;
    margin-bottom: 3.75rem;
  `}
`

const LogoContainer = styled.div`
  display: flex; 
  flex: 1;
  margin: 1.5rem 0;
  position: relative;

  &:before {
    content: "";
    background: linear-gradient(270deg, rgba(255,255,255,0), #FFFFFF);
    min-height: 100%;
    width: 8rem;
    position: absolute;
    left: -0.2rem;
    z-index: 2;
  }

  &:after {
    content: "";
    transform: scaleX(-1);
    background: linear-gradient(270deg, rgba(255,255,255, 0), #FFFFFF);
    min-height: 100%;
    width: 8rem;
    position: absolute;
    right: -0.2rem;
    z-index: 2;
  }
`

const SearchSection = ({icons}) => (
  <section>
    <Container>
      <Row>
        <Col xs={{ order: 2, size: 12 }} md={{ order: 1, size: 6 }}>
          <HeroImage />
        </Col>
        <Col xs={{ order: 1, size: 12 }} md={{ order: 2, size: 6 }}>
          <Heading>Find & compare  great software for your team</Heading>

          <LogoContainer>
            <Slider imageUrls={icons.map(url => url)} />
          </LogoContainer>

          <SearchForm />
        </Col>
      </Row>
    </Container>
  </section>
)

export default SearchSection
