import React from 'react';
import styled from 'styled-components';
import {Row, Col, Container} from 'reactstrap'

const ErrorText = styled.p`
  color: red;
`;

const ContentContainer = styled.div`
  margin-left: 0.75rem;
`

const ListingContainer = styled.div`
  padding: 2rem;
  border-bottom: 1px solid #ebebeb;

  &:last-child {
    border-bottom: 0;
  }
`

class ListingErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.log(error, info);
  }

  render() {
    let productName = 'this product';
    if (this.state.hasError) {
      if (this.props.hasOwnProperty('name')) {
        productName = this.props.name;
      }

      return (
          <ListingContainer>
            <Row noGutters={true}>
              <Col xs={2} sm={2} md={2} lg={1}>

              </Col>
              <Col xs={10} sm={10} md={10} lg={11}>
                <ContentContainer>
                  <div className="d-flex">
                    <ErrorText>There was an issue trying to display {productName}'s data</ErrorText>
                  </div>
                </ContentContainer>
              </Col>
            </Row>
          </ListingContainer>
      );
    }

    return this.props.children;
  }
}

export default ListingErrorBoundary;
