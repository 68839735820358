import React, { Component } from 'react'
import FeatureContainer from './common/FeatureContainer'
import FeaturesList from './features/FeaturesList'
import Heading from './common/Heading'

export default class Features extends Component {
  render() {
    const categories = [{
          text: 'All',
          id: null,
        }]
        .concat(
            this.props
            .features
            .map(feature => feature.categories.map(category => category.short_name))
            .flat()
            .filter((v, i, a) => a.indexOf(v) === i) // Returns unique values only
            .filter(category => this.props.categories.map(productCategory => productCategory.short_name).includes(category))
            .map(categoryName => ({
              text: categoryName.toUpperCase(),
              id: categoryName,
            }))
        );

    const features = this.props.features.map(feature => ({
      categories: feature.categories,
      feature: feature.name,
    })).sort((a,b) => a.feature < b.feature ? -1 : 1);

    return (
      <FeatureContainer>
        <Heading>Features</Heading>

        <FeaturesList features={features} categories={categories} />

        {this.props.text}
      </FeatureContainer>
    )
  }
}
