import Axios from "axios"
import getShortlists from './get-shortlists'
import store from '../store'
import setShowingShortlistComponent from './set-showing-shortlist-component'

export const PRODUCT_ADDED_TO_SHORTLIST = 'PRODUCT_ADDED_TO_SHORTLIST'

/**
 * @deprecated
 */
const addProductToShortlist = (productSlug, shortlistId) => (
    dispatch => {
      return Axios.post(`/api/shortlists/${shortlistId}/products`, {
        product: productSlug
      }).then((response) => {
        const product = response.data.data

        dispatch({
          type: PRODUCT_ADDED_TO_SHORTLIST,
          shortlistId,
          product,
        })

        store.dispatch(setShowingShortlistComponent(true))

        return product
      })
      .catch(error => {
        // If the shortlist could not be found reload shortlist data to get the latest
        if (error.response && error.response.status === 404) {
          store.dispatch(getShortlists())
        }
      })
    }
)

export default addProductToShortlist
