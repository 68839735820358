export const CURRENT_SHORTLIST_SET = 'CURRENT_SHORTLIST_SET'

const setCurrentShortlist = (shortlist) => (
    dispatch => {
      return dispatch({
        type: CURRENT_SHORTLIST_SET,
        shortlist,
      })
    }
)

export default setCurrentShortlist
