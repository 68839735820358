import styled from 'styled-components'
import { mediaBreakpointDownSm } from 'styled-bootstrap-responsive-breakpoints'

const Plans = styled.div`
  border: 1px solid #ebebeb;
  border-radius: 8px;
  display: flex;
  margin: 1.5rem 0;
  overflow-x: ${props => props.scrollable ? 'auto' : null}
  min-width: ${props => props.scrollable ? '100%' : 'auto'}
  
  .plan {
    height: ${props => props.multiple ? '12.8rem' : 'auto'}
    min-width: ${props => props.scrollable ? '15.5rem' : 'auto'}
  }

  .plan-details {
    flex: ${props => props.multiple ? '0 1 100%' : null}
  }

  .plan-label {
    align-items: ${props => props.multiple ? 'flex-end' : null}
  }
  
  .plan-price {
    margin-left: ${props => props.multiple ? 'auto' : null}
    align-items: ${props => props.multiple ? 'flex-end' : null}
  }


  ${mediaBreakpointDownSm`
    overflow-x: auto;
    min-width: 100%;
  `}
`

export default Plans